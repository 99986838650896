import React, { useState, useRef } from "react";
import { PropTypes } from "prop-types";

// imports: syklone
import { IconButton, Popover, icons } from "syklone/ui";

const withInfoPopover = (Component) => {
  const WidgetInfoPopover = ({ anchorOrigin, transformOrigin, ...props }) => {
    const [open, setOpen] = useState(false);
    const refButton = useRef();

    return (
      <>
        <IconButton aria-label="delete" color="primary" onClick={() => setOpen(!open)} ref={refButton}>
          <icons.mui.Info />
        </IconButton>
        <Popover
          open={open}
          onClose={() => setOpen(false)}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          anchorEl={refButton.current}
        >
          <Component {...props} />
        </Popover>
      </>
    );
  };

  WidgetInfoPopover.propTypes = {
    anchorOrigin: PropTypes.object,
    transformOrigin: PropTypes.object,
  };
  WidgetInfoPopover.defaultProps = {
    anchorOrigin: { vertical: "center", horizontal: "right" },
    transformOrigin: {
      vertical: "center",
      horizontal: "left",
    },
  };

  return WidgetInfoPopover;
};

withInfoPopover.propTypes = {
  Component: PropTypes.any,
};
export default withInfoPopover;
