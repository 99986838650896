import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import * as coreCommands from "syklone/graphics/tools/core_commands/index.js";
import { useApiContext } from "syklone/api/react/index.js";

// imports: local
import { DialogRemoteCommon } from "../dialog_remote_common/index.js";

const DialogRemoteImportParts = React.forwardRef(({ open }, ref) => {
  const [rows, setRows] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(open);
  const toolRef = React.useRef();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isConfirmDisabled, setIsConfirmDisabled] = React.useState(false);
  const [loadingText, setIsLoadingText] = React.useState("");

  const api = useApiContext();
  const commandManager = api.commandManager;
  const servicePartSessions = api.sykloneApi.servicePartSessions;

  React.useImperativeHandle(ref, () => ({
    show: (_tool) => {
      console.log(_tool);
      toolRef.current = _tool;
      onRefresh();
      setIsOpen(true);
      setIsConfirmDisabled(false);
    },
  }));

  const onOk = (currentRow, filename) => {
    function loader(loading, text) {
      setIsLoading(loading);
      setIsLoadingText(text);
      if (loading) {
        setIsConfirmDisabled(true);
      } else {
        setIsOpen(false);
        setIsConfirmDisabled(false);
      }
    }

    setIsConfirmDisabled(true);
    const data = {
      filename: filename,
      id: currentRow.id,
    };
    commandManager.executeAsync(new coreCommands.CommandImportPartRemotely(api, toolRef.current, loader, data));
  };

  const onRefresh = async () => {
    try {
      let response = await servicePartSessions.getCommitted();
      let rows = response.data.result;
      setRows(rows);
    } catch (error) {
      alert(`${error}`);
    }
  };

  return (
    <DialogRemoteCommon
      dataTag="dialog-remote-import-platforms"
      title="Import parts"
      isLoading={isLoading}
      loadingText={loadingText}
      open={isOpen}
      onClose={() => setIsOpen(false)}
      rows={rows}
      onOk={onOk}
      onCellDoubleClick={onOk}
      onRefresh={onRefresh}
      isSubmitting={isConfirmDisabled}
    />
  );
});

DialogRemoteImportParts.displayName = "DialogRemoteImportParts";

DialogRemoteImportParts.propTypes = {
  tool: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default DialogRemoteImportParts;
