import React, { useRef, useState } from "react";
import PropTypes from "prop-types";

// imports: syklone
import {
  Button,
  Divider,
  FieldArray,
  Form,
  FormControl,
  Formik,
  Grid,
  IconButton,
  icons,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Yup,
} from "syklone/ui/index.js";
import { WidgetInputNumber } from "syklone/components/widgets/index.js";

// imports: local
import WidgetHelperEditor from "./widget_helper_editor.jsx";
import WidgetJsonPreview from "./widget_json_preview.jsx";

const scanningTemplateSchema = Yup.object().shape({
  templateName: Yup.string().required("Template name is required."),
  machineDefinitionId: Yup.object().required("Machine definition is required."),
  scanningParameters: Yup.array().of(
    Yup.object().shape({
      fieldName: Yup.string().required("Field name is required."),
      dataType: Yup.string().required("Data type is required."),
      minValue: Yup.number().lessThan(Yup.ref("maxValue"), "Value must be less than maximum value"),
      maxValue: Yup.number().moreThan(Yup.ref("minValue"), "Value must be more than minimum value"),
      unit: Yup.string().max(10, "Units must be 10 characters or less."),
    })
  ),
});

const _Section = ({ title, children }) => {
  return (
    <Grid item container flexDirection="column">
      <Divider />
      <Typography component="h2" variant="h5" align="left" color="inherit" noWrap mt={2} mb={2}>
        {title}
      </Typography>
      {children}
    </Grid>
  );
};

_Section.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

const _HelperEditor = ({ data, onApply, onCancel, onClick }) => {
  const refButton = useRef();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
    onClick();
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
    onCancel();
  };

  const handleApply = (data) => {
    onApply(data);
    setIsDialogOpen(false);
  };

  return (
    <>
      <IconButton ref={refButton} onClick={handleOpenDialog}>
        {data.image || data.description ? <icons.mui.Edit color="warning" /> : <icons.mui.Add color="success" />}
      </IconButton>

      <Popover
        open={isDialogOpen}
        anchorEl={refButton.current}
        onClose={handleCloseDialog}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        sx={{ "& .MuiPaper-root": { borderRadius: "0.5rem" } }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <WidgetHelperEditor values={data} onApply={handleApply} onCancel={handleCloseDialog} />
      </Popover>
    </>
  );
};

_HelperEditor.propTypes = {
  data: PropTypes.object,
  onApply: PropTypes.func,
  onCancel: PropTypes.func,
  onClick: PropTypes.func,
};

_HelperEditor.defaultProps = {
  data: { image: undefined, title: "No title", description: "" },
  onApply: () => {},
  onCancel: () => {},
  onClick: () => {},
};

const _TemplatePropertiesList = ({
  checkError,
  data,
  displayError,
  errors,
  handleBlur,
  handleChange,
  removeIndex,
  setFieldTouched,
  setFieldValue,
  touched,
}) => {
  const [highlightedRow, setHighlightedRow] = useState();

  const handleDelete = (index) => {
    removeIndex(index);
    setHighlightedRow(undefined);
  };

  const applyHelperData = (values, entry) => {
    const { description, image } = values;

    entry.description = description;
    entry.image = image;
    setHighlightedRow(undefined);
  };

  return (
    <Grid container item>
      <Grid container item position="relative">
        <Grid item flexGrow={1}>
          {/* TODO: use WidgetSection (from post processing) with add button as adrornment */}
          <Typography component="h3" variant="h4" color="inherit" noWrap mb={2}>
            Parameters
          </Typography>
        </Grid>
      </Grid>
      <TableContainer
        sx={{
          "&.MuiTableContainer-root": {
            backgroundColor: "#181818",
            border: "none",
          },
        }}
      >
        <Table data-syklone="scanning-templates-table" sx={{ width: "100%" }}>
          <TableHead>
            <TableRow>
              <TableCell>Field Name</TableCell>
              <TableCell>Data Type</TableCell>
              <TableCell>Minimum Value</TableCell>
              <TableCell>Maximum Value</TableCell>
              <TableCell>Units</TableCell>
              <TableCell>Helper</TableCell>
              <TableCell>Delete</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((parameter, index) => (
              <TableRow
                key={`scanningParameter${index}`}
                sx={highlightedRow === index ? { backgroundColor: "#202020" } : null}
              >
                <TableCell>
                  <TextField
                    id={`scanningParameters.${index}.fieldName`}
                    name={`scanningParameters.${index}.fieldName`}
                    label="Field Name"
                    variant="outlined"
                    value={data[index].fieldName}
                    onChange={handleChange}
                    error={checkError(touched, errors, index, "fieldName")}
                    helperText={displayError(touched, errors, index, "fieldName")}
                  />
                </TableCell>
                <TableCell>
                  <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                    <InputLabel id="select-scanning-template" key="templateSelect">
                      Data Type
                    </InputLabel>
                    <Select
                      name={`scanningParameters.${index}.dataType`}
                      label="Data Type"
                      defaultValue="numeric"
                      onChange={handleChange}
                    >
                      <MenuItem value="numeric">Numeric</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell>
                  <WidgetInputNumber
                    name={`scanningParameters.${index}.minValue`}
                    label="Minimum Value"
                    value={data[index].minValue}
                    onApply={(key, value) => {
                      setFieldTouched(key, true);
                      setFieldValue(key, value);
                    }}
                    handleBlur={handleBlur}
                    error={checkError(touched, errors, index, "minValue")}
                    helperText={displayError(touched, errors, index, "minValue")}
                  />
                </TableCell>

                <TableCell>
                  <WidgetInputNumber
                    name={`scanningParameters.${index}.maxValue`}
                    label="Maximum Value"
                    value={data[index].maxValue}
                    onApply={(key, value) => {
                      setFieldTouched(key, true);
                      setFieldValue(key, value);
                    }}
                    onBlur={handleBlur}
                    error={checkError(touched, errors, index, "maxValue")}
                    helperText={displayError(touched, errors, index, "maxValue")}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    id={`scanningParameters.${index}.unit`}
                    name={`scanningParameters.${index}.unit`}
                    label="Unit"
                    variant="outlined"
                    value={data[index].unit}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={checkError(touched, errors, index, "unit")}
                    helperText={displayError(touched, errors, index, "unit")}
                  />
                </TableCell>
                <TableCell>
                  <_HelperEditor
                    onClick={() => setHighlightedRow(index)}
                    onApply={(data) => applyHelperData(data, parameter)}
                    onCancel={() => setHighlightedRow(undefined)}
                    data={{
                      title: parameter.fieldName,
                      description: parameter.description,
                      image: parameter.image,
                    }}
                  />
                </TableCell>
                <TableCell>
                  <IconButton aria-label="delete" color="error" onClick={() => handleDelete(index)}>
                    <icons.mui.Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
};

_TemplatePropertiesList.propTypes = {
  data: PropTypes.array,
  checkError: PropTypes.func,
  displayError: PropTypes.func,
  errors: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  removeIndex: PropTypes.func,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  touched: PropTypes.object,
};

const WidgetScanningTemplateEditor = ({ data, machines, handleSubmit }) => {
  const checkError = (touched, errors, index, field) => {
    return (
      touched?.scanningParameters &&
      touched?.scanningParameters[index]?.[field] &&
      errors?.scanningParameters &&
      Boolean(errors?.scanningParameters[index]?.[field])
    );
  };

  const displayError = (touched, errors, index, field) => {
    return (
      touched?.scanningParameters &&
      touched?.scanningParameters[index]?.[field] &&
      errors?.scanningParameters &&
      errors.scanningParameters[index]?.[field]
    );
  };

  const onSubmit = (values) => {
    const { _id, ...rest } = values; // eslint-disable-line
    handleSubmit(rest);
  };

  return (
    <Formik initialValues={data} onSubmit={onSubmit} validationSchema={scanningTemplateSchema} enableReinitialize>
      {({ values, errors, touched, handleChange, handleBlur, setFieldValue, setFieldTouched }) => (
        <Form
          data-syklone="scanning-templates"
          style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}
        >
          <Grid container item justifyContent="center" direction="column" sx={{ marginTop: "1rem" }} spacing={3}>
            <Grid container item spacing={3} direction="row">
              <Grid item>
                <TextField
                  id="templateName"
                  name="templateName"
                  label="Template Name"
                  variant="outlined"
                  value={values.templateName}
                  onChange={handleChange}
                  error={touched.templateName && Boolean(errors.templateName)}
                  helperText={touched.templateName && errors.templateName}
                />
              </Grid>
              <Grid item flex={1}>
                <FormControl fullWidth>
                  <InputLabel id="select-machine">Select machine</InputLabel>
                  <Select
                    labelId="select-machine"
                    id="select-machine"
                    defaultValue=""
                    value={machines.find((machine) => machine._id.$oid === values.machineDefinitionId.$oid)?._id}
                    label="Select machine"
                    onChange={(e) => {
                      setFieldValue("machineDefinitionId", e.target.value);
                    }}
                  >
                    {machines.map((machine) => (
                      <MenuItem key={machine._id.$oid} value={machine._id}>
                        {machine.definitionName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container item flex={1}>
              <FieldArray name="scanningParameters">
                {({ insert, remove, push }) => (
                  <Grid container item flex={1}>
                    <Grid item container direction="column" spacing={3}>
                      <_TemplatePropertiesList
                        checkError={checkError}
                        data={values.scanningParameters}
                        displayError={displayError}
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        push={push}
                        removeIndex={remove}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        touched={touched}
                      />
                    </Grid>
                    <Button
                      variant="contained"
                      color="success"
                      startIcon={<icons.mui.Add />}
                      sx={{ marginTop: 1 }}
                      onClick={() => {
                        push({
                          fieldName: "",
                          dataType: "numeric",
                          minValue: 0,
                          maxValue: 0,
                          unit: "",
                        });
                      }}
                    >
                      Add
                    </Button>
                  </Grid>
                )}
              </FieldArray>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3} justifyContent="center">
                <Grid item>
                  <WidgetJsonPreview json={values} />
                </Grid>
                <Grid item>
                  <Button size="large" color="primary" variant="contained" type="submit">
                    Commit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

WidgetScanningTemplateEditor.propTypes = {
  data: PropTypes.object,
  handleSubmit: PropTypes.func,
  machines: PropTypes.array,
};

WidgetScanningTemplateEditor.defaultProps = {
  data: {
    templateName: "",
    machineDefinitionId: "",
    scanningParameters: [],
  },
  machines: [],
  handleSubmit: () => {},
};

export default WidgetScanningTemplateEditor;
