import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, icons } from "syklone/ui/index.js";

// imports: local
import { WidgetLoader } from "syklone/components/widgets/index.js";

const DialogCommonBase = ({
  open = false,
  dataTag,
  onClose,
  title,
  tooltip,
  dialogPaperSx,
  children,
  actions,
  isLoading,
  loadingText = "Loading",
  showClose,
  dialogActionsSx,
  hideActions,
}) => {
  const classes = useStyles();
  return (
    <>
      {isLoading ? (
        <WidgetLoader dataTag={`${dataTag}-loader`} style={classes.loadingPosition}>
          {loadingText}
        </WidgetLoader>
      ) : null}
      <Dialog
        onClose={onClose}
        aria-labelledby="dialog-common-title"
        open={open}
        disableEnforceFocus
        PaperProps={{ sx: dialogPaperSx }}
      >
        <DialogTitle
          id="dialog-common-title"
          data-syklone={`${dataTag}-header`}
          sx={{
            position: "relative",
            backgroundColor: "#252525",
            display: "flex",
            alignItems: "center",
          }}
        >
          {title}
          {tooltip}
          {onClose && showClose ? (
            <IconButton
              aria-label="close"
              onClick={onClose}
              data-syklone={`${dataTag}-button-close`}
              sx={{
                position: "absolute",
                right: 13,
                top: 12,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <icons.mui.Close />
            </IconButton>
          ) : null}
        </DialogTitle>
        <DialogContent sx={{ backgroundColor: "#252525" }} data-syklone={`${dataTag}-content`}>
          {children}
        </DialogContent>
        {actions && !hideActions && (
          <DialogActions sx={{ alignItems: "baseline", ...dialogActionsSx }} data-syklone={`${dataTag}-footer`}>
            {actions}
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

const useStyles = (theme) => ({
  loadingPosition: {
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 999999,
  },
});

DialogCommonBase.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  tooltip: PropTypes.object,
  contentHeight: PropTypes.number,
  contentWidth: PropTypes.number,
  actions: PropTypes.object,
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  sx: PropTypes.object,
  dialogPaperSx: PropTypes.object,
  showClose: PropTypes.bool,
  dialogActionsSx: PropTypes.object,
  hideActions: PropTypes.bool,
  dataTag: PropTypes.string.isRequired,
};

DialogCommonBase.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  tooltip: PropTypes.object,
  contentHeight: PropTypes.number,
  contentWidth: PropTypes.number,
  actions: PropTypes.object,
  isLoading: PropTypes.bool,
  loadingText: PropTypes.string,
  sx: PropTypes.object,
  dialogPaperSx: PropTypes.object,
  showClose: PropTypes.bool,
  dialogActionsSx: PropTypes.object,
  hideActions: PropTypes.bool,
};

export default DialogCommonBase;
