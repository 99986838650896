import React from "react";
import { BrowserRouter } from "react-router-dom";
import { PropTypes } from "prop-types";
import ReactDOM from "react-dom/client";

// imports: syklone
import { ApiProvider, AuthProvider } from "syklone/api/react/index.js";
import { factories } from "syklone/api/index.js";

// imports: local
import "./index.css";
import { getEnv as getRemoteEnv } from "./env.js";
import * as serviceWorker from "./service_worker.js";
import App from "./app.jsx";

export function DecoratedApp(props) {
  const { api } = props;
  // TODO: [src] we've got some incompabilities between widget_render and react strict mode
  return (
    // <React.StrictMode>
    <ApiProvider api={api}>
      <BrowserRouter>
        <AuthProvider>
          <App />
        </AuthProvider>
      </BrowserRouter>
    </ApiProvider>
    // </React.StrictMode>
  );
}

DecoratedApp.propTypes = {
  api: PropTypes.object,
};

const api = factories.createRemoteApi(getRemoteEnv(), "prdSvcAuthSign");
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<DecoratedApp api={api} />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
