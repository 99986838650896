import React, { Component } from "react";

// imports: syklone
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  icons,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "syklone/ui/index.js";
import { utils } from "syklone/common/index.js";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
}));

export default class DialogMaterials extends Component {
  static defaultProps = {
    open: false,
    materials: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      ...props,
    };
  }

  setMaterials = (materials) => {
    let lst = [];
    for (const material of materials) {
      lst.push({
        name: material.name,
        colorStr: material.color.getHexString(),
        material: material,
      });
    }
    lst.sort((a, b) => a.name.localeCompare(b.name));
    this.setState({
      materials: lst,
    });
  };

  show = () => {
    this.setState({
      open: true,
    });
  };

  onCancel = () => {
    this.setState({
      open: false,
    });
  };

  onRandomize = () => {
    let materials = this.state.materials;
    let palette = utils.createRandomPalette(materials.length);
    for (let [i, v] of materials.entries()) {
      v.colorStr = palette[i];
    }
    this.forceUpdate();
  };

  onOk = () => {
    for (let v of this.state.materials) {
      let mat = v.material;
      mat.color.set(v.colorStr);
    }

    this.setState({
      open: false,
    });
  };

  render() {
    let tableBody = () => {
      return this.state.materials.map((row) => (
        <StyledTableRow key={row.name}>
          <StyledTableCell component="th" scope="row">
            {row.name}
          </StyledTableCell>
          <StyledTableCell align="right">
            <Tooltip title="Assign">
              <IconButton>
                <icons.mui.Colorize style={{ color: row.colorStr }} />
              </IconButton>
            </Tooltip>
          </StyledTableCell>
        </StyledTableRow>
      ));
    };

    return (
      <Dialog onClose={this.handleClose} aria-labelledby="customized-dialog-title" open={this.state.open}>
        <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
          Materials
        </DialogTitle>
        <DialogContent dividers>
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>Material</StyledTableCell>
                  <StyledTableCell align="right">Color</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>{tableBody()}</TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={this.onCancel} color="primary">
            Cancel
          </Button>
          <Button autoFocus onClick={this.onRandomize} color="primary">
            Randomize
          </Button>
          <Button autoFocus onClick={this.onOk} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
