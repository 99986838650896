import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import { PropTypes } from "prop-types";

// imports: syklone
import { Checkbox, CircularProgress, DataGrid, Grid } from "syklone/ui/index.js";
import { useApiContext } from "syklone/api/react/index.js";
import * as coreCommands from "syklone/graphics/tools/core_commands/index.js";

// imports: local
import { DialogCommonWizard } from "../dialog_common_wizard/index.js";
import DialogRemoteCommonCommit from "../dialog_wizard_common_commit/dialog_remote_common_commit.jsx";

const DialogCommit = forwardRef(({ servicePartRecipes, setButtonCommitDisabled }, ref) => {
  const [partRecipes, setPartRecipes] = useState(false);
  const [loading, setLoading] = useState(true);
  const filenameRef = useRef("");

  useImperativeHandle(ref, () => ({
    getFilename: () => filenameRef.current,
  }));

  const fetchPartRecipes = async () => {
    try {
      const resPartRecipes = await servicePartRecipes.getAll();
      setPartRecipes(resPartRecipes.data?.result);
      setLoading(false);
    } catch (error) {
      throw new Error(error);
    }
  };

  useEffect(() => {
    fetchPartRecipes();
  }, []);

  if (loading)
    return (
      <Grid item sx={{ width: "100%" }} alignItems="center" justifyContent="center">
        <CircularProgress />
      </Grid>
    );

  return (
    <DialogRemoteCommonCommit
      rows={partRecipes}
      dataTag={"Part recipes"}
      filenameExistsText="Filename already exists"
      onRefresh={fetchPartRecipes}
      confirmButtonStatus={(isConfirmDisabled) => setButtonCommitDisabled(isConfirmDisabled)}
      onFilenameChange={(value) => (filenameRef.current = value)}
    />
  );
});

DialogCommit.displayName = "DialogCommit";

DialogCommit.propTypes = {
  servicePartRecipes: PropTypes.object,
  setButtonCommitDisabled: PropTypes.func,
};

const DialogSelectBuildFiles = ({ buildFiles, loading, selectedBuildFileId, onSelectedBuildFileIdChange }) => {
  const handleSelectBuildFile = (id) => {
    if (selectedBuildFileId === id) {
      onSelectedBuildFileIdChange("");
    } else {
      onSelectedBuildFileIdChange(id);
    }
  };

  const columns = [
    { field: "id", headerName: "ID" },
    { field: "name", headerName: "Name" },
    { field: "status", headerName: "Status" },
    { field: "createdAt", headerName: "Creation date" },
    {
      field: "selection",
      headerName: "Select",
      sortable: false,
      filterable: false,
      width: 70,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div>
            <Checkbox checked={selectedBuildFileId === params.id} onChange={() => handleSelectBuildFile(params.id)} />
          </div>
        );
      },
    },
  ];

  const sortedData = structuredClone(buildFiles).sort((a, b) => {
    return Date.parse(b.createdAt.$date) - Date.parse(a.createdAt.$date);
  });

  const rows = sortedData?.map((entry) => {
    const { id, name, status, createdAt } = entry;

    return { id, name, status, createdAt };
  });

  if (loading)
    return (
      <Grid item sx={{ width: "100%" }} alignItems="center" justifyContent="center">
        <CircularProgress />
      </Grid>
    );

  return (
    <Grid container flexDirection="column">
      <Grid item>
        <DataGrid
          rows={rows}
          columns={columns}
          onCellClick={({ id }) => handleSelectBuildFile(id)}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 10,
              },
            },
          }}
          pageSizeOptions={[10]}
          sx={(theme) => ({
            fontSize: 13,
            "&.MuiDataGrid-root": {
              "& .MuiDataGrid-row.Mui-selected": {
                backgroundColor: theme.palette.mode === "dark" ? "#121212" : "#f8f8f8",
              },
              "& .MuiDataGrid-cell": {
                borderBottom: theme.palette.mode === "dark" ? "1px solid #2F2F2F" : "1px solid #E8E8E8",
              },
              "& .MuiDataGrid-columnHeader:focus": {
                outline: "none",
              },
              border: "none",
            },
            ".MuiDataGrid-columnHeaders": {
              borderBottom: theme.palette.mode === "dark" ? "1px solid #2F2F2F" : "1px solid #E8E8E8",
            },
            ".MuiDataGrid-footerContainer": {
              borderTop: "none",
            },
          })}
        />
      </Grid>
    </Grid>
  );
};

DialogSelectBuildFiles.propTypes = {
  serviceBfm: PropTypes.object,
  onSelectedBuildFileIdChange: PropTypes.func,
  buildFiles: PropTypes.array,
  loading: PropTypes.bool,
  selectedBuildFileId: PropTypes.string,
};

const DialogRemoteCommitCoupon = forwardRef(({ username, tool }, ref) => {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingText, setIsLoadingText] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const [buildFiles, setBuildFiles] = useState([]);
  const [selectedBuildFileId, setSelectedBuildFileId] = useState("");

  const refDialogCommit = useRef();

  const api = useApiContext();
  const commandManager = api.commandManager;
  const serviceBfm = api.sykloneApi.serviceBfm;
  const servicePartRecipes = api.sykloneApi.servicePartRecipes;

  const fetchBuildFiles = async () => {
    try {
      const resRD = await serviceBfm.getResearchAndDevelopment();
      setBuildFiles(resRD.data?.data);
      setLoading(false);
    } catch (error) {
      throw new Error(error);
    }
  };

  useImperativeHandle(ref, () => ({
    show: () => {
      setIsOpen(true);
      setIsConfirmDisabled(false);
    },
  }));

  useEffect(() => {
    if (isOpen) {
      fetchBuildFiles();
      setActiveStep(0);
    }
  }, [isOpen]);

  const steps = [
    {
      label: "Select build file to link with the coupon",
      buttonName: "Next",
      buttonHandler: () => setActiveStep(1),
      buttonDisabled: !selectedBuildFileId,
    },
    {
      label: "Set coupon name",
      buttonName: "Complete",
      buttonHandler: () => onComplete(refDialogCommit.current.getFilename()),
      buttonDisabled: isConfirmDisabled,
    },
  ];

  const previousStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const onComplete = (filename) => {
    function loader(loading, text) {
      setIsLoading(loading);
      setIsLoadingText(text);
      if (loading) {
        setIsConfirmDisabled(true);
      } else {
        setIsOpen(false);
        setIsConfirmDisabled(false);
      }
    }
    setIsConfirmDisabled(true);

    const data = {
      filename: filename,
      user: username,
      selectedBuildFileId: selectedBuildFileId,
      buildFiles: buildFiles,
    };
    commandManager.executeAsync(new coreCommands.CommandCommitCouponRemotely(api, tool, loader, data));
  };

  const handleSelectedBuildFilesChange = (buildFiles) => {
    setSelectedBuildFileId(buildFiles);
  };

  return (
    <DialogCommonWizard
      dataTag="dialog-remote_commit_coupon"
      onClose={() => setIsOpen(false)}
      dialogPaperSx={{ maxWidth: "920px", width: "920px" }}
      isLoading={isLoading}
      open={isOpen}
      maxSteps={steps.length}
      loadingText={loadingText}
      hideActions={false}
      activeStep={activeStep}
      title={steps[activeStep].label}
      autoFocus
      handleNext={steps[activeStep].buttonHandler}
      handleBack={previousStep}
      nextStepTitle={steps[activeStep].buttonName}
      isNextDisabled={steps[activeStep].buttonDisabled}
      showClose
    >
      <DialogSelectBuildFiles
        buildFiles={buildFiles}
        selectedBuildFileId={selectedBuildFileId}
        loading={loading}
        onSelectedBuildFileIdChange={handleSelectedBuildFilesChange}
      />
      <DialogCommit
        ref={refDialogCommit}
        servicePartRecipes={servicePartRecipes}
        setButtonCommitDisabled={setIsConfirmDisabled}
      />
    </DialogCommonWizard>
  );
});

DialogRemoteCommitCoupon.displayName = "DialogRemoteCommitCoupon";

DialogRemoteCommitCoupon.propTypes = {
  username: PropTypes.string,
  tool: PropTypes.object,
};

export default DialogRemoteCommitCoupon;
