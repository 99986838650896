import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Button } from "syklone/ui/index.js";

// imports: local
import { DialogCommonBase } from "../dialog_common_base/index.js";

const withConfirmation = (DialogCommonBaseComponent) => {
  const DialogCommonConfirmation = ({ onConfirm, isConfirmDisabled, onCancel, children, actions, ...props }) => {
    const dataTag = props.dataTag ? props.dataTag : "dialog-common-confirmation";
    return (
      <DialogCommonBaseComponent
        {...props}
        actions={
          <>
            {actions}
            <Button
              disabled={isConfirmDisabled}
              autoFocus
              onClick={onConfirm}
              color="primary"
              data-syklone={`${dataTag}-button-ok`}
            >
              Ok
            </Button>
            <Button autoFocus onClick={onCancel} color="primary" data-syklone={`${dataTag}-button-cancel`}>
              Cancel
            </Button>
          </>
        }
      >
        {children}
      </DialogCommonBaseComponent>
    );
  };
  DialogCommonConfirmation.displayName = "DialogCommonConfirmation";
  DialogCommonConfirmation.propTypes = {
    ...DialogCommonBase.propTypes,
    onConfirm: PropTypes.func,
    isConfirmDisabled: PropTypes.bool,
    onCancel: PropTypes.func,
  };
  return DialogCommonConfirmation;
};

const DialogCommonConfirmation = withConfirmation(DialogCommonBase);

export default DialogCommonConfirmation;
