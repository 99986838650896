import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import {
  Button,
  Divider,
  Form,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Formik,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  Yup,
} from "syklone/ui/index.js";
import { WidgetInputNumber } from "syklone/components/widgets/index.js";

// imports: local
import WidgetJsonPreview from "./widget_json_preview.jsx";

const machineDefinitionSchema = Yup.object().shape({
  definitionName: Yup.string().required("Definition name is required."),
  manufacturer: Yup.string().required("Manufacturer is required."),
  model: Yup.string().required("Model is required."),
  chamberDimensions: Yup.object().shape({
    x: Yup.number()
      .min(0.001, "Dimension must be greater than 0.001")
      .max(1000, "Dimension must be less than or equal to 1000"),
    y: Yup.number()
      .min(0.001, "Dimension must be greater than 0.001")
      .max(1000, "Dimension must be less than or equal to 1000"),
    z: Yup.number()
      .min(0.001, "Dimension must be greater than 0.001")
      .max(1000, "Dimension must be less than or equal to 1000"),
  }),
  numberPlates: Yup.number().required(),
  isResizable: Yup.boolean().required(),
  origin: Yup.string().required("Platform Origin is required"),
  technology: Yup.object().shape({
    name: Yup.string().required("Technology is required"),
    options: Yup.object()
      .when("technology.name", ([technology], schema) =>
        technology === "laser"
          ? schema
              .min(0.001, "Slice must be greater than 0.001")
              .max(100, "Slice must be less than or equal to 100")
              .lessThan(Yup.ref("maxSliceRange"), "Value must be lower than maximum slice range")
          : schema
      )
      .required("Machine is required."),
  }),
  minSliceRange: Yup.number().when("technology.name", ([technology], schema) =>
    technology === "laser"
      ? schema
          .min(0.001, "Slice must be greater than 0.001")
          .max(100, "Slice must be less than or equal to 100")
          .lessThan(Yup.ref("maxSliceRange"), "Value must be lower than maximum slice range")
      : schema
  ),

  maxSliceRange: Yup.number().when("technology.name", ([technology], schema) =>
    technology === "laser"
      ? schema
          .min(0.001, "Slice must be greater than 0.001")
          .max(100, "Slice must be less than or equal to 100")
          .moreThan(Yup.ref("minSliceRange"), "Value must be greater than minimum slice range")
      : schema
  ),
});

const _Section = ({ title, children }) => {
  return (
    <Grid item container flexDirection="column">
      <Divider />
      <Typography component="h2" variant="h5" align="left" color="inherit" noWrap mt={2} mb={2}>
        {title}
      </Typography>
      {children}
    </Grid>
  );
};

_Section.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
};

const WidgetMachineDefinitionEditor = ({ initialValues, onSubmit }) => {
  const onChangeTechnology = (e, values, setValues) => {
    if (e.target.value !== "laser") {
      // eslint-disable-next-line no-unused-vars
      const { minSliceRange, maxSliceRange, ...newValues } = values;
      newValues.technology = {
        name: e.target.value,
        options: {},
      };
      setValues(newValues);
    } else {
      let newValues = { ...values };
      newValues.technology = {
        name: e.target.value,
        options: { numberOfLasers: 1 },
      };
      newValues["minSliceRange"] = 0;
      newValues["maxSliceRange"] = 0;
      setValues(newValues);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={machineDefinitionSchema}
      onSubmit={onSubmit}
      enableReinitialize={true}
    >
      {({ values, errors, touched, setValues, handleChange, setFieldValue, handleBlur, setFieldTouched }) => (
        <Form data-syklone="machine-editor" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <Grid container item xs={12} md={8} spacing={2}>
            <Grid item container justifyContent="flex-start">
              <TextField
                name="definitionName"
                label="Definition Name"
                variant="outlined"
                value={values.definitionName}
                onChange={handleChange}
                error={touched.definitionName && Boolean(errors.definitionName)}
                helperText={touched.definitionName && errors.definitionName}
              />
            </Grid>

            <_Section title="Machine Identity">
              <Grid container spacing={2} justifyContent="flex-start">
                <Grid item>
                  <TextField
                    name="manufacturer"
                    label="Manufacturer"
                    variant="outlined"
                    value={values.manufacturer}
                    onChange={handleChange}
                    error={touched.manufacturer && Boolean(errors.manufacturer)}
                    helperText={touched.manufacturer && errors.manufacturer}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    name="model"
                    label="Model"
                    variant="outlined"
                    value={values.model}
                    onChange={handleChange}
                    error={touched.model && Boolean(errors.model)}
                    helperText={touched.model && errors.model}
                  />
                </Grid>
              </Grid>
            </_Section>

            <_Section title="Chamber">
              <FormControlLabel
                sx={{ marginBottom: "1rem" }}
                control={
                  <Switch name="isResizable" checked={values.isResizable} onChange={handleChange} color="primary" />
                }
                label="Resizable"
              />

              <Grid item container spacing={2} justifyContent="flex-start">
                <Grid item>
                  <WidgetInputNumber
                    name="chamberDimensions.x"
                    label="Size X"
                    suffix="mm"
                    value={values.chamberDimensions.x}
                    onApply={(key, value) => {
                      setFieldTouched(key, true);
                      setFieldValue(key, value);
                    }}
                    onBlur={handleBlur}
                    error={Boolean(errors.chamberDimensions?.x) && touched.chamberDimensions?.x}
                    helperText={touched.chamberDimensions?.x && errors.chamberDimensions?.x}
                  />
                </Grid>
                <Grid item>
                  <WidgetInputNumber
                    name="chamberDimensions.y"
                    label="Size Y"
                    suffix="mm"
                    value={values.chamberDimensions.y}
                    onApply={(key, value) => {
                      setFieldTouched(key, true);
                      setFieldValue(key, value);
                    }}
                    onBlur={handleBlur}
                    error={Boolean(errors.chamberDimensions?.y) && touched.chamberDimensions?.y}
                    helperText={touched.chamberDimensions?.y && errors.chamberDimensions?.y}
                  />
                </Grid>
                <Grid item>
                  <WidgetInputNumber
                    name="chamberDimensions.z"
                    label="Size Z"
                    suffix="mm"
                    value={values.chamberDimensions.z}
                    onApply={(key, value) => {
                      setFieldTouched(key, true);
                      setFieldValue(key, value);
                    }}
                    onBlur={handleBlur}
                    error={Boolean(errors.chamberDimensions?.z) && touched.chamberDimensions?.z}
                    helperText={touched.chamberDimensions?.z && errors.chamberDimensions?.z}
                  />
                </Grid>
              </Grid>
            </_Section>

            <_Section title="Platform">
              <Grid item container spacing={2} justifyContent="flex-start">
                <Grid item>
                  <FormControl
                    variant="outlined"
                    sx={{ minWidth: "170px!important" }}
                    error={touched.origin && Boolean(errors.origin)}
                  >
                    <InputLabel id="select-platform-origin" key="select-platform-origin">
                      Platform Origin
                    </InputLabel>
                    <Select
                      name="origin"
                      label="Platform Origin"
                      onChange={handleChange}
                      value={values.origin}
                      defaultValue=""
                    >
                      <MenuItem value="bottomLeft">Bottom Left</MenuItem>
                      <MenuItem value="centre">Centre</MenuItem>
                    </Select>
                    {errors.origin && touched.origin && <FormHelperText>{errors.origin}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item>
                  <FormControl
                    variant="outlined"
                    sx={{ minWidth: "170px!important" }}
                    error={touched.numberPlates && Boolean(errors.numberPlates)}
                  >
                    <InputLabel id="select-number-plates" key="select-number-plates">
                      Number of Plates
                    </InputLabel>
                    <Select
                      name="numberPlates"
                      label="Number of Plates"
                      value={values.numberPlates}
                      onChange={(e) => {
                        setFieldValue(e.target.name, Number(e.target.value));
                      }}
                      defaultValue="1"
                    >
                      <MenuItem value="1">1</MenuItem>
                      <MenuItem value="2">2</MenuItem>
                      <MenuItem value="4">4</MenuItem>
                      <MenuItem value="8">8</MenuItem>
                    </Select>
                    {errors.numberPlates && touched.numberPlates && (
                      <FormHelperText>{errors.numberPlates}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </_Section>

            <_Section title="Technology">
              <Grid
                item
                container
                direction="column"
                flexDirection="column"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item>
                  <FormControl
                    variant="outlined"
                    sx={{ minWidth: "170px!important" }}
                    error={touched?.technology?.name && Boolean(errors?.technology?.name)}
                  >
                    <InputLabel id="select-technology" key="select-technology">
                      Technology
                    </InputLabel>
                    <Select
                      name="technology"
                      label="Technology"
                      value={values.technology.name}
                      onChange={(e) => onChangeTechnology(e, values, setValues)}
                      defaultValue=""
                    >
                      <MenuItem value="laser">Laser</MenuItem>
                      <MenuItem value="ebeam">E-Beam</MenuItem>
                      <MenuItem value="Custom">Custom</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </Select>
                    {errors.technology?.name && touched.technology?.name && (
                      <FormHelperText>{errors.technology?.name}</FormHelperText>
                    )}
                  </FormControl>
                </Grid>
                <Grid
                  container
                  item
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  spacing={2}
                >
                  {(() => {
                    switch (values.technology.name) {
                      case "laser":
                        return (
                          <>
                            <Grid item>
                              <WidgetInputNumber
                                name="minSliceRange"
                                label="Minimum Slice Value"
                                suffix="mm"
                                value={values.minSliceRange}
                                onApply={(key, value) => {
                                  setFieldTouched(key, true);
                                  setFieldValue(key, value);
                                }}
                                onBlur={handleBlur}
                                error={Boolean(errors.minSliceRange) && touched.minSliceRange}
                                helperText={touched.minSliceRange && errors.minSliceRange}
                              />
                            </Grid>
                            <Grid item>
                              <WidgetInputNumber
                                name="maxSliceRange"
                                label="Maximum Slice Value"
                                suffix="mm"
                                value={values.maxSliceRange}
                                onApply={(key, value) => {
                                  setFieldTouched(key, true);
                                  setFieldValue(key, value);
                                }}
                                onBlur={handleBlur}
                                error={Boolean(errors.maxSliceRange) && touched.maxSliceRange}
                                helperText={touched.maxSliceRange && errors.maxSliceRange}
                              />
                            </Grid>
                            <Grid item>
                              <FormControl
                                variant="outlined"
                                sx={{ minWidth: "170px!important" }}
                                error={
                                  Boolean(errors.technology?.options?.numberOfLasers) &&
                                  touched.technology?.options?.numberOfLasers
                                }
                              >
                                <InputLabel id="select-technology" key="select-technology">
                                  Number Of Lasers
                                </InputLabel>
                                <Select
                                  name="technology.options.numberOfLasers"
                                  label="Number Of Lasers"
                                  value={values.technology?.options?.numberOfLasers}
                                  onChange={(e) => {
                                    setFieldValue(e.target.name, Number(e.target.value));
                                  }}
                                >
                                  {Array.from({ length: 4 }, (_, i) => i + 1).map((numberOfLasers) => (
                                    <MenuItem key={numberOfLasers} value={numberOfLasers}>
                                      {numberOfLasers}
                                    </MenuItem>
                                  ))}
                                </Select>
                                {errors.technology?.options?.numberOfLasers &&
                                  touched.technology?.options?.numberOfLasers && (
                                    <FormHelperText>{errors.technology.options.numberOfLasers}</FormHelperText>
                                  )}
                              </FormControl>
                            </Grid>
                          </>
                        );
                      case "ebeam":
                        return (
                          <Grid item>
                            <span>In development...</span>
                          </Grid>
                        );
                      case "other":
                        return (
                          <Grid item>
                            <span>In development...</span>
                          </Grid>
                        );
                      default:
                    }
                  })()}
                </Grid>
              </Grid>
            </_Section>

            <Grid item xs={12}>
              <Divider style={{ marginBottom: "1rem" }} />
              <Grid container spacing={3} justifyContent="flex-start">
                <Grid item>
                  <WidgetJsonPreview json={values} />
                </Grid>
                <Grid item>
                  <Button size="large" color="primary" variant="contained" type="submit">
                    Commit
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

WidgetMachineDefinitionEditor.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func,
};

WidgetMachineDefinitionEditor.defaultProps = {
  initialValues: {
    definitionName: "",
    manufacturer: "",
    model: "",
    chamberDimensions: {
      x: 0,
      y: 0,
      z: 0,
    },
    numberPlates: 1,
    isResizable: false,
    origin: "",
    technology: { name: "", options: { numberOfLasers: null } },
  },
  onSubmit: () => {},
};

export default WidgetMachineDefinitionEditor;
