import React from "react";
import { Route, Routes, useNavigate, useLocation, Navigate } from "react-router-dom";

// imports: syklone
import { WidgetNotFound } from "syklone/components/widgets/index.js";
import { PermissionApplicationRoutes } from "syklone/api/modules/permission_settings/index.js";
import { AuthGuard, useAuthContext, useApiContext, AuthProtectedRoute } from "syklone/api/react/index.js";

// imports: local
import { PagePartCreator, PagePlatformCreator, PageBuildCreator } from "./components/pages/index.js";

function App() {
  const navigate = useNavigate();
  let location = useLocation();
  const api = useApiContext();
  const auth = useAuthContext();
  const authRedirectUrl = api.webAppUrls.urlPageGlobalFrontend;

  return (
    <Routes>
      {/* Note: root path will navigate to part-creator which uses authGuard that checks if user is loggedIn*/}
      <Route path="/" element={<Navigate to="/part-creator" />} />
      <Route element={<AuthGuard loginRoute={authRedirectUrl} />}>
        <Route
          exact
          path="/part-creator"
          element={
            <AuthProtectedRoute route={[PermissionApplicationRoutes.pagePartCreator]}>
              <PagePartCreator navigate={navigate} location={location} auth={auth} />
            </AuthProtectedRoute>
          }
        />
        <Route
          exact
          path="/platform-creator"
          element={
            <AuthProtectedRoute route={[PermissionApplicationRoutes.pagePlatformCreator]}>
              <PagePlatformCreator navigate={navigate} location={location} auth={auth} />
            </AuthProtectedRoute>
          }
        />
        <Route
          exact
          path="/build-creator/*"
          element={
            <AuthProtectedRoute route={[PermissionApplicationRoutes.pageBuildCreator]}>
              <PageBuildCreator />
            </AuthProtectedRoute>
          }
        ></Route>
      </Route>
      <Route path="*" element={<WidgetNotFound />} />
    </Routes>
  );
}

export default App;
