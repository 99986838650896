import React, { Component } from "react";
import PropTypes from "prop-types";

// imports: syklone
import { utils } from "syklone/common/index.js";
import {
  Box,
  createTheme,
  CssBaseline,
  Divider,
  Grid,
  IconButton,
  icons,
  Paper,
  SnackbarProvider,
  styled,
  ThemeProvider,
  themes,
} from "syklone/ui/index.js";
import { DialogProfile, DialogSettings } from "syklone/components/dialogs/index.js";
import {
  WidgetHistoryCommands,
  WidgetTab,
  WidgetTransformEditor,
  WidgetSlicerOptions,
} from "syklone/components/widgets/index.js";
import { createRestrictedToolPartCreator } from "syklone/graphics/tools/factories.js";
import { SelectableType } from "syklone/graphics/lib/index.js";
import {
  WidgetOutliner,
  WidgetPartCreatorAssistant,
  WidgetPartCreatorCommit,
  WidgetPartCreatorConfigMenu,
  WidgetPartCreatorImport,
  WidgetPartCreatorMenu,
  WidgetPartCreatorReview,
  WidgetRender,
} from "syklone/graphics/components/index.js";
import * as coreCommands from "syklone/graphics/tools/core_commands/index.js";
import * as commands from "syklone/graphics/tools/tools/tool_part_creator/commands/index.js";
import { withApiContext } from "syklone/api/react/index.js";

// imports: local
import {
  WidgetTopBar,
  WidgetPageTitle,
  WidgetMiniToolbar,
  WidgetConfigButton,
  WidgetAboutButton,
} from "../../widgets/index.js";
import {
  DialogAbout,
  DialogRemoteOpenParts,
  DialogRemoteSaveParts,
  DialogRemoteCommitParts,
  DialogRemoteCommitCoupon,
} from "../../dialogs/index.js";
import * as img from "./img/index.js";
import * as constants from "../constants.js";

class _PagePartCreator extends Component {
  static propTypes = {
    classes: PropTypes.object,
    auth: PropTypes.object,
    navigate: PropTypes.func,
    location: PropTypes.object,
    api: PropTypes.object,
  };

  // -------- Reactjs --------
  constructor(props) {
    super(props);

    this.tabItems = ["create part", "assign", "review", "commit"];
    this._username = props.auth?.user?.name || "";

    this.state = {
      theme: {},
      currentTabTitle: "create part",
      snapshots: null,
      isExpanded: false,
      panelTree: {},
      historyOpen: false,
      anchorEl: null,
      popperOpen: false,
      isTreeToolVisible: false,
      resetMenu: false,
      width: 0,
      height: 0,
    };
    this.refStepper = React.createRef();
    this.refWidgetRender = React.createRef();
    this.refWidgetOutliner = React.createRef();
    this.refWidgetPartTransform = React.createRef();
    this.refWidgetHistoryCommands = React.createRef();
    this.refDialogAbout = React.createRef();
    this.refDialogSettings = React.createRef();
    this.refDialogProfile = React.createRef();
    this.refDialogOpenPartsRemote = React.createRef();
    this.refDialogSavePartsRemote = React.createRef();
    this.refDialogCommitPartsRemote = React.createRef();
    this.refDialogCommitCouponRemote = React.createRef();
    this.refMenuAppBar = React.createRef();
    this.refWidgetSidebarResponsive = React.createRef();
    this.refWidgetTab = React.createRef();
    this.refWidgetPartCreatorReview = React.createRef();
    this.refWidgetPartCreatorCommit = React.createRef();
    this.refWidgetThemeSwitcher = React.createRef();
    this.refWidgetAssistant = React.createRef();
    this.refDialogSnapshotsParts = React.createRef();
    this.refParentDiv = React.createRef();
    this.handleHome = this.handleHome.bind(this);
    this.api = props.api;
  }

  componentDidMount = () => {
    const profileManager = this.api.profileManager;
    const sessionSettings = this.api.sessionSettings;
    const commandManager = this.api.commandManager;
    let widgetRender = this._getWidgetRender();

    profileManager.username = this.props.auth?.user?.name || "";
    let profile = profileManager.getCurrentProfile();

    // prettier-ignore
    this.tool = createRestrictedToolPartCreator(this.api, widgetRender, profile);
    this.setState({ panelTree: this.tool.getPanelTree() });

    // Add event listeners
    let eventFactory = widgetRender.getEventFactory();
    let eventTypes = eventFactory.getEventTypes();

    for (let v of eventTypes) {
      eventFactory.addEventListener(v, (...args) => console.log(v, args));
    }
    commandManager.addEventListener("EventCommandManagerChanged", this.handleCommandExecuted);
    eventFactory.addEventListener("EventSceneGraphChanged", (event) => {
      this.handleSceneChanged();
      this.setState({ resetMenu: false });
    });
    eventFactory.addEventListener("EventPartsChanged", (event) => {
      this.handleSceneChanged();
      this.setState({ resetMenu: false });
    });
    eventFactory.addEventListener("EventObjectTransformationChanged", (event) => {
      this.handleSceneChanged();
      this.setState({ resetMenu: false });
    });
    eventFactory.addEventListener("EventObjectTransformationFinished", (event) => {
      this.handleSceneChanged();
      this.setState({ resetMenu: false });
    });
    eventFactory.addEventListener("EventObjectMaterialsChanged", (event) => {
      this.handleSceneChanged();
      this.setState({ resetMenu: false });
    });
    eventFactory.addEventListener("EventObjectSelectionChanged", (event) => {
      this.handleSceneChanged();
      this.setState({ resetMenu: true });
    });
    eventFactory.addEventListener("EventObjectVisibilityChanged", (event) => {
      this.handleSceneChanged();
      this.setState({ resetMenu: false });
    });
    eventFactory.addEventListener("EventPanelActionClicked", (event) => {
      this.handlePanelActionClicked(event.params.actionName);
      this.setState({ resetMenu: false });
    });
    eventFactory.addEventListener("EventWidgetPresetChanged", (event) => {
      this.handleSceneChanged();
      this.setState({ resetMenu: false });
    });
    eventFactory.addEventListener("EventScenePresetChanged", (event) => {
      this.handleSceneChanged();
      this.setState({ resetMenu: false });
    });
    eventFactory.addEventListener("EventGridPresetChanged", (event) => {
      this.handleSceneChanged();
      this.setState({ resetMenu: false });
    });
    eventFactory.addEventListener("EventPlatformPresetChanged", (event) => {
      this.handleSceneChanged();
      this.setState({ resetMenu: false });
    });

    this._updateTabStatus();
    this.handleTheme(sessionSettings.data().isThemeLight);

    window.addEventListener("beforeunload", this._beforeUnloadHandler);

    const updateSize = () => {
      if (this.refParentDiv.current) {
        this.setState({
          width: this.refParentDiv.current.offsetWidth,
          height: this.refParentDiv.current.offsetHeight,
        });
      }
    };

    updateSize();

    this.resizeObserver = new ResizeObserver(updateSize);
    if (this.refParentDiv.current) {
      this.resizeObserver.observe(this.refParentDiv.current);
    }
  };

  componentWillUnmount = () => {
    window.removeEventListener("beforeunload", this._beforeUnloadHandler);

    if (this.refParentDiv.current) {
      this.resizeObserver.unobserve(this.refParentDiv.current);
    }
  };

  // -------- Private --------
  _getStepper = () => {
    return this.refStepper.current;
  };

  _getWidgetRender = () => {
    return this.refWidgetRender.current;
  };

  _getWidgetOutliner = () => {
    return this.refWidgetOutliner.current;
  };

  _getWidgetPartTransform = () => {
    return this.refWidgetPartTransform.current;
  };

  _getWidgetHistoryCommands = () => {
    return this.refWidgetHistoryCommands.current;
  };

  _getDialogAbout = () => {
    return this.refDialogAbout.current;
  };

  _getDialogSettings = () => {
    return this.refDialogSettings.current;
  };

  _getDialogProfile = () => {
    return this.refDialogProfile.current;
  };

  _getDialogOpenPartsRemote = () => {
    return this.refDialogOpenPartsRemote.current;
  };

  _getDialogSavePartsRemote = () => {
    return this.refDialogSavePartsRemote.current;
  };

  _getDialogCommitPartsRemote = () => {
    return this.refDialogCommitPartsRemote.current;
  };

  _getDialogCommitCouponRemote = () => {
    return this.refDialogCommitCouponRemote.current;
  };

  _getMenuAppBar = () => {
    return this.refMenuAppBar.current;
  };

  _getWidgetSidebarResponsive = () => {
    return this.refWidgetSidebarResponsive.current;
  };

  _getWidgetTab = () => {
    return this.refWidgetTab.current;
  };

  _getWidgetPartCreatorReview = () => {
    return this.refWidgetPartCreatorReview.current;
  };

  _getWidgetPartCreatorCommit = () => {
    return this.refWidgetPartCreatorCommit.current;
  };

  _getWidgetThemeSwitcher = () => {
    return this.refWidgetThemeSwitcher.current;
  };

  _getWidgetAssistant = () => {
    return this.refWidgetAssistant.current;
  };

  _getTheme = (isThemeLight) => {
    return isThemeLight ? themes.themeLight : themes.themeDark;
  };

  _getUsername = () => {
    return this.props.auth?.user?.name || "";
  };

  _getNumSelectedObjects = () => {
    let widgetRender = this.refWidgetRender.current;
    let scene = widgetRender.getScene();
    return scene.getSelectedObjects().size;
  };

  _getCurrentObject = () => {
    let widgetRender = this._getWidgetRender();
    let scene = widgetRender.getScene();
    return this._getNumSelectedObjects() === 1 ? scene.getSelectedObjects().values().next().value : null;
  };

  _updateTabStatus = () => {
    const widgetRender = this._getWidgetRender();
    const widgetTab = this._getWidgetTab();
    const api = this.api;

    const { status, tab } = api.validatePart(widgetRender);

    if (status === "doing") {
      widgetTab.setAsDoing(tab);
    } else if (status === "done") {
      widgetTab.setAsDone(tab);
    }
  };

  _getSnapshots = () => {
    return this.state.snapshots;
  };

  _setSnapshots = (snapshots) => {
    this.setState({
      snapshots: snapshots,
    });
  };

  _isVisible = () => {
    return this.state.currentTabTitle === "create part" || this.state.currentTabTitle === "assign" ? true : false;
  };

  _beforeUnloadHandler = (event) => {
    event.preventDefault();

    // Legacy support, e.g. Chrome/Edge < 119
    event.returnValue = true;
  };

  // -------- Handlers --------
  handleAddMaterial = (selectable) => {
    let widgetRender = this._getWidgetRender();
    const api = this.api;
    const commandManager = api.commandManager;
    commandManager.execute(new coreCommands.CommandAddMaterial(widgetRender, selectable));
  };

  handleAssignMaterial = (selectable, material) => {
    let widgetRender = this._getWidgetRender();
    const api = this.api;
    const commandManager = api.commandManager;
    commandManager.execute(new coreCommands.CommandAssignMaterial(widgetRender, selectable, material));
  };

  handleChangeFamily = (material, family, parameters, paramSet) => {
    let widgetRender = this._getWidgetRender();
    const api = this.api;
    const commandManager = api.commandManager;
    commandManager.execute(
      new coreCommands.CommandChangeMaterialFamily(widgetRender, material, family, parameters, paramSet)
    );
  };

  handleChangeParamSet = (material, parameters, paramSet) => {
    let widgetRender = this._getWidgetRender();
    const api = this.api;
    const commandManager = api.commandManager;
    commandManager.execute(
      new coreCommands.CommandChangeMaterialParamSet(widgetRender, material, parameters, paramSet)
    );
  };

  handleChangeType = (selectable, type) => {
    let widgetRender = this._getWidgetRender();
    const api = this.api;
    const commandManager = api.commandManager;
    commandManager.execute(new coreCommands.CommandChangeObjectType(widgetRender, selectable, type));
  };

  handleCopyLabelToClipboard = (label) => {
    let widgetRender = this._getWidgetRender();
    const api = this.api;
    const commandManager = api.commandManager;

    console.log("onCopyLabelToClipboard", label);
    let state = {
      cubicLabel: label.toDict(),
      curve: label.getCurve().toDict(),
    };
    let text = JSON.stringify(state, null, 4);
    commandManager.execute(new coreCommands.CommandCopyToClipboard(widgetRender, text));
  };

  handleDeleteMaterial = (selectable, material) => {
    let widgetRender = this._getWidgetRender();
    const api = this.api;
    const commandManager = api.commandManager;
    commandManager.execute(new coreCommands.CommandDeleteMaterial(widgetRender, selectable, material));
  };

  handleIsolateComponent = (selectable) => {
    let widgetRender = this._getWidgetRender();
    const api = this.api;
    const commandManager = api.commandManager;
    commandManager.execute(new coreCommands.CommandIsolateObject(widgetRender, selectable));
  };

  handleMakeVisibleItem = (selectable) => {
    let widgetRender = this._getWidgetRender();
    const api = this.api;
    const commandManager = api.commandManager;
    commandManager.execute(new coreCommands.CommandToggleSelectableVisibility(widgetRender, selectable));
  };

  handleSelectFacesByMaterial = (selectable, material) => {
    let widgetRender = this._getWidgetRender();
    const api = this.api;
    const commandManager = api.commandManager;
    commandManager.execute(new coreCommands.CommandSelectFacesByMaterial(widgetRender, selectable, material));
  };

  handleSelectItem = (selectable) => {
    let widgetRender = this._getWidgetRender();
    const api = this.api;
    const commandManager = api.commandManager;
    commandManager.execute(new coreCommands.CommandSelectSpecificObject(widgetRender, selectable));
  };

  getMaterialComponentStatus = (material) => {
    if (
      material.getBuildParams().getFamily() !== "Unassigned" &&
      material.getBuildParams().getParamSet() !== "Unassigned"
    ) {
      return true;
    }
    return false;
  };

  getMaterialParameters = () => {
    const api = this.api;
    return api.sykloneApi.sk.svcBuildCreator.ApiPostProcessing.getAll();
  };

  getLabelProperties = (label) => {
    return label.getProperties();
  };

  getLabelStatus = (label) => {
    return label.isValid();
  };

  handleCloseLabelPropertiesDialog = (label) => {};

  handleOpenLabelPropertiesDialog = (label) => {};

  handleChangeLabelProperties = (label, properties) => {
    const widgetRender = this._getWidgetRender();
    const api = this.api;
    const commandManager = api.commandManager;

    commandManager.execute(new coreCommands.CommandSubmitLabelProperties(widgetRender, label, properties));
  };

  handleDeleteLabel = (selectable, label) => {
    const widgetRender = this._getWidgetRender();
    const api = this.api;
    const commandManager = api.commandManager;

    commandManager.execute(new coreCommands.CommandDeleteLabel(widgetRender, selectable, label));
  };

  handleOpenLabellingMode = (selectable) => {
    const widgetRender = this._getWidgetRender();
    const api = this.api;
    const commandManager = api.commandManager;
    const tool = this.tool;

    const toolCubicLabels = tool.getChildByName("ToolCubicLabels");
    commandManager.execute(new coreCommands.CommandSwitchTool(toolCubicLabels));
    commandManager.execute(new coreCommands.CommandCenterAroundObject(widgetRender, selectable));
  };

  getSceneComponentStatus = (selectable) => {
    const materials = selectable.getMaterials();
    for (let i = 0; i < materials.length; i++) {
      const material = materials[i];
      if (
        material.getBuildParams().getFamily() === "Unassigned" ||
        material.getBuildParams().getParamSet() === "Unassigned"
      ) {
        return false;
      }
    }

    for (let label of selectable.getLabels()) {
      if (!label.isValid()) return false;
    }

    return true;
  };

  getSelectableTypes = () => {
    return Object.values(SelectableType);
  };

  handleChangeVectorScanOrder = (array) => {
    const widgetRender = this._getWidgetRender();
    const api = this.api;
    const commandManager = api.commandManager;
    const scene = widgetRender.getScene();
    const grid = scene.getGrid();
    const slicerOptions = grid.getSlicerOptions();

    const data = { ...slicerOptions.getData(), vectorScanOrder: array };

    commandManager.execute(new coreCommands.CommandSetPartSlicerOptions(widgetRender, data));
  };

  handleCloseDialogSlicerOptions = () => null;

  handleOpenDialogSlicerOptions = () => null;

  handleResetVectorScanOrder = (data) => {
    const widgetRender = this._getWidgetRender();
    const api = this.api;
    const commandManager = api.commandManager;
    const scene = widgetRender.getScene();
    const grid = scene.getGrid();
    const slicerOptions = grid.getSlicerOptions();

    const output = { ...slicerOptions.getData(), vectorScanOrder: data };

    commandManager.execute(new coreCommands.CommandSetPartSlicerOptions(widgetRender, output));
  };

  getPorousOptimization = () => {
    const widgetRender = this._getWidgetRender();
    const scene = widgetRender.getScene();
    const grid = scene.getGrid();
    const slicerOptions = grid.getSlicerOptions();

    return slicerOptions.getData().porousOptimization;
  };

  getPorousOptimizationOptions = () => {
    const widgetRender = this._getWidgetRender();
    const scene = widgetRender.getScene();
    const grid = scene.getGrid();
    const slicerOptions = grid.getSlicerOptions();

    return Object.values(slicerOptions.getPorousOptimizationOptions());
  };

  getSupportOptimization = () => {
    const widgetRender = this._getWidgetRender();
    const scene = widgetRender.getScene();
    const grid = scene.getGrid();
    const slicerOptions = grid.getSlicerOptions();

    return slicerOptions.getData().supportOptimization;
  };

  handlePorousOptimizationChange = (e) => {
    const widgetRender = this._getWidgetRender();
    const api = this.api;
    const commandManager = api.commandManager;
    const scene = widgetRender.getScene();
    const grid = scene.getGrid();
    const slicerOptions = grid.getSlicerOptions();

    const data = { ...slicerOptions.getData(), porousOptimization: e.target.value };

    commandManager.execute(new coreCommands.CommandSetPartSlicerOptions(widgetRender, data));
  };

  handleSupportOptimizationChange = (e) => {
    const widgetRender = this._getWidgetRender();
    const api = this.api;
    const commandManager = api.commandManager;
    const scene = widgetRender.getScene();
    const grid = scene.getGrid();
    const slicerOptions = grid.getSlicerOptions();
    const data = { ...slicerOptions.getData(), supportOptimization: e };

    commandManager.execute(new coreCommands.CommandSetPartSlicerOptions(widgetRender, data));
  };

  getVectorScanOrderData = () => {
    const widgetRender = this._getWidgetRender();
    const scene = widgetRender.getScene();
    const grid = scene.getGrid();
    const slicerOptions = grid.getSlicerOptions();

    return {
      current: slicerOptions.getData().vectorScanOrder,
      default: slicerOptions.getDefaultData().vectorScanOrder,
    };
  };

  handleSceneChanged = () => {
    let widgetRender = this._getWidgetRender();
    let widgetOutliner = this._getWidgetOutliner();
    let widgetReviewParts = this._getWidgetPartCreatorReview();
    let scene = widgetRender.getScene();
    const labels = scene.getLabels();
    let selectables = scene.getSelectables();

    // widgetOutliner
    widgetOutliner.setSelectables(selectables, scene.getSelectedObjects());

    // widgetReviewParts
    widgetReviewParts.setSelectables(selectables);
    widgetOutliner.setLabels(labels);

    // widgetTab
    this._updateTabStatus();

    // widgetPartTransform
    let widgetPartTransform = this._getWidgetPartTransform();
    let numSelectedObjects = this._getNumSelectedObjects();
    let obj = this._getCurrentObject();
    widgetPartTransform.setParams(obj, numSelectedObjects);
  };

  handleTransformItem = (object, transformationProperty, value) => {
    this.tool.registerEditTransformation(object, transformationProperty, value);
    this._updateTabStatus();
  };

  handleLogout = () => {
    this.props.auth.logout();
  };

  handleHome = () => {
    try {
      this.props.navigate("/");
    } catch (e) {
      let widgetRender = this._getWidgetRender();
      let notifier = widgetRender.getNotifier();
      notifier.addNotificationError(`${e}`);
    }
  };

  handleImport = async () => {
    const api = this.api;
    const commandManager = api.commandManager;
    let widgetRender = this._getWidgetRender();
    await commandManager.executeAsync(new commands.CommandImportComponentsLocally(widgetRender));
  };

  handleImportRemote = async () => {
    let dialogOpenPartsRemote = this._getDialogOpenPartsRemote();
    await dialogOpenPartsRemote.show(this.tool);
  };

  handleSettings = () => {
    let dialogSettings = this._getDialogSettings();
    dialogSettings.show();
  };

  handleProfile = () => {
    let dialogSettings = this._getDialogProfile();
    dialogSettings.setDialogOpen(true);
  };

  handleAbout = () => {
    let dialogAbout = this._getDialogAbout();
    dialogAbout.show();
  };

  handleAssistant = () => {
    alert("assistant");
  };

  handleCommandExecuted = (event) => {
    const api = this.api;
    const commandManager = api.commandManager;

    let widgetHistoryCommands = this._getWidgetHistoryCommands();
    if (widgetHistoryCommands) {
      widgetHistoryCommands.setCommandManager(commandManager);
      if (utils.optionalChaining(() => event.params.command.getClassName()) === "CommandNewProject") {
        // TODO: Fill out when adding the platform-creator snapshots
      }
    }
  };

  handleSideBar = () => {
    let widgetSidebarResponsive = this._getWidgetSidebarResponsive();
    widgetSidebarResponsive.show(true);
  };

  handleChangeTab = (tabId) => {
    let widgetTab = this._getWidgetTab();
    this.setState(
      {
        currentTabTitle: widgetTab.getCurrentTabTitle(),
        historyOpen: false,
      },
      () => {
        let tabTitle = widgetTab.getCurrentTabTitle();
        if (tabTitle === "commit") {
          let widgetCommitParts = this._getWidgetPartCreatorCommit();
          let widgetRender = this._getWidgetRender();
          widgetCommitParts.validate(widgetRender);
        }
      }
    );
  };

  handleChangeTabStatus = (tabStatus) => {
    const widgetAssistant = this._getWidgetAssistant();
    widgetAssistant.setMenuStates(tabStatus);
  };

  handleTheme = (isThemeLight) => {
    const api = this.api;
    const sessionSettings = api.sessionSettings;

    let data = sessionSettings.data();
    data.isThemeLight = isThemeLight;
    sessionSettings.save(data);
    this.setState({
      theme: this._getTheme(isThemeLight),
    });
  };

  handleCommitPartsRemote = async () => {
    this._getDialogCommitPartsRemote().show();
  };

  handleCommitCouponRemote = async () => {
    this._getDialogCommitCouponRemote().show();
  };

  handlePanelActionClicked = async (action) => {
    const api = this.api;
    const commandManager = api.commandManager;

    if (action === "openRemote") {
      let dialogOpenPartsRemote = this._getDialogOpenPartsRemote();
      await dialogOpenPartsRemote.show(this.tool);
    } else if (action === "saveRemote") {
      let dialogSavePartsRemote = this._getDialogSavePartsRemote();
      await dialogSavePartsRemote.show(this.tool);
    } else if (action === "undo") {
      if (commandManager.canUndo()) {
        commandManager.undo();
      }
    } else if (action === "redo") {
      if (commandManager.canRedo()) {
        commandManager.redo();
      }
    }
  };

  handleToggleSideBarDesktop = () => {
    this.setState(
      {
        isExpanded: !this.state.isExpanded,
      },
      () => {
        window.dispatchEvent(new Event("resize"));
      }
    );
  };

  handleSaveSettings = (settings) => {
    const api = this.api;
    const sessionSettings = api.sessionSettings;
    sessionSettings.save(settings);
  };

  handleResetSettings = () => {
    const api = this.api;
    const sessionSettings = api.sessionSettings;

    let dct = sessionSettings.defaults();
    sessionSettings.save(dct);
  };

  handleHistoryClick = () => {
    this.setState((prevState) => ({
      historyOpen: !prevState.historyOpen,
      isTreeToolVisible: false,
    }));
  };

  handlePopperClick = (event) => {
    this.setState((prevState) => ({
      popperOpen: !prevState.popperOpen,
    }));
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  // -------- Public: Component creation --------
  createAppHeader = () => {
    const api = this.api;
    const sessionSettings = api.sessionSettings;

    return (
      <_AppHeaderGrid data-syklone="part_creator-header" isThemeLight={sessionSettings.data().isThemeLight}>
        <_AppHeaderLeftBox>
          <_SideBarButton aria-label="menu" onClick={this.handleSideBar}>
            <icons.mui.Menu fontSize="large" />
          </_SideBarButton>
          <img src={img.LogoPartCreator} alt="Part Creator" />
        </_AppHeaderLeftBox>
        <_AppHeaderMidBox>
          <Grid item xs={12}>
            <WidgetTab
              onChangeTab={this.handleChangeTab}
              onChangeTabStatus={this.handleChangeTabStatus}
              ref={this.refWidgetTab}
              items={this.tabItems}
              showIcons={sessionSettings.data().showTabIcons}
            />
          </Grid>
        </_AppHeaderMidBox>
      </_AppHeaderGrid>
    );
  };

  createGroups = () => {
    const api = this.api;
    const commandManager = api.commandManager;
    return [
      {
        links: [
          {
            name: "Menu",
            description: this.state.isTreeToolVisible ? "Close" : "Open advanced menu",
            icon: <icons.mui.Tune fontSize="small" />,
            action: () =>
              this.setState((prevState) => ({
                historyOpen: false,
                isTreeToolVisible: !prevState.isTreeToolVisible,
              })),
            isActive: this.state.isTreeToolVisible,
          },
        ],
      },
      {
        links: [
          {
            name: "New project",
            description: "Create new project",
            icon: <icons.mui.FiberNew fontSize="small" />,
            action: () => commandManager.execute(new coreCommands.CommandNewProject(api, this.tool)),
            isActive: null,
          },
        ],
      },
    ];
  };

  // -------- Render --------
  reRender = () => {
    const api = this.api;
    const sessionSettings = api.sessionSettings;

    this.forceUpdate();
    this.handleTheme(sessionSettings.data().isThemeLight);
  };

  render() {
    let theme = createTheme(this.state.theme);
    const api = this.api;
    const sessionSettings = api.sessionSettings;
    const commandManager = api.commandManager;
    const { width } = this.state;

    return (
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <CssBaseline />
          <WidgetPageTitle title="Part creator" />
          <>
            {this.tool ? (
              <WidgetPartCreatorConfigMenu
                tool={this.tool}
                popperOpen={this.state.popperOpen}
                popperAchhorEl={this.state.anchorEl}
              />
            ) : null}

            <_RootBox>
              <Box>
                <DialogAbout ref={this.refDialogAbout} />
                <DialogSettings
                  data={sessionSettings.data()}
                  options={sessionSettings.options()}
                  onOk={this.handleSaveSettings}
                  onReset={this.handleResetSettings}
                  ref={this.refDialogSettings}
                  reRender={this.reRender}
                />
                <DialogRemoteOpenParts ref={this.refDialogOpenPartsRemote} username={this._getUsername()} />
                <DialogRemoteSaveParts ref={this.refDialogSavePartsRemote} username={this._getUsername()} />
                <DialogRemoteCommitParts
                  ref={this.refDialogCommitPartsRemote}
                  widgetRender={this._getWidgetRender()}
                  tool={this.tool}
                  username={this._getUsername()}
                />
                <DialogRemoteCommitCoupon
                  ref={this.refDialogCommitCouponRemote}
                  tool={this.tool}
                  username={this._getUsername()}
                />
                <WidgetPartCreatorAssistant
                  ref={this.refWidgetAssistant}
                  tabItems={this.tabItems}
                  activeTabName={this.state.currentTabTitle}
                  panelTree={this.state.panelTree}
                  menuStates={{}}
                />
              </Box>
              <_TopBox>
                <_LogoBox>
                  <img src={img.LogoPartCreatorAmblem} />
                </_LogoBox>
                <DialogProfile ref={this.refDialogProfile} data={this.props.auth?.user} />
                <WidgetTopBar
                  isLightTheme={sessionSettings.data().isThemeLight}
                  username={this._getUsername()}
                  hasNotification={false}
                  onSettings={this.handleSettings}
                  onProfile={this.handleProfile}
                  handleLogout={this.handleLogout}
                  handleAbout={this.handleAbout}
                  handleTheme={this.handleTheme}
                  refWidgetThemeSwitcher={this.refWidgetThemeSwitcher}
                />
              </_TopBox>

              <_ContentBox>
                <_TabBox>
                  <WidgetTab
                    isVertical={true}
                    onChangeTab={this.handleChangeTab}
                    onChangeTabStatus={this.handleChangeTabStatus}
                    ref={this.refWidgetTab}
                    items={this.tabItems}
                    showIcons={sessionSettings.data().showTabIcons}
                  />
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                    <WidgetConfigButton onClickConfigButton={this.handlePopperClick} isOpen={this.state.popperOpen} />
                    <WidgetAboutButton onClickAboutButton={this.handleAbout} />
                  </Box>
                </_TabBox>

                <_ToolBox
                  sx={{
                    display:
                      this.state.currentTabTitle === "create part" || this.state.currentTabTitle === "assign"
                        ? "flex"
                        : "none",
                  }}
                >
                  <_RightPanelBox
                    data-syklone="widget-right_panel"
                    isThemeLight={sessionSettings.data().isThemeLight}
                    visible={this._isVisible()}
                  >
                    <Grid
                      item
                      xs={12}
                      hidden={this.state.currentTabTitle !== "create part"}
                      sx={{ paddingTop: "0.5rem" }}
                    >
                      <Grid container direction="column" spacing={2}>
                        <Grid item xs={12}>
                          <WidgetPartCreatorImport
                            onLocalImport={this.handleImport}
                            onRemoteImport={this.handleImportRemote}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <WidgetTransformEditor
                            ref={this.refWidgetPartTransform}
                            onTransform={this.handleTransformItem}
                            headerVersion="2"
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      hidden={this.state.currentTabTitle !== "assign"}
                      sx={{ height: "calc(100vh - 175px)", overflow: "auto", paddingTop: "0.5rem" }}
                    >
                      <WidgetSlicerOptions
                        getters={{
                          getPorousOptimization: this.getPorousOptimization,
                          getPorousOptimizationOptions: this.getPorousOptimizationOptions,
                          getSupportOptimization: this.getSupportOptimization,
                          getVectorScanOrderData: this.getVectorScanOrderData,
                        }}
                        handlers={{
                          onCloseDialog: this.handleCloseDialogSlicerOptions,
                          onOpenDialog: this.handleOpenDialogSlicerOptions,
                          onPorousOptimizationChange: this.handlePorousOptimizationChange,
                          onReset: this.handleResetVectorScanOrder,
                          onSupportOptimizationChange: this.handleSupportOptimizationChange,
                          onVectorScanOrderChange: this.handleChangeVectorScanOrder,
                        }}
                        headerVersion="2"
                      />
                      <Divider sx={{ margin: "0.5rem 1rem" }} />
                      <WidgetOutliner
                        ref={this.refWidgetOutliner}
                        getters={{
                          getLabelProperties: this.getLabelProperties,
                          getLabelStatus: this.getLabelStatus,
                          getMaterialComponentStatus: this.getMaterialComponentStatus,
                          getMaterialParameters: this.getMaterialParameters,
                          getSceneComponentStatus: this.getSceneComponentStatus,
                          getSelectableTypes: this.getSelectableTypes,
                        }}
                        handlers={{
                          onAddMaterial: this.handleAddMaterial,
                          onAssignMaterial: this.handleAssignMaterial,
                          onChangeFamily: this.handleChangeFamily,
                          onChangeLabelProperties: this.handleChangeLabelProperties,
                          onChangeParamSet: this.handleChangeParamSet,
                          onChangeType: this.handleChangeType,
                          onCloseLabelPropertiesDialog: this.handleCloseLabelPropertiesDialog,
                          onCopyLabelToClipboard: this.handleCopyLabelToClipboard,
                          onDeleteLabel: this.handleDeleteLabel,
                          onDeleteMaterial: this.handleDeleteMaterial,
                          onIsolateComponent: this.handleIsolateComponent,
                          onMakeVisibleItem: this.handleMakeVisibleItem,
                          onOpenLabellingMode: this.handleOpenLabellingMode,
                          onOpenLabelPropertiesDialog: this.handleOpenLabelPropertiesDialog,
                          onSelectFacesByMaterial: this.handleSelectFacesByMaterial,
                          onSelectItem: this.handleSelectItem,
                        }}
                        headerVersion="2"
                      />
                    </Grid>
                  </_RightPanelBox>
                </_ToolBox>
                <_MenuBox
                  sx={{
                    display:
                      this.state.currentTabTitle === "create part" || this.state.currentTabTitle === "assign"
                        ? "flex"
                        : "none",
                  }}
                >
                  {this.tool ? (
                    <WidgetPartCreatorMenu
                      commandManager={commandManager}
                      tool={this.tool}
                      widgetRender={this._getWidgetRender()}
                      step={this.state.currentTabTitle}
                      savePart={this.handlePanelActionClicked}
                      handeHistory={this.handleHistoryClick}
                      resetMenu={this.state.resetMenu}
                    />
                  ) : null}
                </_MenuBox>

                <_RenderBox
                  hasBackground={this.state.currentTabTitle === "review" || this.state.currentTabTitle === "commit"}
                  style={{
                    width:
                      this.state.currentTabTitle === "review" || this.state.currentTabTitle === "commit"
                        ? "100%"
                        : `calc(100% - (${constants.BOX_COLUMN_SIZE_MD} + 118px))`,
                  }}
                >
                  <_MainPartBox
                    data-syklone="widget-main_part"
                    visible={this._isVisible()}
                    style={{
                      width: "100%",
                    }}
                  >
                    <Grid
                      item
                      style={{ position: "relative" }}
                      xs={12}
                      hidden={this.state.currentTabTitle !== "create part" && this.state.currentTabTitle !== "assign"}
                      ref={this.refParentDiv}
                    >
                      <>
                        {width > 200 && (
                          <_WidgetMiniToolbarWrapper>
                            <WidgetMiniToolbar groups={this.createGroups()} />
                          </_WidgetMiniToolbarWrapper>
                        )}

                        <_WidgetRenderWrapper
                          data-syklone="widget-render-wrapper"
                          visible={this.state.isTreeToolVisible}
                        >
                          <WidgetRender
                            ref={this.refWidgetRender}
                            onSnapshotTaken={this.handleSnapshotTaken}
                            onPanelActionClicked={this.handlePanelActionClicked}
                            isWidgetRenderHeightAuto={true}
                            cameraToggle={false}
                          />
                        </_WidgetRenderWrapper>

                        <_HistoryBox visible={this.state.historyOpen}>
                          <_CustomPaper>
                            <WidgetHistoryCommands
                              ref={this.refWidgetHistoryCommands}
                              commandManager={commandManager}
                              areaMaxHeight={245}
                            />
                          </_CustomPaper>
                        </_HistoryBox>
                      </>
                    </Grid>
                  </_MainPartBox>
                  <Grid container>
                    <Grid
                      item
                      xs={12}
                      hidden={this.state.currentTabTitle !== "review"}
                      style={{ height: constants.CENTRAL_WIDGET_HEIGHT }}
                    >
                      <WidgetPartCreatorReview ref={this.refWidgetPartCreatorReview} />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      hidden={this.state.currentTabTitle !== "commit"}
                      style={{ height: constants.CENTRAL_WIDGET_HEIGHT }}
                    >
                      <WidgetPartCreatorCommit
                        isActive={this.state.currentTabTitle === "commit"}
                        onCommitAsPart={this.handleCommitPartsRemote}
                        onCommitAsCoupon={this.handleCommitCouponRemote}
                        ref={this.refWidgetPartCreatorCommit}
                      />
                    </Grid>
                  </Grid>
                </_RenderBox>
              </_ContentBox>
            </_RootBox>
          </>
        </SnackbarProvider>
      </ThemeProvider>
    );
  }
}

const PagePartCreatorNew = withApiContext(_PagePartCreator);

export default PagePartCreatorNew;

// -------- Private: Styled components --------
const _RootBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  height: "100vh",
  "& *::-webkit-scrollbar": {
    width: "6px",
    height: "6px",
  },
  "& *::-webkit-scrollbar-thumb": {
    minHeight: "24px",
    borderRadius: "8px",
    backgroundColor: "#585859",
  },
  "& *::-webkit-scrollbar-corner": {
    backgroundColor: "#202022",
  },
});

const _TopBox = styled(Box)({
  display: "flex",
  height: "56px",
  alignItems: "center",
  borderBottom: "1px solid #3C3C3C",
  justifyContent: "space-between",
});

const _LogoBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRight: "1px solid #3C3C3C",
  width: "62px",
});

const _ContentBox = styled(Box)({
  display: "flex",
  height: "100%",
});

const _ToolBox = styled(Box)({
  backgroundColor: "#212121",
  minWidth: "530px",
  overflowX: "hidden",
  overflowY: "auto",
  height: "calc(100vh - 56px)",
});

const _TabBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: "8px",
});

const _MenuBox = styled(Box)({
  minWidth: "55px",
  overflowX: "hidden",
  overflowY: "auto",
  height: "calc(100vh - 56px)",
});

const _RenderBox = styled(Box, {
  shouldForwardProp: (props) => props !== "hasBackground",
})(({ hasBackground }) => ({
  width: "100%",
  padding: "18px 18px 0px 18px",
  backgroundColor: hasBackground ? "#212121" : "transparent",
}));

/* OLD UI */
const _MainPartBox = styled(Box, {
  shouldForwardProp: (props) => props !== "visible",
})(({ visible }) => ({
  display: visible ? "inline-block" : "none",
  position: "relative",
  "@media (max-width: 1280px)": {
    width: "100%!important",
    paddingRight: "0px",
  },
}));

const _RightPanelBox = styled(Box, {
  shouldForwardProp: (props) => props !== "visible" && props !== "isThemeLight",
})(({ isThemeLight, visible }) => ({
  display: visible ? "inline-block" : "none",
  width: `${constants.BOX_COLUMN_SIZE_MD}`,
  minWidth: `${constants.BOX_COLUMN_SIZE_MD}`,
  "@media (max-width: 1280px)": {
    width: "100%",
    borderTopRightRadius: "6px",
    borderBottomRightRadius: "6px",
  },
  verticalAlign: "top",
  overflow: "hidden",
}));

const _AppHeaderGrid = styled(Grid, {
  shouldForwardProp: (props) => props !== "isThemeLight",
})(({ isThemeLight }) => ({
  display: "flex",
  position: "relative",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "5px",
  width: "100%",
  padding: "11px 30px",
  "@media (max-width: 960px)": {
    flexDirection: "column!important",
    padding: "0px 20px",
  },
  borderBottom: `1px solid ${isThemeLight ? "#E8E8E8" : "#2F2F2F"}`,
}));

const _AppHeaderLeftBox = styled(Box)({
  display: "flex",
  flex: "0 0 190px",
  "@media (max-width: 960px)": {
    flex: 1,
    width: "100%",
    justifyContent: "space-between",
    flexDirection: "row-reverse",
  },
});

const _CustomPaper = styled(Paper)({
  "& .MuiGrid-container .MuiBox-root:before": {
    display: "none",
  },
  "& .MuiGrid-container h2": {
    fontSize: "16px",
    paddingLeft: "16px",
  },
});

const _AppHeaderMidBox = styled(Box)({
  position: "absolute",
  bottom: 0,
  left: "50%",
  transform: "translateX(-50%)",
  "@media (max-width: 960px)": {
    position: "relative",
    flexGrow: 1,
    left: "initial",
    transform: "initial",
    width: "100%",
  },
});

const _HistoryBox = styled(Box, {
  shouldForwardProp: (props) => props !== "visible",
})(({ visible }) => ({
  display: visible ? "initial" : "none",
  position: "absolute",
  width: "360px",
  bottom: "-10px",
  left: "-14px",
}));

const _SideBarButton = styled(IconButton)({
  display: "none!important", // TODO: [components] why are we keeping it then?
  "@media (max-width: 1280px)": {
    display: "inline-flex!important",
    width: "48px",
    height: "48px",
  },
});

const _WidgetRenderWrapper = styled(Box, {
  shouldForwardProp: (props) => props !== "visible",
})(({ visible }) => ({
  height: "calc(100vh - 94px)",
  ".dg.main": {
    marginTop: "40px",
    padding: "6px",
    backgroundColor: "#222",
    borderRadius: "6px",
    display: visible ? "initial" : "none",
    maxHeight: "601px!important",
  },
  ".dg.main .close-button": {
    width: "auto!important",
  },
  "#widget-render-toolbar": {
    inset: "auto!important",
    top: "0px!important",
    left: "1rem!important",
    bottom: "0px!important",
  },
}));

const _WidgetMiniToolbarWrapper = styled(Box)({
  position: "absolute",
  right: "6px",
  top: "6px",
  zIndex: 1,
});
