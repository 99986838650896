import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Box, Grid, IconButton, Tooltip, styled } from "syklone/ui/index.js";

const _GroupItem = styled(Box)({
  background: "#252525",
  padding: ".2rem",
  borderRadius: "3px",
});

const _CustomButton = styled(IconButton, {
  shouldForwardProp: (props) => props !== "active",
})(({ active }) => ({
  color: active && "#FFB500",
}));

function WidgetMiniToolbar({ groups }) {
  if (groups.length === 0) {
    return null;
  }

  return (
    <>
      <Grid container spacing={0.7}>
        {groups.map((group, index) =>
          group.links.length > 1 ? (
            <Grid item key={index}>
              <_GroupItem>
                <Grid container>
                  {group.links.map((item, index) => (
                    <Grid key={index}>
                      <Tooltip title={item.description} placement="bottom" arrow>
                        <div>
                          <_CustomButton
                            onClick={item.action}
                            size="small"
                            data={`mini-toolbar-item-${item.name}`}
                            active={item.isActive}
                          >
                            {item.icon}
                          </_CustomButton>
                        </div>
                      </Tooltip>
                    </Grid>
                  ))}
                </Grid>
              </_GroupItem>
            </Grid>
          ) : (
            <Grid item key={index}>
              <_GroupItem>
                <Grid container>
                  {group.links.map((item, index) => (
                    <Grid key={index}>
                      <Tooltip title={item.description} placement="bottom" arrow>
                        <div>
                          <IconButton onClick={item.action} size="small" data={`mini-toolbar-item-${item.name}`}>
                            {item.icon}
                          </IconButton>
                        </div>
                      </Tooltip>
                    </Grid>
                  ))}
                </Grid>
              </_GroupItem>
            </Grid>
          )
        )}
      </Grid>
    </>
  );
}

WidgetMiniToolbar.propTypes = {
  groups: PropTypes.array,
};

export default WidgetMiniToolbar;
