import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { DataGrid, GridToolbar } from "syklone/ui/index.js";

const DialogRemoteCommonDatagrid = ({ onSelectionModelChanged, onCellDoubleClick, onDelete, rows, columns }) => {
  const selectedRow = React.useRef(null);

  const onSelectRow = (e) => {
    const lst = rows.filter((row) => Object.values(e).includes(row.id));
    const currentRow = lst.length > 0 ? lst[0] : null;
    selectedRow.current = currentRow;
    onSelectionModelChanged(currentRow);
  };

  const onCellKeyDown = (params, event, details) => {
    if (event.key !== "Delete") {
      return;
    }
    onDelete && onDelete(selectedRow.current);
  };

  return (
    <DataGrid
      onSelectionModelChange={onSelectRow}
      onCellClick={onSelectRow}
      onCellKeyDown={onCellKeyDown}
      onCellDoubleClick={() => onCellDoubleClick(selectedRow.current)}
      rows={rows}
      columns={columns}
      components={{
        Toolbar: GridToolbar,
      }}
    />
  );
};

DialogRemoteCommonDatagrid.propTypes = {
  onSelectionModelChanged: PropTypes.func,
  onDelete: PropTypes.func,
  onCellDoubleClick: PropTypes.func,
  rows: PropTypes.array,
  columns: PropTypes.array,
};

DialogRemoteCommonDatagrid.defaultProps = {
  onSelectionModelChanged: () => {},
  onDelete: () => {},
  onCellDoubleClick: () => {},
  rows: [],
  columns: [],
};

export default DialogRemoteCommonDatagrid;
