import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Button, DataGrid, Grid, IconButton, icons, Tooltip, Typography, useSnackbar } from "syklone/ui/index.js";
import { useApiContext, usePermissionChecks } from "syklone/api/react/index.js";
import { WidgetInfoBox, WidgetPopoverMenu } from "syklone/components/widgets/index.js";
import { PermissionVisualElements } from "syklone/api/modules/permission_settings/data/index.js";

// imports: local
import WidgetScanningTemplateEditor from "./widget_scanning_template_editor.jsx";

const _DefinitionsList = ({ scanningTemplates, machineDefinitions, onEdit, onDelete, onDuplicate }) => {
  const { checkElementVisibility } = usePermissionChecks();
  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "templateName", headerName: "Template Name", flex: 1 },
    { field: "machineDefinitionName", headerName: "Machine Definition Name", flex: 1 },
    { field: "parametersNumber", headerName: "Number of parameters", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      width: 70,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div>
            <WidgetPopoverMenu data={actionMenu(params)} />
          </div>
        );
      },
    },
  ];

  const rows = scanningTemplates?.map((entry) => {
    const { templateName, scanningParameters, machineDefinitionId } = entry;
    const machine = machineDefinitions.find((e) => e._id.$oid === machineDefinitionId.$oid);

    return {
      id: entry._id.$oid,
      machineDefinitionName: machine?.definitionName,
      parametersNumber: scanningParameters.length,
      templateName,
    };
  });

  const actionMenu = (params) => {
    return {
      items: [
        {
          name: "Edit",
          action: () => onEdit(params.id),
          isHighlighted: false,
          disabled: !checkElementVisibility(PermissionVisualElements.editParameterTemplate),
        },
        {
          name: "Create duplicate",
          action: () => onDuplicate(params.id),
          isHighlighted: false,
          disabled: !checkElementVisibility(PermissionVisualElements.createParameterTemplate),
        },
        {
          name: "Delete",
          action: () => onDelete(params.id),
          isHighlighted: true,
          disabled: !checkElementVisibility(PermissionVisualElements.deleteParameterTemplate),
        },
      ],
    };
  };

  return (
    <Grid item>
      <DataGrid
        rows={rows.reverse()}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        sx={(theme) => ({
          fontSize: 13,
          "&.MuiDataGrid-root": {
            "& .MuiDataGrid-row.Mui-selected": {
              backgroundColor: theme.palette.mode === "dark" ? "#121212" : "#f8f8f8",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: theme.palette.mode === "dark" ? "1px solid #2F2F2F" : "1px solid #E8E8E8",
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
            },
            border: "none",
          },
          ".MuiDataGrid-columnHeaders": {
            borderBottom: theme.palette.mode === "dark" ? "1px solid #2F2F2F" : "1px solid #E8E8E8",
          },
          ".MuiDataGrid-footerContainer": {
            borderTop: "none",
          },
        })}
      />
    </Grid>
  );
};

_DefinitionsList.propTypes = {
  machineDefinitions: PropTypes.array,
  onDelete: PropTypes.func,
  onDuplicate: PropTypes.func,
  onEdit: PropTypes.func,
  scanningTemplates: PropTypes.array,
};

_DefinitionsList.defaultProps = {
  machineDefinitions: [],
  onDelete: () => {},
  onDuplicate: () => {},
  onEdit: () => {},
  scanningTemplates: [],
};

function WidgetScanningTemplate({ getMachineDefinitions, getScanningTemplates, reloadScanningTemplates }) {
  const api = useApiContext();
  const { checkElementVisibility } = usePermissionChecks();

  const [mode, setMode] = useState("list");
  const [machineDefinitions, setMachineDefinitions] = useState();
  const [scanningTemplates, setScanningTemplates] = useState();
  const [errors, setErrors] = useState([]);
  let initialValues = useRef();
  let selectedId = useRef();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (machineDefinitions?.length === 0) {
      enqueueSnackbar("No machine definitions found.", {
        variant: "warning",
      });
    }
  }, [machineDefinitions]);

  useEffect(() => {
    if (scanningTemplates?.length === 0) {
      enqueueSnackbar("No scanning templates found.", {
        variant: "warning",
      });
    }
  }, [scanningTemplates]);

  const fetchScanningTemplates = async () => {
    const { data, error } = await reloadScanningTemplates();
    if (error) setErrors({ ...errors, scanningTemplates: error });
    setScanningTemplates(data);
  };

  useEffect(() => {
    const machineDefinitionsSetup = async () => {
      const { data, error } = await getMachineDefinitions();
      if (error) setErrors((prevState) => ({ ...prevState, machineDefinitions: error }));

      setMachineDefinitions(data);
    };
    const scanningTemplatesSetup = async () => {
      const { data, error } = await getScanningTemplates();
      if (error) setErrors((prevState) => ({ ...prevState, scanningTemplates: error }));

      setScanningTemplates(data);
    };

    machineDefinitionsSetup();
    scanningTemplatesSetup();
  }, []);

  const handleCreateNewScanningTemplate = async (values) => {
    try {
      await api.sykloneApi.sk.svcBuildCreator.ApiScanningTemplates.postAll(values);
      fetchScanningTemplates();
      setMode("list");
      enqueueSnackbar("Successfully uploaded!", {
        variant: "success",
      });
    } catch (error) {
      console.log("error", error);
      enqueueSnackbar(`Unable to upload scanning templates due to ${error.toString()}`, {
        variant: "error",
      });
      setErrors([...errors, error]);
    }
  };

  const handleScanningTemplateOverride = async (id, values) => {
    try {
      await api.sykloneApi.sk.svcBuildCreator.ApiScanningTemplates.putAllById(id, values);
      enqueueSnackbar(`Scanning template of id:${id} successfully updated!`, {
        variant: "success",
      });
      await fetchScanningTemplates();
      setMode("list");
    } catch (error) {
      enqueueSnackbar(`Error updating scanning template due to ${error.name},  ${error.message}`, {
        variant: "error",
      });
    }
  };

  const handleDuplicate = (id) => {
    const selectedTemplate = scanningTemplates.find((template) => template._id.$oid === id);
    const { createdAt, _id, ...filteredValues } = selectedTemplate; // eslint-disable-line
    selectedId.current = undefined;
    initialValues.current = filteredValues;
    setMode("new");
  };

  const handleScanningTemplateDelete = async (id) => {
    try {
      const response = await api.sykloneApi.sk.svcBuildCreator.ApiScanningTemplates.deleteAllById(id);
      enqueueSnackbar(response.data.data.message, {
        variant: "success",
      });
      await fetchScanningTemplates();
    } catch (error) {
      enqueueSnackbar(`Unable to delete machine definition due to ${error.toString()}`, {
        variant: "error",
      });
      setErrors(error);
    }
  };

  const handleScanningTemplateEdit = (id) => {
    const selectedTemplate = scanningTemplates.find((template) => template._id.$oid === id);
    const { createdAt, _id, ...filteredValues } = selectedTemplate; // eslint-disable-line
    selectedId.current = _id.$oid;
    initialValues.current = filteredValues;
    setMode("edit");
  };

  const handleNewTemplate = () => {
    selectedId.current = undefined;
    initialValues.current = undefined;
    setMode("new");
  };

  return (
    <Grid container item>
      {
        {
          list: (
            <Grid container item>
              <Grid container item position="relative">
                <Grid item flexGrow={1}>
                  <Typography component="h1" variant="h4" align="center" color="inherit" noWrap mb={2}>
                    Scanning Templates
                  </Typography>
                </Grid>
                <Button
                  id="create-parameter-template"
                  disabled={!checkElementVisibility(PermissionVisualElements.createParameterTemplate)}
                  onClick={handleNewTemplate}
                  sx={{ position: "absolute", right: 0 }}
                  variant="contained"
                  color="success"
                >
                  New Template
                </Button>
              </Grid>
              <Grid container item>
                {Object.keys(errors)?.length > 0 ? (
                  <Grid container spacing={1}>
                    {Object.keys(errors).map((key) => (
                      <Grid item xs={12} key={key}>
                        <WidgetInfoBox variant="error">{errors[key]}</WidgetInfoBox>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  scanningTemplates?.length && (
                    <Grid item xs={12}>
                      <_DefinitionsList
                        machineDefinitions={machineDefinitions}
                        onDelete={handleScanningTemplateDelete}
                        onDuplicate={handleDuplicate}
                        onEdit={handleScanningTemplateEdit}
                        scanningTemplates={scanningTemplates}
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </Grid>
          ),
          new: (
            <>
              <Grid container item flexDirection="row">
                <Grid item flexGrow={1} flexShrink={1} alignSelf="start">
                  <Tooltip title="Back to the list." arrow placement="top">
                    <IconButton aria-label="delete" onClick={() => setMode("list")}>
                      <icons.mui.ArrowBack />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item flexGrow={1} flexShrink={1} alignSelf="center" marginBottom={1}>
                  <Typography component="h1" variant="h4" color="inherit">
                    New Scanning Template
                  </Typography>
                </Grid>
              </Grid>
              <WidgetScanningTemplateEditor
                data={initialValues.current}
                machines={machineDefinitions}
                handleSubmit={handleCreateNewScanningTemplate}
              />
            </>
          ),
          edit: (
            <>
              <Grid container item flexDirection="row" position="relative">
                <Tooltip sx={{ position: "absolute" }} title="Back to the list." arrow placement="top">
                  <IconButton aria-label="delete" onClick={() => setMode("list")}>
                    <icons.mui.ArrowBack />
                  </IconButton>
                </Tooltip>
                <Grid container item flexDirection="column" alignItems="center">
                  <Grid item flexGrow={1} flexShrink={1}>
                    <Typography component="h1" variant="h4" color="inherit">
                      Edit Scanning Template
                    </Typography>
                  </Grid>
                  <Grid item>id: {selectedId.current}</Grid>
                </Grid>
              </Grid>
              <WidgetScanningTemplateEditor
                data={initialValues.current}
                machines={machineDefinitions}
                handleSubmit={(values) => handleScanningTemplateOverride(selectedId.current, values)}
              />
            </>
          ),
        }[mode]
      }
    </Grid>
  );
}

WidgetScanningTemplate.propTypes = {
  getMachineDefinitions: PropTypes.func,
  getScanningTemplates: PropTypes.func,
  reloadScanningTemplates: PropTypes.func,
};

WidgetScanningTemplate.defaultProps = {
  getMachineDefinitions: () => {},
  getScanningTemplates: () => {},
  reloadScanningTemplates: () => {},
};

export default WidgetScanningTemplate;
