import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Box } from "syklone/ui/index.js";

const widgetRenderHeight = 650;

const DialogRemoteCommonWidgetRenderViewer = React.forwardRef(
  ({ handleCreateSnapshots, onSnapshotsCreated, containerWidth }, ref) => {
    const widgetRenderRef = React.useRef(null);
    const widgetRenderContainerRef = React.useRef(null);
    const widgetRenderParentRef = React.useRef(null);

    React.useImperativeHandle(
      ref,
      () => ({
        setWidgetRender,
        enable,
        disable,
        onGenerateSnapshots,
      }),
      [widgetRenderContainerRef]
    );

    const onSnapshotsGenerateCallback = (event) => {
      const snapshots = event.params.snapshots;
      onSnapshotsCreated(snapshots);
    };

    const setWidgetRender = (widgetRender) => {
      widgetRenderRef.current = widgetRender;
    };

    const enable = () => {
      const webGlContainer = widgetRenderRef.current.getWebglContainer();
      const eventFactory = widgetRenderRef.current.getEventFactory();
      eventFactory.addEventListener("EventSnapshotsCreated", onSnapshotsGenerateCallback);

      widgetRenderParentRef.current = webGlContainer.parentElement;
      widgetRenderContainerRef.current.appendChild(widgetRenderRef.current.getWebglContainer());
    };

    const disable = () => {
      const eventFactory = widgetRenderRef.current.getEventFactory();
      eventFactory.removeEventListener("EventSnapshotsCreated", onSnapshotsGenerateCallback);
      widgetRenderParentRef.current.appendChild(widgetRenderRef.current.getWebglContainer());
    };

    const onGenerateSnapshots = () => {
      let widgetNotifications = widgetRenderRef.current.getWidgetNotifications();
      widgetNotifications.resetNotications();
      try {
        handleCreateSnapshots(true);
      } catch (errors) {
        handleCreateSnapshots(false);
        for (let e of errors) {
          widgetNotifications.addNotification("ON_GENERATE_SNAPSHOTS", "error", e.message);
        }
      }
    };
    return (
      <Box
        id="dialogWebGlContainer"
        ref={widgetRenderContainerRef}
        sx={{ width: containerWidth, height: widgetRenderHeight }}
      />
    );
  }
);

DialogRemoteCommonWidgetRenderViewer.displayName = "DialogRemoteCommonWidgetRenderPreview";

DialogRemoteCommonWidgetRenderViewer.propTypes = {
  handleCreateSnapshots: PropTypes.func,
  onSnapshotsCreated: PropTypes.func,
  widgetRender: PropTypes.object,
  containerWidth: PropTypes.number,
};

export default DialogRemoteCommonWidgetRenderViewer;
