import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Box, Button, MobileStepper } from "syklone/ui/index.js";

// imports: local
import { DialogCommonBase } from "../dialog_common_base/index.js";

const withWizard = (DialogCommonBaseComponent) => {
  const DialogCommonWizard = ({
    maxSteps,
    activeStep,
    handleNext,
    handleBack,
    children,
    actions,
    nextStepTitle = "next",
    isNextDisabled,
    ...props
  }) => {
    const dataTag = props.dataTag;
    return (
      <DialogCommonBaseComponent
        dialogActionsSx={{ backgroundColor: "#343434" }}
        {...props}
        actions={
          <MobileStepper
            variant="text"
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            sx={{ width: "100%", backgroundColor: "inherit", paddingLeft: 0, paddingRight: 0 }}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={isNextDisabled}
                data-syklone={`${dataTag}-button-next`}
              >
                {nextStepTitle}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
                data-syklone={`${dataTag}-button-prev`}
              >
                Back
              </Button>
            }
          />
        }
      >
        {React.Children.map(children, (child, index) => {
          const { hideIfInactive } = child.props;
          if (hideIfInactive) {
            return <Box hidden={activeStep !== index}>{child}</Box>;
          }
          if (activeStep === index) {
            return child;
          } else {
            return null;
          }
        })}
      </DialogCommonBaseComponent>
    );
  };
  DialogCommonWizard.displayName = "DialogCommonWizard";
  DialogCommonWizard.propTypes = {
    ...DialogCommonBase.propTypes,
    maxSteps: PropTypes.number,
    activeStep: PropTypes.number,
    handleNext: PropTypes.func,
    handleBack: PropTypes.func,
    nextStepTitle: PropTypes.string,
  };
  return DialogCommonWizard;
};

const DialogCommonWizard = withWizard(DialogCommonBase);

export default DialogCommonWizard;
