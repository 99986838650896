import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import {
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  useFormik,
  Yup,
} from "syklone/ui/index.js";

// imports: local
import WidgetFormSolid from "./widget_form_solid.jsx";
import WidgetFormPorous from "./widget_form_porous.jsx";
import WidgetFormSupport from "./widget_form_support.jsx";

const materialTypes = ["solid", "porous", "support"];

const WidgetPostProcessingEditor = ({ data, machines, unitCells, scanningParameters, onSubmit, onUnitCellUpload }) => {
  const { machineDefinitionId, type, _id, ...typeSpecificData } = data; // eslint-disable-line

  const baseSchema = Yup.object().shape({
    machineDefinitionId: Yup.object()
      .shape({
        $oid: Yup.string()
          .oneOf(machines.map((machine) => machine._id.$oid))
          .required("Machine is required."),
      })
      .required("Machine is required."),
    type: Yup.string().oneOf(materialTypes).required("Type is required."),
  });

  const formik = useFormik({
    initialValues: { machineDefinitionId, type },
    validationSchema: baseSchema,
    onSubmit: () => {},
  });

  const { values, touched, errors, setValues, handleChange, setFieldValue, setFieldTouched, submitForm } = formik;

  const onChangeType = (e) => {
    const { machineDefinitionId, type } = values;
    setValues({ machineDefinitionId, type });
    setFieldTouched("machineDefinitionId", true);
    handleChange(e);
  };

  const handleSubmit = (data) => {
    const completeData = { ...data, ...values };
    if (Object.keys(errors).length) {
      return;
    }
    submitForm();
    onSubmit(completeData);
  };

  return (
    <Grid container item xs={12} mt={2}>
      <Grid container flexDirection="column" alignItems="center" spacing={2}>
        <Grid item container flexDirection="row" alignItems="center" spacing={2}>
          <Grid item>
            <FormControl
              variant="outlined"
              sx={{ minWidth: "220px!important" }}
              error={touched.machineDefinitionId && Boolean(errors.machineDefinitionId)}
            >
              <InputLabel id="select-machine" key="machineDefinitionId">
                Select machine
              </InputLabel>
              <Select
                name="machineDefinitionId"
                label="Select machine"
                onChange={(e) => {
                  setFieldTouched("machineDefinitionId", true);
                  setFieldValue("machineDefinitionId", { $oid: e.target.value });
                }}
                value={values.machineDefinitionId.$oid}
              >
                {machines.map((machine, index) => {
                  return (
                    <MenuItem key={index} value={machine._id.$oid}>
                      {machine.definitionName}
                    </MenuItem>
                  );
                })}
              </Select>
              {errors.machineDefinitionId && touched.machineDefinitionId && (
                <FormHelperText>{Object.values(errors.machineDefinitionId)}</FormHelperText>
              )}
            </FormControl>
          </Grid>
          <Grid item>
            <FormControl
              variant="outlined"
              sx={{ minWidth: "220px!important" }}
              error={touched.type && Boolean(errors.type)}
            >
              <InputLabel id="select-type" key="typeSelect">
                Select type
              </InputLabel>
              <Select name="type" label="Select type" onChange={onChangeType} value={values.type}>
                {materialTypes.map((type, index) => {
                  return (
                    <MenuItem key={index} value={type}>
                      {type}
                    </MenuItem>
                  );
                })}
              </Select>
              {errors.type && touched.type && <FormHelperText>{errors.type}</FormHelperText>}
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs={12} mt={2}>
        {
          {
            solid: (
              <Grid container item flexDirection="column" spacing={1} xs={12}>
                <Grid item>
                  <Divider />
                </Grid>
                <Grid item>
                  <WidgetFormSolid
                    scanningParameters={scanningParameters}
                    onSubmit={handleSubmit}
                    initialData={typeSpecificData}
                  />
                </Grid>
              </Grid>
            ),
            porous: (
              <Grid container item flexDirection="column" spacing={1} xs={12}>
                <Grid item>
                  <Divider />
                </Grid>
                <Grid item>
                  <WidgetFormPorous
                    scanningParameters={scanningParameters}
                    unitCells={unitCells}
                    onSubmit={handleSubmit}
                    onUnitCellUpload={onUnitCellUpload}
                    initialData={typeSpecificData}
                  />
                </Grid>
              </Grid>
            ),
            support: (
              <Grid container item flexDirection="column" spacing={1} xs={12}>
                <Grid item>
                  <Divider />
                </Grid>
                <Grid item>
                  <WidgetFormSupport
                    scanningParameters={scanningParameters}
                    onSubmit={handleSubmit}
                    initialData={typeSpecificData}
                  />
                </Grid>
              </Grid>
            ),
          }[values.type]
        }
      </Grid>
    </Grid>
  );
};

WidgetPostProcessingEditor.propTypes = {
  data: PropTypes.object,
  machines: PropTypes.array,
  onSubmit: PropTypes.func,
  onUnitCellUpload: PropTypes.func,
  scanningParameters: PropTypes.array,
  unitCells: PropTypes.array,
};

WidgetPostProcessingEditor.defaultProps = {
  data: { type: "", machineDefinitionId: { $oid: "" } },
  machines: [],
  onSubmit: () => {},
  onUnitCellUpload: () => {},
  scanningParameters: [],
  unitCells: [],
};

export default WidgetPostProcessingEditor;
