import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

// imports: syklone
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  icons,
  TextField,
  Tooltip,
  Typography,
} from "syklone/ui/index.js";

const WidgetHelperEditor = ({ values, onApply, onCancel }) => {
  const [data, setData] = useState({ description: values.description, image: values.image });
  const [selectedImage, setSelectedImage] = useState();

  const inputRef = useRef();

  const onButtonClick = () => {
    inputRef.current.click();
  };

  useEffect(() => {
    const { image } = data;

    if (!image) return;
    const reader = new FileReader();
    reader.readAsDataURL(image);

    reader.onloadend = function (e) {
      setSelectedImage(e.target.result);
    };
  }, [data]);

  const onImageInputChange = (event) => {
    const file = event.target.files[0];
    setData({ ...data, image: file });
  };

  const handleApply = () => {
    onApply(data);
  };

  return (
    <Card sx={{ width: 350, borderRadius: "1rem" }}>
      <Grid item container sx={{ position: "relative" }}>
        <Grid
          sx={{ width: "100%", height: "250px", backgroundColor: "#363636" }}
          item
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          {selectedImage ? (
            <CardMedia
              component="img"
              sx={{
                objectFit: "contain",
                display: "block",
                maxWidth: "100%",
                maxHeight: "100%",
                width: "auto",
                height: "auto",
                backgroundColor: "white",
                "&.MuiCardMedia-img": { objectFit: "contain" },
              }}
              image={selectedImage}
              title={data.image ? data.image?.name : "Name undefined."}
            />
          ) : (
            <span>Upload your image.</span>
          )}
        </Grid>

        <label htmlFor="contained-button-file" style={{ position: "absolute", bottom: 0, right: 0, margin: "0.5rem" }}>
          <Tooltip
            title="Upload image"
            arrow
            placement="top"
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: "offset",
                    options: {
                      offset: [0, -8],
                    },
                  },
                ],
              },
            }}
          >
            <IconButton
              aria-label="delete"
              onClick={onButtonClick}
              disabled={true} // TODO: make it enabled once Alex makes his move
              sx={(theme) => {
                return {
                  color: theme.palette.primary.main,
                  backgroundColor: theme.palette.primary.main + "11",
                  "&:hover": { backgroundColor: theme.palette.primary.main + "22" },
                };
              }}
            >
              <icons.mui.Upload fontSize="inherit" />
              <input
                ref={inputRef}
                accept="image/*"
                type="file"
                id="input-image-upload"
                multiple={false}
                onChange={onImageInputChange}
                style={{ display: "none" }}
              />
            </IconButton>
          </Tooltip>
        </label>
      </Grid>
      <CardContent>
        <Grid container item display="flex" justifyContent="flex-start" mb={1.25}>
          <Typography gutterBottom variant="h5" component="h2">
            {values?.title}
          </Typography>
        </Grid>

        <Grid container item display="flex" justifyContent="center">
          <TextField
            value={data?.description}
            onChange={(e) => setData({ ...data, description: e.target.value })}
            id="description"
            label="Description"
            sx={{ width: "100%" }}
            multiline
            maxRows={4}
          />
        </Grid>
      </CardContent>
      <CardActions style={{ display: "flex", justifyContent: "center", marginBottom: "0.5rem" }}>
        <Button onClick={onCancel} color="warning" variant="contained">
          Cancel
        </Button>
        <Button onClick={handleApply} color="success" variant="contained">
          Apply
        </Button>
      </CardActions>
    </Card>
  );
};

WidgetHelperEditor.propTypes = {
  values: PropTypes.object,
  editable: PropTypes.arrayOf(PropTypes.string),
  onApply: PropTypes.func,
  onCancel: PropTypes.func,
};

WidgetHelperEditor.defaultProps = {
  values: { image: undefined, title: "No title", description: "" },
  editable: [],
  onApply: () => {},
  onCancel: () => {},
};

export default WidgetHelperEditor;
