import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Card, CardContent, CardMedia, Grid, Typography } from "syklone/ui/index.js";

const WidgetHelperCard = ({ title, imageData, imageFit, children, width }) => {
  const [image, setImage] = useState();

  useEffect(() => {
    if (!imageData) return;
    const reader = new FileReader();
    reader.readAsDataURL(imageData);

    reader.onloadend = function (e) {
      setImage(e.target.result);
    };
  }, [imageData]);

  return (
    <Card sx={{ width: width }}>
      <Grid
        sx={{ width: "100%", height: "250px", backgroundColor: "#363636" }}
        item
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {image ? (
          <CardMedia
            component="img"
            sx={{
              objectFit: imageFit,
              imageRendering: "auto",
              width: "100%",
              height: "100%",
              backgroundColor: "white",
            }}
            image={image}
            title={image ? image?.name : "Name undefined."}
          />
        ) : (
          <span>No image available.</span>
        )}
      </Grid>
      <CardContent>
        <Grid container item display="flex" justifyContent="flex-start" mb={1.25}>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
        </Grid>

        <Grid container item display="flex" justifyContent="center">
          {children}
        </Grid>
      </CardContent>
    </Card>
  );
};

WidgetHelperCard.propTypes = {
  children: PropTypes.any,
  imageData: PropTypes.object,
  imageFit: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.number,
};

WidgetHelperCard.defaultProps = {
  children: <div style={{ padding: "0.5rem" }}>Your content</div>,
  imageData: undefined,
  imageFit: "contain",
  title: "No title",
  width: 350,
};

export default WidgetHelperCard;
