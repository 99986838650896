import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Box, icons, styled, Tooltip } from "syklone/ui/index.js";

const _CustomBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "45px",
  height: "45px",
  borderRadius: "50%",
  background: "#000",
  cursor: "pointer",
});

function WidgetConfigButton({ onClickConfigButton, isOpen }) {
  return (
    <Tooltip title="Configuration options" placement="right" arrow>
      <_CustomBox onClick={onClickConfigButton} data-syklone="widget-config-button">
        {isOpen ? <icons.mui.CloseOutlined /> : <icons.mui.SettingsOutlined />}
      </_CustomBox>
    </Tooltip>
  );
}

WidgetConfigButton.propTypes = {
  onClickConfigButton: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default WidgetConfigButton;
