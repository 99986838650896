import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { useApiContext } from "syklone/api/react/index.js";
import * as coreCommands from "syklone/graphics/tools/core_commands/index.js";

// imports: local
import { DialogWizardCommonCommit } from "../dialog_wizard_common_commit/index.js";

const DialogRemoteCommitParts = React.forwardRef(({ widgetRender, tool, username }, ref) => {
  const [rows, setRows] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isConfirmDisabled, setIsConfirmDisabled] = React.useState(false);
  const [loadingText, setIsLoadingText] = React.useState("");

  const api = useApiContext();
  const commandManager = api.commandManager;
  const servicePartSessions = api.sykloneApi.servicePartSessions;

  React.useImperativeHandle(ref, () => ({
    show: () => {
      setIsOpen(true);
      setIsConfirmDisabled(false);
    },
  }));

  const onComplete = (filename, snapshots) => {
    function loader(loading, text) {
      setIsLoading(loading);
      setIsLoadingText(text);
      if (loading) {
        setIsConfirmDisabled(true);
      } else {
        setIsOpen(false);
        setIsConfirmDisabled(false);
      }
    }

    setIsConfirmDisabled(true);
    const data = {
      filename: filename,
      user: username,
      snapshots: snapshots,
    };
    commandManager.executeAsync(new coreCommands.CommandCommitPartRemotely(api, tool, loader, data));
  };

  const onRefresh = async () => {
    try {
      let response = await servicePartSessions.getCommitted();
      let rows = response.data.result;
      setRows(rows);
    } catch (error) {
      alert(`${error}`);
    }
  };

  const onCreateSnapshots = () => {
    const cmd = new coreCommands.CommandCreateSnapshotsPart(tool);
    commandManager.execute(cmd);
  };

  const handleCloseDialog = () => {
    if (!isConfirmDisabled) {
      setIsOpen(false);
    }
  };

  return (
    <DialogWizardCommonCommit
      widgetRender={widgetRender}
      filenameExistsText="Committed part cannot be overwritten"
      title="Commit Parts"
      isOpen={isOpen}
      onClose={handleCloseDialog}
      rows={rows}
      fileNameEnabled={true}
      onComplete={onComplete}
      onRefresh={onRefresh}
      handleCreateSnapshots={onCreateSnapshots}
      isLoading={isLoading}
      loadingText={loadingText}
      isSubmitting={isConfirmDisabled}
      dataTag="commit-parts-dialog"
      stepTitle1="Adjust Snapshots"
      stepTitle2="Review snapshots"
      stepTitle3="Commit the part"
    />
  );
});

DialogRemoteCommitParts.displayName = "DialogRemoteCommitParts";

DialogRemoteCommitParts.propTypes = {
  tool: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  widgetRender: PropTypes.object,
  username: PropTypes.string,
};

export default DialogRemoteCommitParts;
