import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Box, icons, styled, Tooltip } from "syklone/ui/index.js";

const _CustomBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "45px",
  height: "45px",
  borderRadius: "50%",
  background: "#000",
  cursor: "pointer",
});

function WidgetAssistantButton({ onClick, isOpen }) {
  return (
    <Tooltip title="Assistant" placement="right" arrow>
      <_CustomBox onClick={onClick} data-syklone="widget-assistant-button">
        <icons.mui.HelpCenter />
      </_CustomBox>
    </Tooltip>
  );
}

WidgetAssistantButton.propTypes = {
  onClick: PropTypes.func,
  isOpen: PropTypes.bool,
};

export default WidgetAssistantButton;
