import React, { useImperativeHandle, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

// imports: syklone
import { DialogAbout as DialogAboutCommon } from "syklone/components/dialogs/index.js";
import { useApiContext } from "syklone/api/react/index.js";

// imports: local
import { about } from "../../../packages/core/index.js";

const DialogAbout = React.forwardRef((props, ref) => {
  const api = useApiContext();
  const [items, setItems] = useState([]);
  const [versions, setVersions] = useState({});
  const dialogAboutRef = useRef(null);

  const getServicesVersions = async () => {
    try {
      let versions = await api.sykloneApi.sk.metrics();
      setVersions(versions);
    } catch (error) {
      alert(`${error}`);
    }
  };

  useEffect(() => {
    getServicesVersions();
  }, []);

  useEffect(() => {
    setItems(about.items(api));
  }, [api]);

  function show() {
    const dialogAbout = dialogAboutRef.current;
    dialogAbout.setState({ items }, () => dialogAbout.show());
  }

  useImperativeHandle(ref, () => ({
    show: show,
  }));

  return <DialogAboutCommon open={props.open} items={items} ref={dialogAboutRef} versions={versions} />;
});

DialogAbout.displayName = "DialogAbout";

DialogAbout.propTypes = {
  classes: PropTypes.object,
  open: PropTypes.bool,
};

DialogAbout.defaultProps = {
  open: false,
};

export default DialogAbout;
