// syklintjs:disable-lint
import React from "react";

export const useDialogRemoteCommonFilenameValueWithValidation = (rows, filenameExistsText, canOverwrite = false) => {
  const [filenameValue, setFilenameValue] = React.useState("");
  const [filenameInputHelperText, setFilenameInputHelperText] = React.useState("");
  const [inputHelperTextType, setInpuHelperTextType] = React.useState("");
  const [isConfirmDisabled, setIsConfirmDisabled] = React.useState(false);

  React.useEffect(() => {
    setFilenameInputHelperText("");

    if (!filenameValue) {
      setInpuHelperTextType("");
      setIsConfirmDisabled(true);
    } else {
      if (filenameExistsText) {
        let found = filenameExists(filenameValue);
        if (!found) {
          setFilenameInputHelperText("");
          setInpuHelperTextType("");
          setIsConfirmDisabled(false);
        } else {
          setFilenameInputHelperText(filenameExistsText);
          setInpuHelperTextType(canOverwrite ? "warning" : "error");
          setIsConfirmDisabled(canOverwrite ? false : true);
        }
      } else {
        setIsConfirmDisabled(false);
      }
    }
  }, [filenameExistsText, filenameValue]);

  const filenameExists = React.useCallback(
    (filename) => {
      for (let row of rows) {
        if (row.name === filename) {
          return true;
        }
      }
      return false;
    },
    [rows]
  );

  return {
    setFilenameValue,
    filenameValue,
    filenameInputHelperText,
    isConfirmDisabled,
    inputHelperTextType,
  };
};
