import React, { Component } from "react";
import PropTypes from "prop-types";

// imports: syklone
import { utils } from "syklone/common/index.js";
import {
  Box,
  createTheme,
  CssBaseline,
  Divider,
  Grid,
  IconButton,
  icons,
  Paper,
  SnackbarProvider,
  styled,
  ThemeProvider,
  themes,
} from "syklone/ui/index.js";
import { DialogProfile, DialogSettings } from "syklone/components/dialogs/index.js";
import { WidgetHistoryCommands, WidgetTab } from "syklone/components/widgets/index.js";
import { createRestrictedToolPlatformCreator } from "syklone/graphics/tools/factories.js";
import {
  WidgetArray,
  WidgetDriftOptions,
  WidgetImportCoupons,
  WidgetLaserAndScanOrderAssignment,
  WidgetMachineDetails,
  WidgetMergerOptions,
  WidgetOptimize,
  WidgetPlatformCreatorAssistant,
  WidgetPlatformCreatorCommit,
  WidgetPlatformCreatorConfigMenu,
  WidgetPlatformCreatorImport,
  WidgetPlatformCreatorImportParts,
  WidgetPlatformCreatorMenu,
  WidgetPlatformCreatorReview,
  WidgetRender,
} from "syklone/graphics/components/index.js";
import { GasFlow } from "syklone/graphics/lib/index.js";
import * as coreCommands from "syklone/graphics/tools/core_commands/index.js";
import * as toolPlatformCreatorCommands from "syklone/graphics/tools/tools/tool_platform_creator/commands/index.js";

// imports: local
import {
  DialogAbout,
  DialogRemoteOpenPlatforms,
  DialogRemoteSavePlatforms,
  DialogRemoteImportParts,
  DialogRemoteCommitPlatforms,
} from "../../dialogs/index.js";
import * as img from "./img/index.js";
import {
  WidgetTopBar,
  WidgetPageTitle,
  WidgetMiniToolbar,
  WidgetConfigButton,
  WidgetAboutButton,
} from "../../widgets/index.js";

import { withApiContext } from "syklone/api/react/index.js";

import * as constants from "../constants.js";

class _PagePlatformCreator extends Component {
  static propTypes = {
    classes: PropTypes.object,
    auth: PropTypes.object,
    location: PropTypes.object,
    navigate: PropTypes.func,
    api: PropTypes.object,
  };

  // -------- Reactjs --------
  constructor(props) {
    super(props);

    this._username = props.auth?.user?.name || "";

    this.state = {
      theme: {},
      currentTabTitle: "create scene",
      panelTree: {},
      historyOpen: false,
      anchorEl: null,
      popperOpen: false,
      widgetImportPartsOpen: false,
      isTreeToolVisible: false,
      dataParts: [],
      buildFileId: null,
      buildFileStatus: null,
      importLoading: false,
      resetMenu: false,
      width: 0,
      height: 0,
    };

    this.api = props.api;

    this.tabItems = ["create scene", "platform config", "review", "commit"];

    this.refWidgetRender = React.createRef();
    this.refDialogAbout = React.createRef();
    this.refDialogSettings = React.createRef();
    this.refDialogOpenPlatformsRemote = React.createRef();
    this.refDialogSavePlatformsRemote = React.createRef();
    this.refDialogImportPartsRemote = React.createRef();
    this.refDialogCommitPlatformsRemote = React.createRef();
    this.refMenuAppBar = React.createRef();
    this.refWidgetSidebarResponsive = React.createRef();
    this.refWidgetTab = React.createRef();
    this.refWidgetMachineDetails = React.createRef();
    this.refWidgetHistoryCommands = React.createRef();
    this.refWidgetParts = React.createRef();
    this.refWidgetArray = React.createRef();
    this.refWidgetOptimize = React.createRef();
    this.refWidgetPartCreatorImportCoupons = React.createRef();
    this.refWidgetLaserAndScanOrderAssignment = React.createRef();
    this.refWidgetReviewPlatforms = React.createRef();
    this.refWidgetCommitPlatforms = React.createRef();
    this.refWidgetAssistant = React.createRef();
    this.refParentDiv = React.createRef();
    this.refDialogProfile = React.createRef();
  }

  componentDidMount = () => {
    const api = this.api;
    const profileManager = api.profileManager;
    const sessionSettings = api.sessionSettings;
    const commandManager = api.commandManager;
    let widgetRender = this._getWidgetRender();

    profileManager.username = this.props.auth?.user?.name || "";
    let profile = profileManager.getCurrentProfile();

    // prettier-ignore
    this.tool = createRestrictedToolPlatformCreator(api, widgetRender, profile);
    this.setState({ panelTree: this.tool.getPanelTree() });

    // gasflow
    const scene = widgetRender.getScene();
    const { width, depth, height, origin } = scene.getPlatform();
    const gasFlow = new GasFlow({ size: { width, depth, height }, origin });
    scene.add(gasFlow);

    // WidgetPartCreatorImportCoupons
    const widgetImportCoupons = this.refWidgetPartCreatorImportCoupons.current;
    widgetImportCoupons.setStrategyOptions(this.tool.getModel().getValues().strategyOptions);
    this._handleToolPlatformCreatorModelUpdate();

    // WidgetOptimize
    const widgetOptimize = this.refWidgetOptimize.current;
    const toolStatistics = this.tool.getChildByName("ToolStatistics");
    widgetOptimize.setOptimizeStrategyOptions(toolStatistics.getModel().getValues().strategyOptions);
    this._handleToolStatisticsModelUpdate();

    // Add event listeners
    let eventFactory = widgetRender.getEventFactory();
    let eventTypes = eventFactory.getEventTypes();

    for (let v of eventTypes) {
      eventFactory.addEventListener(v, (...args) => console.log(v, args));
    }

    this.tool.getModel().subscribe(this._handleToolPlatformCreatorModelUpdate);
    toolStatistics.getModel().subscribe(this._handleToolStatisticsModelUpdate);

    commandManager.addEventListener("EventCommandManagerChanged", this.handleCommandExecuted);

    eventFactory.addEventListener("EventSceneGraphChanged", (event) => {
      this.handleSceneChanged();
      this.setState({ resetMenu: false });
    });
    eventFactory.addEventListener("EventPartsChanged", (event) => {
      this.handleSceneChanged();
      this.setState({ resetMenu: false });
    });
    eventFactory.addEventListener("EventObjectTransformationChanged", (event) => {
      this.handleSceneChanged();
      this.setState({ resetMenu: false });
    });
    eventFactory.addEventListener("EventObjectTransformationFinished", (event) => {
      this.handleSceneChanged();
      this.setState({ resetMenu: false });
    });
    eventFactory.addEventListener("EventObjectMaterialsChanged", (event) => {
      this.handleSceneChanged();
      this.setState({ resetMenu: false });
    });
    eventFactory.addEventListener("EventObjectSelectionChanged", (event) => {
      this.handleSceneChanged();
      this.setState({ resetMenu: true });
    });
    eventFactory.addEventListener("EventObjectVisibilityChanged", (event) => {
      this.handleSceneChanged();
      this.setState({ resetMenu: false });
    });
    eventFactory.addEventListener("EventPanelActionClicked", (event) => {
      this.handlePanelActionClicked(event.params.actionName);
      this.setState({ resetMenu: false });
    });
    eventFactory.addEventListener("EventWidgetPresetChanged", (event) => {
      this.handleSceneChanged();
      this.setState({ resetMenu: false });
    });
    eventFactory.addEventListener("EventScenePresetChanged", (event) => {
      this.handleSceneChanged();
      this.setState({ resetMenu: false });
    });
    eventFactory.addEventListener("EventGridPresetChanged", (event) => {
      this.handleSceneChanged();
      this.setState({ resetMenu: false });
    });
    eventFactory.addEventListener("EventPlatformPresetChanged", (event) => {
      this.handleSceneChanged();
      this.setState({ resetMenu: false });
    });
    eventFactory.addEventListener("EventSnapshotsCreated", (event) => {
      // TODO: Fill out when adding the platform-creator snapshots
    });

    this._updateTabStatus();
    this.handleTheme(sessionSettings.data().isThemeLight);

    window.addEventListener("beforeunload", this._beforeUnloadHandler);

    const updateSize = () => {
      if (this.refParentDiv.current) {
        this.setState({
          width: this.refParentDiv.current.offsetWidth,
          height: this.refParentDiv.current.offsetHeight,
        });
      }
    };

    updateSize();

    this.resizeObserver = new ResizeObserver(updateSize);
    if (this.refParentDiv.current) {
      this.resizeObserver.observe(this.refParentDiv.current);
    }
  };

  componentWillUnmount = () => {
    const toolStatistics = this.tool.getChildByName("ToolStatistics");
    this.tool.getModel().unsubscribe(this._handleToolPlatformCreatorModelUpdate);
    toolStatistics.getModel().unsubscribe(this._handleToolStatisticsModelUpdate);
    window.removeEventListener("beforeunload", this._beforeUnloadHandler);

    if (this.refParentDiv.current) {
      this.resizeObserver.unobserve(this.refParentDiv.current);
    }
  };

  componentDidUpdate = (_, prevState) => {
    const api = this.api;
    const commandManager = api.commandManager;
    const widgetRender = this._getWidgetRender();

    if (prevState.currentTabTitle === "platform config" && this.state.currentTabTitle !== "platform config") {
      commandManager.execute(new coreCommands.CommandTurnGasflow(widgetRender, false));
    }

    if (prevState.currentTabTitle !== "platform config" && this.state.currentTabTitle === "platform config") {
      commandManager.execute(new coreCommands.CommandTurnGasflow(widgetRender, true));
    }
  };

  // -------- Private --------
  _getWidgetRender = () => {
    return this.refWidgetRender.current;
  };

  _getDialogAbout = () => {
    return this.refDialogAbout.current;
  };

  _getDialogSettings = () => {
    return this.refDialogSettings.current;
  };

  _getDialogProfile = () => {
    return this.refDialogProfile.current;
  };

  _getDialogOpenPlatformsRemote = () => {
    return this.refDialogOpenPlatformsRemote.current;
  };

  _getDialogSavePlatformsRemote = () => {
    return this.refDialogSavePlatformsRemote.current;
  };

  _getDialogImportPartsRemote = () => {
    return this.refDialogImportPartsRemote.current;
  };

  _getDialogCommitPlatformsRemote = () => {
    return this.refDialogCommitPlatformsRemote.current;
  };

  _getMenuAppBar = () => {
    return this.refMenuAppBar.current;
  };

  _getWidgetSidebarResponsive = () => {
    return this.refWidgetSidebarResponsive.current;
  };

  _getWidgetPartCreatorImportCoupons = () => {
    return this.refWidgetPartCreatorImportCoupons.current;
  };

  _getWidgetTab = () => {
    return this.refWidgetTab.current;
  };

  _getWidgetMachineDetails = () => {
    return this.refWidgetMachineDetails.current;
  };

  _getWidgetHistoryCommands = () => {
    return this.refWidgetHistoryCommands.current;
  };

  _getWidgetParts = () => {
    return this.refWidgetParts.current;
  };

  _getWidgetArray = () => {
    return this.refWidgetArray.current;
  };

  _getWidgetOptimize = () => {
    return this.refWidgetOptimize.current;
  };

  _getWidgetLaserAndScanOrderAssignment = () => {
    return this.refWidgetLaserAndScanOrderAssignment.current;
  };

  _getWidgetReviewPlatforms = () => {
    return this.refWidgetReviewPlatforms.current;
  };

  _getWidgetCommitPlatforms = () => {
    return this.refWidgetCommitPlatforms.current;
  };

  _getWidgetAssistant = () => {
    return this.refWidgetAssistant.current;
  };

  _handleToolPlatformCreatorModelUpdate = () => {
    const toolPlatformCreator = this.tool;
    const widgetLaserAndScanOrderAssignment = this._getWidgetLaserAndScanOrderAssignment();
    const widgetMachineDetails = this._getWidgetMachineDetails();
    const widgetArray = this._getWidgetArray();
    const widgetPartCreatorImportCoupons = this._getWidgetPartCreatorImportCoupons();

    const modelValues = toolPlatformCreator.getModel().getValues();
    const widgetArrayValues = {
      width: modelValues.arrayWidth,
      depth: modelValues.arrayDepth,
      spaceWidth: modelValues.arraySpaceWidth,
      spaceDepth: modelValues.arraySpaceDepth,
    };

    widgetPartCreatorImportCoupons?.setStrategy(toolPlatformCreator.getModel().getValues().strategy);
    widgetLaserAndScanOrderAssignment?.update();
    widgetMachineDetails?.update();
    widgetArray?.update(widgetArrayValues);
  };

  _handleToolStatisticsModelUpdate = () => {
    const toolStatistics = this.tool.getChildByName("ToolStatistics");
    const { strategyOptions, ...modelValues } = toolStatistics.getModel().getValues(); // eslint-disable-line
    this.refWidgetOptimize.current.setOptimizeData(modelValues);
  };

  _updateTabStatus = () => {
    const api = this.api;
    let widgetRender = this._getWidgetRender();
    let widgetTab = this._getWidgetTab();
    let done;

    function _update(status, tabName) {
      if (status === "doing") {
        widgetTab.setAsDoing(tabName);
      } else if (status === "done") {
        widgetTab.setAsDone(tabName);
      }
    }

    // prerequisites
    done = api.validationManager
      .getPluginsByTag("Prerequisites")
      .every((p) => p.setWidgetRender(widgetRender).run().getStatus());
    if (!done) {
      widgetTab.setAsDoing("scene");
      return;
    }

    let scene = widgetRender.getScene();

    // scene
    done = api.validationManager.getPluginsByTag("TabScene").every((p) => p.setScene(scene).run().getStatus());

    // stats: disabled for now because when transforming parts manually
    //        WidgetRender not invalidated on realtime. Thinking a proper way
    //        to invalidate efficiently (polling, commands, ...)
    // let reporter = new SelectableReporter(widgetRender);
    // let platform = scene.getPlatform();
    // let stats = reporter.analizeBoundingBox(platform.getBoundingBox(), false);
    // done =
    //   done &&
    //   ValidationManager.getPluginsByTag("stats").every((p) =>
    //     p.setPanelStats(stats).run().getStatus()
    //   );

    if (done) {
      _update("done", "scene");
    } else {
      _update("doing", "scene");
      return;
    }

    // commit
    _update("done", "commit");
  };

  _getTheme = (isThemeLight) => {
    return isThemeLight ? themes.themeLight : themes.themeDark;
  };

  _getUsername = () => {
    return this.props.auth?.user?.name || "";
  };

  _isVisible = () => {
    return this.state.currentTabTitle === "create scene" || this.state.currentTabTitle === "platform config"
      ? true
      : false;
  };

  _updateBuildFileId = (newState) => {
    this.setState({ buildFileId: newState });
  };

  _updatebuildFileStatus = (newState) => {
    this.setState({ buildFileStatus: newState });
  };

  _beforeUnloadHandler = (event) => {
    event.preventDefault();

    // Legacy support, e.g. Chrome/Edge < 119
    event.returnValue = true;
  };

  _getReleasedById = async (bfmId) => {
    const api = this.api;
    const serviceBfm = api.sykloneApi.serviceBfm;
    let response = await serviceBfm.getReleasedByBuildFileId(bfmId);

    return {
      id: response.id,
      name: response.name,
      createdBy: response.lifecycle[0].user,
      createdAt: response.createdAt,
      updatedAt: response.updatedAt,
      platformNum: response.platforms?.length ?? null,
      partSessionId: response.partSession?.id,
      status: response.status,
    };
  };

  // -------- Handlers --------
  handleCommandExecuted = (event) => {
    const api = this.api;
    const commandManager = api.commandManager;

    let widgetHistoryCommands = this._getWidgetHistoryCommands();
    if (widgetHistoryCommands) {
      widgetHistoryCommands.setCommandManager(commandManager);
      if (utils.optionalChaining(() => event.params.command.getClassName()) === "CommandNewProject") {
        // TODO: Fill out when adding the platform-creator snapshots
      }
    }
  };

  handleSideBar = () => {
    let widgetSideBar = this._getWidgetSidebarResponsive();
    widgetSideBar.show(true);
  };

  handleHome = () => {
    try {
      this.props.navigate("/");
    } catch (e) {
      let widgetRender = this._getWidgetRender();
      let notifier = widgetRender.getNotifier();
      notifier.addNotificationError(`${e}`);
    }
  };

  handleLogout = () => {
    this.props.auth.logout();
  };

  handleSettings = () => {
    let dialogSettings = this._getDialogSettings();
    dialogSettings.show();
  };

  handleProfile = () => {
    let dialogSettings = this._getDialogProfile();
    dialogSettings.setDialogOpen(true);
  };

  handleAbout = () => {
    let dialogAbout = this._getDialogAbout();
    dialogAbout.show();
  };

  handleTheme = (isThemeLight) => {
    const api = this.api;
    const sessionSettings = api.sessionSettings;

    let data = sessionSettings.data();
    data.isThemeLight = isThemeLight;
    sessionSettings.save(data);
    this.setState({
      theme: this._getTheme(isThemeLight),
    });
  };

  handleChangeTabStatus = (tabStatus) => {
    const widgetAssistant = this._getWidgetAssistant();
    widgetAssistant.setMenuStates(tabStatus);
  };

  handleCommitPlatformsRemote = async (tool) => {
    let dialogCommitPlatformsRemote = this._getDialogCommitPlatformsRemote();
    await dialogCommitPlatformsRemote.show(this.tool);
  };

  handlePanelActionClicked = async (action) => {
    const api = this.api;
    const commandManager = api.commandManager;

    if (action === "openRemote") {
      let dialogOpenPlatformsRemote = this._getDialogOpenPlatformsRemote();
      await dialogOpenPlatformsRemote.show(this.tool);
    } else if (action === "saveRemote") {
      let dialogSavePlatformsRemote = this._getDialogSavePlatformsRemote();
      await dialogSavePlatformsRemote.show(this.tool);
    } else if (action === "importRemote") {
      let dialogImportPartsRemote = this._getDialogImportPartsRemote();
      await dialogImportPartsRemote.show(this.tool);
    } else if (action === "undo") {
      if (commandManager.canUndo()) {
        commandManager.undo();
      }
    } else if (action === "redo") {
      if (commandManager.canRedo()) {
        commandManager.redo();
      }
    }
  };

  handleChangeTab = (tabId) => {
    let widgetTab = this._getWidgetTab();
    this.setState(
      {
        currentTabTitle: widgetTab.getCurrentTabTitle(),
        historyOpen: false,
      },
      () => {
        let tabTitle = widgetTab.getCurrentTabTitle();
        if (tabTitle === "commit") {
          let widgetCommitPlatforms = this._getWidgetCommitPlatforms();
          let widgetRender = this._getWidgetRender();
          widgetCommitPlatforms.validate(widgetRender);
        }
      }
    );
  };

  handleImportPart = async () => {
    let dialogImportPartsRemote = this._getDialogImportPartsRemote();
    await dialogImportPartsRemote.show(this.tool);
  };

  handleRemoteOpenPlatform = async () => {
    let dialogOpenPlatformsRemote = this._getDialogOpenPlatformsRemote();
    await dialogOpenPlatformsRemote.show(this.tool);
  };

  handleImportPartFromBuildFiles = async () => {
    const api = this.api;
    const serviceBfm = api.sykloneApi.serviceBfm;
    const widgetRender = this._getWidgetRender();
    const notifier = widgetRender.getNotifier();

    try {
      this.setState({
        importLoading: true,
      });

      let resRD, resUR, resProducts, updateDataPartsProducts;

      try {
        resRD = await serviceBfm.getResearchAndDevelopment();
        resUR = await serviceBfm.getUnderReview();
        resProducts = await api.sykloneApi.bcGetAllProducts();

        updateDataPartsProducts = async () => {
          return Promise.all(
            resProducts.data?.data.map(async (item) => {
              const subItems = await Promise.all(item.build_file_id.map((bfmId) => this._getReleasedById(bfmId)));
              return {
                id: item._id,
                name: item.product_id,
                tab: 2,
                parent: null,
                subItems: subItems,
              };
            })
          );
        };
      } catch (error) {
        this.setState({
          importLoading: false,
        });
        notifier.addNotificationError(`${error.toString()}`);
        console.log(error);
      }

      const productData = await updateDataPartsProducts();

      const updatedDataPartsRD = resRD.data?.data.map((item) => ({
        id: item.id,
        name: item.name,
        createdBy: item.lifecycle[0].user,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        platformNum: item.platforms?.length ?? null,
        partSessionId: item.partSession?.id,
        tab: 0,
        parent: null,
        subItems: [],
        status: item.status,
      }));

      const updatedDataPartsUR = resUR.data?.data.map((item) => ({
        id: item.id,
        name: item.name,
        createdBy: item.lifecycle[0].user,
        createdAt: item.createdAt,
        updatedAt: item.updatedAt,
        platformNum: item.platforms?.length ?? null,
        partSessionId: item.partSession?.id,
        tab: 1,
        parent: null,
        subItems: [],
        status: item.status,
      }));

      this.setState({
        importLoading: false,
        dataParts: [...updatedDataPartsRD, ...updatedDataPartsUR, ...productData],
        widgetImportPartsOpen: true,
      });
    } catch (error) {
      this.setState({
        importLoading: false,
      });
      let errorMessage = error?.detail?.toString() || "Error loading build files or products.";
      notifier.addNotificationError(`${errorMessage}`);
      console.log("Error loading build files or products.");
    }
  };

  handleClickImportPart = async (filename, id, buildFileId) => {
    const api = this.api;
    const commandManager = api.commandManager;
    let widgetRender = this._getWidgetRender();

    function loader(loading, text) {
      widgetRender.setLoading(loading, text);
    }

    const data = {
      filename: filename,
      id: id,
      buildFileId: buildFileId,
    };

    await commandManager.executeAsync(new coreCommands.CommandImportPartRemotely(api, this.tool, loader, data));
  };

  handleCloseImportPart = () => {
    this.setState({
      widgetImportPartsOpen: false,
    });
  };

  handleChangeLaserScanOrder = (e) => {};

  handleVectorScanOverrideChange = (array) => {
    const toolPlatformCreator = this.tool;
    const machine = toolPlatformCreator.getMachine();
    const mergerOptions = machine.getOptions().getMergerOptions();
    const api = this.api;
    const commandManager = api.commandManager;

    const data = { ...mergerOptions.getData(), vectorOrderOverride: array };

    commandManager.execute(new coreCommands.CommandSetMergerOptions(toolPlatformCreator, data));
  };

  handleCloseDialogSlicerOptions = () => null;

  handleOpenDialogSlicerOptions = () => null;

  handleResetVectorScanOrder = (data) => {
    const api = this.api;
    const commandManager = api.commandManager;
    const toolPlatformCreator = this.tool;
    const machine = toolPlatformCreator.getMachine();
    const mergerOptions = machine.getOptions().getMergerOptions();

    const output = { ...mergerOptions.getData(), vectorOrderOverride: data };

    commandManager.execute(new coreCommands.CommandSetMergerOptions(toolPlatformCreator, output));
  };

  handleWidgetPartCreatorImportCouponsImportClick = () => {
    const toolPlatformCreator = this.tool;
    toolPlatformCreator.importCoupons();
  };

  handleWidgetPartCreatorImportCouponsStrategyChange = (strategy) => {
    const toolPlatformCreator = this.tool;
    toolPlatformCreator.getModel().apply({ strategy: strategy });
  };

  getVectorScanOverrideData = () => {
    const toolPlatformCreator = this.tool;
    const machine = toolPlatformCreator.getMachine();
    const mergerOptions = machine.getOptions().getMergerOptions();

    return {
      current: mergerOptions.getData().vectorOrderOverride,
      default: mergerOptions.getDefaultData().vectorOrderOverride,
    };
  };

  handleChangeLaserAndScanOrder = (array) => {
    const api = this.api;
    const commandManager = api.commandManager;
    const toolPlatformCreator = this.tool;

    commandManager.execute(new coreCommands.CommandSetLaserAndScanOrder(toolPlatformCreator, array));
  };

  handleSelectPart = (array) => {
    const widgetRender = this._getWidgetRender();
    const api = this.api;
    const commandManager = api.commandManager;
    const scene = widgetRender.getScene();
    const parts = scene.getParts();
    const selectedParts = Array.from(scene.getSelectedParts());

    const toggleSelectionList = [];

    array.forEach((object) => {
      const found = selectedParts.find((part) => part.name === object.name);
      if (found && !object.selection) {
        toggleSelectionList.push(found);
      }
      if (!found && object.selection) {
        toggleSelectionList.push(parts.find((part) => part.name === object.name));
      }
    });

    commandManager.execute(new coreCommands.CommandToggleObjectsSelection(widgetRender, toggleSelectionList));
  };

  handlePlainView = () => {
    const widgetRender = this._getWidgetRender();
    const api = this.api;
    const commandManager = api.commandManager;

    commandManager.execute(new coreCommands.CommandPlainView(widgetRender));
  };

  handleDeselectAllParts = () => {
    const widgetRender = this._getWidgetRender();
    const api = this.api;
    const commandManager = api.commandManager;

    commandManager.execute(new coreCommands.CommandDeselectAll(widgetRender));
  };

  handleToggleGasFlowVisibility = () => {
    const widgetRender = this._getWidgetRender();
    const api = this.api;
    const commandManager = api.commandManager;

    commandManager.execute(new coreCommands.CommandToggleGasflowVisibility(widgetRender));
  };

  handleFetchMachineDefinitions = async () => {
    const widgetRender = this._getWidgetRender();
    const notifier = widgetRender.getNotifier();
    const api = this.api;

    try {
      const response = await api.sykloneApi.sk.svcBuildCreator.ApiMachineDefinition.getAll();
      const machineDefinitions = response.data.data["machine-definitions"];
      notifier.addNotificationSuccess("Retrieved machine definitions");
      return machineDefinitions;
    } catch (error) {
      notifier.addNotificationError(`Unable to get machine definitions due to ${error.detail}`);
    }
  };

  handleMachineChange = (machineSpecs) => {
    if (!machineSpecs) {
      console.error(`onMachineChanged: machine is ${machineSpecs}`);
      return;
    }

    const toolPlatformCreator = this.tool;
    toolPlatformCreator.updateMachineSpecification(machineSpecs);
  };

  handleSetDefaultMachine = (machineSpecs) => {
    if (!machineSpecs) {
      console.error(`onSetDefaultMachine: machine is ${machineSpecs}`);
      return;
    }

    const toolPlatformCreator = this.tool;
    toolPlatformCreator.setDefaultMachineSpecification(machineSpecs);
  };

  getIsDrifted = () => {
    const tool = this.tool;
    const machine = tool.getMachine();
    const driftOptions = machine.getOptions().getDriftOptions();

    return driftOptions.getData().isDrifted;
  };

  getTemplateId = () => {
    const tool = this.tool;
    const machine = tool.getMachine();
    const driftOptions = machine.getOptions().getDriftOptions();

    return driftOptions.getData().templateId;
  };

  handleIsDriftedChange = (state) => {
    const tool = this.tool;
    const api = tool.getApi();
    const machine = tool.getMachine();
    const driftOptions = machine.getOptions().getDriftOptions();

    const data = { ...driftOptions.getData(), isDrifted: state };

    api.commandManager.execute(new coreCommands.CommandSetDriftOptions(tool, data));
  };

  handleTemplateIdChange = (templateId) => {
    const tool = this.tool;
    const api = tool.getApi();
    const machine = tool.getMachine();
    const driftOptions = machine.getOptions().getDriftOptions();

    const data = { ...driftOptions.getData(), templateId };

    api.commandManager.execute(new coreCommands.CommandSetDriftOptions(tool, data));
  };

  getCurrentMachineState = () => {
    const toolPlatformCreator = this.tool;
    const machine = toolPlatformCreator.getMachine();
    return machine.getSpecification();
  };

  getMachineParameters = () => {
    const toolPlatformCreator = this.tool;
    const machineSpecs = toolPlatformCreator.getMachine().getSpecification();
    return {
      size: { x: machineSpecs.chamberDimensions.x, y: machineSpecs.chamberDimensions.y },
      numberOfLasers: machineSpecs.technology.options?.numberOfLasers || 0,
      origin: machineSpecs.origin,
    };
  };

  getGasFlowVisibility = () => {
    const widgetRender = this._getWidgetRender();
    const scene = widgetRender.getScene();
    const gasFlow = scene.children.find((child) => child.name === "GasFlow");

    return gasFlow.visible;
  };

  getLaserAndScanOrder = () => {
    const toolPlatformCreator = this.tool;
    const machine = toolPlatformCreator.getMachine();
    const laserAndScanOrder = machine.getOptions().getLaserAndScanOrder();

    return structuredClone(laserAndScanOrder.getData());
  };

  getPartsProperties = () => {
    const widgetRender = this._getWidgetRender();
    const scene = widgetRender.getScene();

    const lst = [];

    for (const part of scene.getParts()) {
      const bbox = part.getBoundingBox(part);

      const name = part.name;
      const position = {
        x: bbox.min.x + (bbox.max.x - bbox.min.x) / 2,
        y: bbox.min.y + (bbox.max.y - bbox.min.y) / 2,
      };
      const size = {
        x: bbox.max.x - bbox.min.x,
        y: bbox.max.y - bbox.min.y,
      };
      const selection = scene.getSelectedParts().has(part);

      lst.push({ name, position, size, selection });
    }

    return lst;
  };

  handleSceneChanged = () => {
    let widgetRender = this._getWidgetRender();
    let scene = widgetRender.getScene();
    let widgetReviewPlatforms = this._getWidgetReviewPlatforms();
    const widgetLaserAndScanOrderAssignment = this._getWidgetLaserAndScanOrderAssignment();

    // widgetReviewPlatforms
    widgetReviewPlatforms.setParts(scene.getParts());
    widgetReviewPlatforms.setLeafs(scene.getLeafs());

    // widgetLaserAndScanOrderAssignment
    widgetLaserAndScanOrderAssignment?.update();

    this._updateTabStatus();
  };

  handleSaveSettings = (settings) => {
    const api = this.api;
    const sessionSettings = api.sessionSettings;
    sessionSettings.save(settings);
  };

  handleResetSettings = () => {
    const api = this.api;
    const sessionSettings = api.sessionSettings;
    let dct = sessionSettings.defaults();
    sessionSettings.save(dct);
  };

  handleToggleSideBarDesktop = () => {
    this.setState(
      {
        isExpanded: !this.state.isExpanded,
      },
      () => {
        window.dispatchEvent(new Event("resize"));
      }
    );
  };

  handleHistoryClick = () => {
    this.setState((prevState) => ({
      historyOpen: !prevState.historyOpen,
      isTreeToolVisible: false,
    }));
  };

  handlePopperClick = (event) => {
    this.setState((prevState) => ({
      popperOpen: !prevState.popperOpen,
    }));
    this.setState({
      anchorEl: event.currentTarget,
    });
  };

  handleGenerateClick = (data) => {
    const api = this.api;
    let tool = this.tool;
    let widgetRender = tool.getWidget();
    const commandManager = api.commandManager;
    const toolObjectMode = tool.getChildByName("ToolObjectMode");

    const { width, depth, spaceWidth, spaceDepth } = data;

    toolObjectMode.switch();
    commandManager.execute(
      new toolPlatformCreatorCommands.MacroCommandGenArray(api, widgetRender, width, depth, spaceWidth, spaceDepth)
    );
  };

  handleArrayChange = (key, value) => {
    const dict = {
      depth: "arrayDepth",
      width: "arrayWidth",
      spaceWidth: "arraySpaceWidth",
      spaceDepth: "arraySpaceDepth",
    };
    const api = this.api;
    const commandManager = api.commandManager;
    const model = this.tool.getModel();
    commandManager.execute(new coreCommands.CommandSetModelValue(model, dict[key], value));
  };

  handleOptimizeClick = () => {
    let tool = this.tool;
    const toolStatistics = tool.getChildByName("ToolStatistics");

    toolStatistics.switch();
    toolStatistics.optimize();
  };

  handleCleanClick = () => {
    let tool = this.tool;
    const toolStatistics = tool.getChildByName("ToolStatistics");

    toolStatistics.switch();
    toolStatistics.clean();
  };

  handleOptimizeChange = (key, value) => {
    let tool = this.tool;
    const toolStatistics = tool.getChildByName("ToolStatistics");

    toolStatistics.setModelParameter(key, value);
  };

  // -------- Public: Component creation --------
  createAppHeader = () => {
    const api = this.api;
    const sessionSettings = api.sessionSettings;

    return (
      <_AppHeaderGrid data-syklone="platform_creator-header" isThemeLight={sessionSettings.data().isThemeLight}>
        <_AppHeaderLeftBox>
          <_SideBarButton ria-label="menu" onClick={this.handleSideBar}>
            <icons.mui.Menu fontSize="large" />
          </_SideBarButton>
          <_LogoBox img={img} alt="Platform Creator" />
        </_AppHeaderLeftBox>
        <_AppHeaderMidBox>
          <Grid item xs={12}>
            <WidgetTab
              onChangeTab={this.handleChangeTab}
              onChangeTabStatus={this.handleChangeTabStatus}
              ref={this.refWidgetTab}
              items={this.tabItems}
              showIcons={sessionSettings.data().showTabIcons}
            />
          </Grid>
        </_AppHeaderMidBox>
      </_AppHeaderGrid>
    );
  };

  createMiniToolBarGroups = () => {
    const api = this.api;
    const commandManager = api.commandManager;
    return [
      {
        links: [
          {
            name: "Menu",
            description: this.state.isTreeToolVisible ? "Close" : "Open advanced menu",
            icon: <icons.mui.Tune fontSize="small" />,
            action: () =>
              this.setState((prevState) => ({
                historyOpen: false,
                isTreeToolVisible: !prevState.isTreeToolVisible,
              })),
            isActive: this.state.isTreeToolVisible,
          },
        ],
      },
      {
        links: [
          {
            name: "New project",
            description: "Create new project",
            icon: <icons.mui.FiberNew fontSize="small" />,
            action: () => commandManager.execute(new coreCommands.CommandNewProject(api, this.tool)),
            isActive: null,
          },
        ],
      },
    ];
  };

  getParts = async () => {};

  // -------- Render --------
  reRender = () => {
    const api = this.api;
    const sessionSettings = api.sessionSettings;
    this.forceUpdate();
    this.handleTheme(sessionSettings.data().isThemeLight);
  };

  render() {
    const api = this.api;
    const sessionSettings = api.sessionSettings;
    const commandManager = api.commandManager;
    const isWidgetMachinesDetails = this.refWidgetRender.current !== null;
    let theme = createTheme(this.state.theme);
    const { width } = this.state;

    let technology;
    const toolPlatformCreator = this.tool;
    if (toolPlatformCreator) {
      const machineOptions = toolPlatformCreator.getMachine().getSpecification();
      technology = machineOptions.technology.name;
    }

    return (
      <ThemeProvider theme={theme}>
        <SnackbarProvider>
          <CssBaseline />
          <WidgetPageTitle title="Platform creator" />
          <>
            {this.tool ? (
              <WidgetPlatformCreatorConfigMenu
                tool={this.tool}
                popperOpen={this.state.popperOpen}
                popperAchhorEl={this.state.anchorEl}
                wholeState={this.state}
              />
            ) : null}
            <_RootBox>
              <Box>
                <DialogAbout ref={this.refDialogAbout} />
                <DialogSettings
                  data={sessionSettings.data()}
                  options={sessionSettings.options()}
                  onOk={this.handleSaveSettings}
                  onReset={this.handleResetSettings}
                  ref={this.refDialogSettings}
                  reRender={this.reRender}
                />
                <DialogRemoteOpenPlatforms ref={this.refDialogOpenPlatformsRemote} username={this._getUsername()} />
                <DialogRemoteSavePlatforms ref={this.refDialogSavePlatformsRemote} username={this._getUsername()} />
                <DialogRemoteImportParts ref={this.refDialogImportPartsRemote} />
                <DialogRemoteCommitPlatforms
                  buildFileId={this.state.buildFileId}
                  buildFileStatus={this.state.buildFileStatus}
                  ref={this.refDialogCommitPlatformsRemote}
                  username={this._getUsername()}
                  widgetRender={this._getWidgetRender()}
                  tool={this.tool}
                />
                <WidgetPlatformCreatorImportParts
                  open={this.state.widgetImportPartsOpen}
                  data={this.state.dataParts}
                  onClose={this.handleCloseImportPart}
                  onImport={this.handleClickImportPart}
                  updateBuildFileId={this._updateBuildFileId}
                  updateBuildFileStatus={this._updatebuildFileStatus}
                />
                <WidgetPlatformCreatorAssistant
                  ref={this.refWidgetAssistant}
                  tabItems={this.tabItems}
                  activeTabName={this.state.currentTabTitle}
                  panelTree={this.state.panelTree}
                  menuStates={{}}
                />
              </Box>
              <_TopBox>
                <_LogoBox>
                  <img src={img.LogoPlatformCreatorAmblem} />
                </_LogoBox>
                <DialogProfile ref={this.refDialogProfile} data={this.props.auth?.user} />
                <WidgetTopBar
                  isLightTheme={sessionSettings.data().isThemeLight}
                  username={this._getUsername()}
                  hasNotification={false}
                  onProfile={this.handleProfile}
                  onSettings={this.handleSettings}
                  onLogout={this.handleLogout}
                  handleAbout={this.handleAbout}
                  onTheme={this.handleTheme}
                  refWidgetThemeSwitcher={this.refWidgetThemeSwitcher}
                />
              </_TopBox>

              <_ContentBox>
                <_TabBox>
                  <WidgetTab
                    isVertical={true}
                    onChangeTab={this.handleChangeTab}
                    onChangeTabStatus={this.handleChangeTabStatus}
                    ref={this.refWidgetTab}
                    items={this.tabItems}
                    showIcons={sessionSettings.data().showTabIcons}
                  />
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                    <WidgetConfigButton onClickConfigButton={this.handlePopperClick} isOpen={this.state.popperOpen} />
                    <WidgetAboutButton onClickAboutButton={this.handleAbout} />
                  </Box>
                </_TabBox>

                <_ToolBox
                  sx={{
                    display:
                      this.state.currentTabTitle === "create scene" || this.state.currentTabTitle === "platform config"
                        ? "flex"
                        : "none",
                  }}
                >
                  <_RightPanelBox
                    data-syklone="widget-right_panel"
                    isThemeLight={sessionSettings.data().isThemeLight}
                    visible={this._isVisible()}
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{ padding: "10px 0 0 0" }}
                      hidden={this.state.currentTabTitle !== "create scene"}
                    >
                      <Grid container direction="column" spacing={2}>
                        <Grid item xs={12}>
                          <WidgetPlatformCreatorImport
                            onRemoteImportPart={this.handleImportPart}
                            onImportPartFromBuildFiles={this.handleImportPartFromBuildFiles}
                            onRemoteOpenPlatform={this.handleRemoteOpenPlatform}
                            loading={this.state.importLoading}
                          />
                        </Grid>
                      </Grid>
                    </Grid>

                    {isWidgetMachinesDetails ? (
                      <Grid
                        item
                        xs={12}
                        sx={{ padding: "0.5rem 0 0 0" }}
                        hidden={this.state.currentTabTitle !== "create scene"}
                      >
                        <WidgetMachineDetails
                          ref={this.refWidgetMachineDetails}
                          widgetRender={this.refWidgetRender}
                          headerVersion="2"
                          fetchMachines={this.handleFetchMachineDefinitions}
                          onMachineChange={this.handleMachineChange}
                          onSetDefaultMachine={this.handleSetDefaultMachine}
                          getMachine={this.getCurrentMachineState}
                        />
                      </Grid>
                    ) : null}

                    <Grid
                      item
                      xs={12}
                      hidden={this.state.currentTabTitle !== "platform config"}
                      sx={{ padding: "10px 0 0 0" }}
                    >
                      <Grid container direction="column" spacing={2}>
                        <Grid item xs={12}>
                          <WidgetMergerOptions
                            getters={{
                              getVectorScanOverrideData: this.getVectorScanOverrideData,
                            }}
                            handlers={{
                              onCloseDialog: this.handleCloseDialogSlicerOptions,
                              onOpenDialog: this.handleOpenDialogSlicerOptions,
                              onReset: this.handleResetVectorScanOrder,
                              onVectorScanOverrideChange: this.handleVectorScanOverrideChange,
                            }}
                            headerVersion="2"
                          />
                          <Divider sx={{ margin: "0.5rem 1rem" }} />
                          <WidgetDriftOptions
                            getters={{
                              getIsDrifted: this.getIsDrifted,
                              getTemplateId: this.getTemplateId,
                            }}
                            handlers={{
                              onCloseDialog: () => null,
                              onOpenDialog: () => null,
                              onIsDriftedChange: this.handleIsDriftedChange,
                              onTemplateIdChange: this.handleTemplateIdChange,
                            }}
                            headerVersion="2"
                          />
                          <Divider sx={{ margin: "0.5rem 1rem" }} />
                          <WidgetImportCoupons
                            defaultExpanded={false}
                            ref={this.refWidgetPartCreatorImportCoupons}
                            onStrategyChange={this.handleWidgetPartCreatorImportCouponsStrategyChange}
                            onImportClick={this.handleWidgetPartCreatorImportCouponsImportClick}
                          />
                          <Divider sx={{ margin: "0.5rem 1rem" }} />
                          <WidgetArray
                            ref={this.refWidgetArray}
                            onArrayChange={this.handleArrayChange}
                            arraySectionExpanded={false}
                            onGenerateClick={this.handleGenerateClick}
                          />
                          <Divider sx={{ margin: "0.5rem 1rem" }} />
                          <WidgetOptimize
                            ref={this.refWidgetOptimize}
                            optimizeSectionExpanded={false}
                            onOptimizeClick={this.handleOptimizeClick}
                            onOptimizeChange={this.handleOptimizeChange}
                            onCleanClick={this.handleCleanClick}
                          />
                          {this.state.currentTabTitle === "platform config" && technology === "laser" && (
                            <>
                              <Divider sx={{ margin: "0.5rem 1rem" }} />
                              <WidgetLaserAndScanOrderAssignment
                                ref={this.refWidgetLaserAndScanOrderAssignment}
                                getters={{
                                  getGasFlowVisibility: this.getGasFlowVisibility,
                                  getMachineParameters: this.getMachineParameters,
                                  getLaserAndScanOrder: this.getLaserAndScanOrder,
                                  getPartsProperties: this.getPartsProperties,
                                }}
                                handlers={{
                                  handleChangeLaserAndScanOrder: this.handleChangeLaserAndScanOrder,
                                  handleDeselectAllParts: this.handleDeselectAllParts,
                                  handlePlainView: this.handlePlainView,
                                  handleSelectPart: this.handleSelectPart,
                                  handleToggleGasFlowVisibility: this.handleToggleGasFlowVisibility,
                                }}
                                headerVersion="2"
                              />
                            </>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </_RightPanelBox>
                </_ToolBox>

                <_MenuBox
                  sx={{
                    display:
                      this.state.currentTabTitle === "create scene" || this.state.currentTabTitle === "platform config"
                        ? "flex"
                        : "none",
                  }}
                >
                  {this.tool ? (
                    <WidgetPlatformCreatorMenu
                      commandManager={commandManager}
                      tool={this.tool}
                      widgetRender={this._getWidgetRender()}
                      savePlatform={this.handlePanelActionClicked}
                      handleHistory={this.handleHistoryClick}
                      resetMenu={this.state.resetMenu}
                    />
                  ) : null}
                </_MenuBox>

                <_RenderBox
                  hasBackground={this.state.currentTabTitle === "review" || this.state.currentTabTitle === "commit"}
                  style={{
                    width:
                      this.state.currentTabTitle === "review" || this.state.currentTabTitle === "commit"
                        ? "100%"
                        : `calc(100% - (${constants.BOX_COLUMN_SIZE_MD} + 118px))`,
                  }}
                >
                  <_MainPartBox data-syklone="widget-main_part" visible={this._isVisible()} sx={{ width: "100%" }}>
                    <Grid
                      item
                      style={{ position: "relative" }}
                      xs={12}
                      hidden={
                        this.state.currentTabTitle !== "create scene" &&
                        this.state.currentTabTitle !== "platform config"
                      }
                      ref={this.refParentDiv}
                    >
                      <>
                        {width > 200 && (
                          <_WidgetMiniToolbarWrapper>
                            <WidgetMiniToolbar groups={this.createMiniToolBarGroups()} />
                          </_WidgetMiniToolbarWrapper>
                        )}

                        <_WidgetRenderWrapper
                          data-syklone="widget-render-wrapper"
                          visible={this.state.isTreeToolVisible}
                        >
                          <WidgetRender
                            ref={this.refWidgetRender}
                            isWidgetRenderHeightAuto={true}
                            cameraToggle={false}
                          />
                        </_WidgetRenderWrapper>

                        <_HistoryBox visible={this.state.historyOpen}>
                          <_CustomPaper>
                            <WidgetHistoryCommands
                              ref={this.refWidgetHistoryCommands}
                              commandManager={commandManager}
                              areaMaxHeight={245}
                            />
                          </_CustomPaper>
                        </_HistoryBox>
                      </>
                    </Grid>
                  </_MainPartBox>
                  <Grid container>
                    <Grid item xs={12} hidden={this.state.currentTabTitle !== "review"}>
                      <WidgetPlatformCreatorReview ref={this.refWidgetReviewPlatforms} />
                    </Grid>

                    <Grid item xs={12} hidden={this.state.currentTabTitle !== "commit"}>
                      <WidgetPlatformCreatorCommit
                        isActive={this.state.currentTabTitle === "commit"}
                        onCommitRemote={this.handleCommitPlatformsRemote}
                        ref={this.refWidgetCommitPlatforms}
                      />
                    </Grid>
                  </Grid>
                </_RenderBox>
              </_ContentBox>
            </_RootBox>
          </>
        </SnackbarProvider>
      </ThemeProvider>
    );
  }
}

const PagePlatformCreator = withApiContext(_PagePlatformCreator);

export default PagePlatformCreator;
// -------- Private: Styled components --------
const _RootBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  position: "relative",
  height: "100vh",
  "& *::-webkit-scrollbar": {
    width: "6px",
    height: "6px",
  },
  "& *::-webkit-scrollbar-thumb": {
    minHeight: "24px",
    borderRadius: "8px",
    backgroundColor: "#585859",
  },
  "& *::-webkit-scrollbar-corner": {
    backgroundColor: "#202022",
  },
});

const _TopBox = styled(Box)({
  display: "flex",
  height: "56px",
  alignItems: "center",
  borderBottom: "1px solid #3C3C3C",
});

const _LogoBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  borderRight: "1px solid #3C3C3C",
  width: "62px",
});

const _ContentBox = styled(Box)({
  display: "flex",
  height: "100%",
});

const _ToolBox = styled(Box)({
  backgroundColor: "#212121",
  minWidth: "530px",
  overflowX: "hidden",
  overflowY: "auto",
  height: "calc(100vh - 56px)",
});

const _TabBox = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
  paddingBottom: "8px",
});

const _MenuBox = styled(Box)({
  minWidth: "55px",
  overflowX: "hidden",
  overflowY: "auto",
  height: "calc(100vh - 56px)",
});

const _RenderBox = styled(Box, {
  shouldForwardProp: (props) => props !== "hasBackground",
})(({ hasBackground }) => ({
  width: "100%",
  padding: "18px 18px 0px 18px",
  backgroundColor: hasBackground ? "#212121" : "transparent",
}));

/* OLD UI */

const _MainPartBox = styled(Box, {
  shouldForwardProp: (props) => props !== "visible",
})(({ visible }) => ({
  display: visible ? "inline-block" : "none",
  position: "relative",
  "@media (max-width: 1280px)": {
    width: "100%!important",
    paddingRight: "0px",
  },
}));

const _RightPanelBox = styled(Box, {
  shouldForwardProp: (props) => props !== "visible" && props !== "isThemeLight",
})(({ isThemeLight, visible }) => ({
  display: visible ? "inline-block" : "none",
  width: `${constants.BOX_COLUMN_SIZE_MD}`,
  minWidth: `${constants.BOX_COLUMN_SIZE_MD}`,
  "@media (max-width: 1280px)": {
    width: "100%",
    borderTopRightRadius: "6px",
    borderBottomRightRadius: "6px",
  },
  verticalAlign: "top",
  //overflow: "hidden",
  height: "calc(100vh - 175px)",
  overflow: "auto",
}));

const _AppHeaderGrid = styled(Grid, {
  shouldForwardProp: (props) => props !== "isThemeLight",
})(({ isThemeLight }) => ({
  display: "flex",
  position: "relative",
  justifyContent: "space-between",
  alignItems: "center",
  marginTop: "5px",
  width: "100%",
  padding: "11px 30px",
  "@media (max-width: 960px)": {
    flexDirection: "column!important",
    padding: "0px 20px",
  },
  borderBottom: `1px solid ${isThemeLight ? "#E8E8E8" : "#2F2F2F"}`,
}));

const _AppHeaderLeftBox = styled(Box)({
  display: "flex",
  flex: "0 0 190px",
  "@media (max-width: 960px)": {
    flex: 1,
    width: "100%",
    justifyContent: "space-between",
    flexDirection: "row-reverse",
  },
});

const _AppHeaderMidBox = styled(Box)({
  position: "absolute",
  bottom: 0,
  left: "50%",
  transform: "translateX(-50%)",
  "@media (max-width: 960px)": {
    position: "relative",
    flexGrow: 1,
    left: "initial",
    transform: "initial",
    width: "100%",
  },
});

const _SideBarButton = styled(IconButton)({
  display: "none!important", // TODO: [components] why are we keeping it then?
  "@media (max-width: 1280px)": {
    display: "inline-flex!important",
    width: "48px",
    height: "48px",
  },
});

const _CustomPaper = styled(Paper)({
  "& .MuiGrid-container .MuiBox-root:before": {
    display: "none",
  },
  "& .MuiGrid-container h2": {
    fontSize: "16px",
    paddingLeft: "16px",
  },
});

const _HistoryBox = styled(Box, {
  shouldForwardProp: (props) => props !== "visible",
})(({ visible }) => ({
  display: visible ? "initial" : "none",
  position: "absolute",
  width: "360px",
  bottom: "-10px",
  left: "-14px",
}));

const _WidgetRenderWrapper = styled(Box, {
  shouldForwardProp: (props) => props !== "visible",
})(({ visible }) => ({
  height: "calc(100vh - 94px)",
  ".dg.main": {
    marginTop: "40px",
    padding: "6px",
    backgroundColor: "#222",
    borderRadius: "6px",
    display: visible ? "initial" : "none",
    maxHeight: "601px!important",
  },
  ".dg.main .close-button": {
    width: "auto!important",
  },
  "#widget-render-toolbar": {
    inset: "auto!important",
    top: "0px!important",
    left: "1rem!important",
    bottom: "0px!important",
  },
}));

const _WidgetMiniToolbarWrapper = styled(Box)({
  position: "absolute",
  right: "6px",
  top: "6px",
  zIndex: 1,
});
