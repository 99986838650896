import React, { useEffect, useState, useRef } from "react";
import FormData from "form-data";
import PropTypes from "prop-types";

// imports: syklone
import { Button, DataGrid, Grid, IconButton, icons, Tooltip, Typography, useSnackbar } from "syklone/ui/index.js";
import { useApiContext, usePermissionChecks } from "syklone/api/react/index.js";
import { WidgetInfoBox, WidgetPopoverMenu } from "syklone/components/widgets/index.js";
import { PermissionVisualElements } from "syklone/api/modules/permission_settings/data/index.js";

// imports: local
import WidgetPostProcessingEditor from "./widget_post_processing_editor.jsx";

const _MaterialsList = ({
  postProcessing,
  scanningParameters,
  machineDefinitions,
  handleEdit,
  handleDelete,
  handleDuplicate,
}) => {
  const { checkElementVisibility } = usePermissionChecks();
  const columns = [
    { field: "id", headerName: "ID", flex: 3 },
    { field: "name", headerName: "Name", flex: 2 },
    { field: "family", headerName: "Family", flex: 2 },
    { field: "type", headerName: "Type", flex: 1 },
    { field: "machineDefinitionName", headerName: "Machine Definition Name", flex: 2 },
    { field: "sliceHeight", headerName: "Slice Height", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      width: 70,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div>
            <WidgetPopoverMenu data={actionMenu(params)} />
          </div>
        );
      },
    },
  ];

  const sortedData = structuredClone(postProcessing).sort((a, b) => {
    return Date.parse(b.createdAt.$date) - Date.parse(a.createdAt.$date);
  });

  const rows = sortedData?.map((entry) => {
    const { name, family, type, machineDefinitionId, sliceHeight } = entry;

    const machine = machineDefinitions.find((e) => e._id.$oid === machineDefinitionId.$oid);

    return {
      id: entry._id.$oid,
      name: name,
      family: family,
      type: type,
      sliceHeight: sliceHeight,
      machineDefinitionName: machine?.definitionName,
    };
  });

  const actionMenu = (params) => {
    return {
      items: [
        {
          name: "Edit",
          action: () => handleEdit(params.id),
          isHighlighted: false,
          disabled: !checkElementVisibility(PermissionVisualElements.editPostProcessingParameter),
        },
        {
          name: "Create duplicate",
          action: () => handleDuplicate(params.id),
          isHighlighted: false,
          disabled: !checkElementVisibility(PermissionVisualElements.createPostProcessingParameter),
        },
        {
          name: "Delete",
          action: () => handleDelete(params.id),
          isHighlighted: true,
          disabled: !checkElementVisibility(PermissionVisualElements.deletePostProcessingParameter),
        },
      ],
    };
  };

  return (
    <Grid item>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        sx={(theme) => ({
          fontSize: 13,
          "&.MuiDataGrid-root": {
            "& .MuiDataGrid-row.Mui-selected": {
              backgroundColor: theme.palette.mode === "dark" ? "#121212" : "#f8f8f8",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: theme.palette.mode === "dark" ? "1px solid #2F2F2F" : "1px solid #E8E8E8",
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
            },
            border: "none",
          },
          ".MuiDataGrid-columnHeaders": {
            borderBottom: theme.palette.mode === "dark" ? "1px solid #2F2F2F" : "1px solid #E8E8E8",
          },
          ".MuiDataGrid-footerContainer": {
            borderTop: "none",
          },
        })}
      />
    </Grid>
  );
};

_MaterialsList.propTypes = {
  handleDelete: PropTypes.func,
  handleDuplicate: PropTypes.func,
  handleEdit: PropTypes.func,
  machineDefinitions: PropTypes.array,
  postProcessing: PropTypes.array,
  scanningParameters: PropTypes.array,
  scanningTemplates: PropTypes.array,
};

_MaterialsList.defaultProps = {
  handleDelete: () => {},
  handleDuplicate: () => {},
  handleEdit: () => {},
  machineDefinitions: [],
  postProcessing: [],
  scanningParameters: [],
  scanningTemplates: [],
};

function WidgetPostProcessing({
  getMachineDefinitions,
  getScanningParameters,
  getUnitCells,
  getPostProcessing,
  reloadUnitCells,
  reloadPostProcessing,
}) {
  const api = useApiContext();
  const { checkElementVisibility } = usePermissionChecks();
  const [machineDefinitions, setMachineDefinitions] = useState();
  const [unitCells, setUnitCells] = useState();
  const [scanningParameters, setScanningParameters] = useState();
  const [postProcessing, setPostProcessing] = useState();
  const [errors, setErrors] = useState({});
  const [mode, setMode] = useState("list");

  let initialValues = useRef();
  let selectedId = useRef();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const machineDefinitionsSetup = async () => {
      const { data, error } = await getMachineDefinitions();
      if (error) setErrors((prevState) => ({ ...prevState, machineDefinitions: error }));

      setMachineDefinitions(data);
    };

    const unitCellsSetup = async () => {
      const { data, error } = await getUnitCells();
      if (error) setErrors((prevState) => ({ ...prevState, unitCells: error }));

      setUnitCells(data);
    };

    const scanningParametersSetup = async () => {
      const { data, error } = await getScanningParameters();
      if (error) setErrors((prevState) => ({ ...prevState, scanningParameters: error }));

      setScanningParameters(data);
    };

    const postProcessingSetup = async () => {
      const { data, error } = await getPostProcessing();
      if (error) setErrors((prevState) => ({ ...prevState, postProcessing: error }));

      setPostProcessing(data);
    };

    machineDefinitionsSetup();
    scanningParametersSetup();
    postProcessingSetup();
    unitCellsSetup();
  }, []);

  useEffect(() => {
    if (machineDefinitions?.length === 0) {
      enqueueSnackbar("No machine definitions found.", {
        variant: "warning",
      });
    }
  }, [machineDefinitions]);

  useEffect(() => {
    if (scanningParameters?.length === 0) {
      enqueueSnackbar("No scanning parameters found.", {
        variant: "warning",
      });
    }
  }, [scanningParameters]);

  useEffect(() => {
    if (postProcessing?.length === 0) {
      enqueueSnackbar("No post processing data found.", {
        variant: "warning",
      });
    }
  }, [postProcessing]);

  useEffect(() => {
    if (unitCells?.length === 0) {
      enqueueSnackbar("No unit cells data found.", {
        variant: "warning",
      });
    }
  }, [unitCells]);

  const fetchUnitCells = async () => {
    const { data, error } = await reloadUnitCells();
    if (error) setErrors({ ...errors, unitCells: error });
    setUnitCells(data);
  };

  const fetchPostProcessing = async () => {
    const { data, error } = await reloadPostProcessing();
    if (error) setErrors({ ...errors, postProcessing: error });
    setPostProcessing(data);
  };

  const handleEdit = (id) => {
    const selectedPostProcessing = postProcessing.find((definition) => definition._id.$oid === id);
    const { createdAt, _id, ...filteredValues } = selectedPostProcessing; // eslint-disable-line
    selectedId.current = _id.$oid;
    initialValues.current = filteredValues;
    setMode("edit");
  };

  const handleNewTemplate = () => {
    initialValues.current = undefined;
    selectedId.current = undefined;
    setMode("new");
  };

  const handleDuplicate = (id) => {
    const selectedPostProcessing = postProcessing.find((definition) => definition._id.$oid === id);
    const { createdAt, _id, ...filteredValues } = selectedPostProcessing; // eslint-disable-line
    selectedId.current = undefined;
    initialValues.current = filteredValues;
    setMode("new");
  };

  const handleDelete = async (id) => {
    try {
      const response = await api.sykloneApi.sk.svcBuildCreator.ApiPostProcessing.deleteAllByPostProcessingId(id);
      enqueueSnackbar(response.data.data.message, {
        variant: "success",
      });
      await fetchPostProcessing();
    } catch (error) {
      enqueueSnackbar(`Unable to delete machine definition due to ${error.toString()}`, {
        variant: "error",
      });
      setErrors(error);
    }
  };

  const handleSubmitNew = async (values) => {
    try {
      await api.sykloneApi.sk.svcBuildCreator.ApiPostProcessing.postAll(values);
      enqueueSnackbar("Successfully uploaded!", {
        variant: "success",
      });
      fetchPostProcessing();
      setMode("list");
    } catch (error) {
      enqueueSnackbar(error, {
        variant: "error",
      });
    }
  };

  const handleSubmitOverride = async (id, values) => {
    try {
      await api.sykloneApi.sk.svcBuildCreator.ApiPostProcessing.putAllByPostProcessingId(id, values);
      enqueueSnackbar(`Post processing of id:${id} successfully updated!`, {
        variant: "success",
      });
      await fetchPostProcessing();
      setMode("list");
    } catch (error) {
      enqueueSnackbar(`Error updating post processing due to ${error.detail}`, {
        variant: "error",
      });
    }
  };

  const handleUnitCellUpload = async (file) => {
    const formData = new FormData();
    formData.append("files", file, file.name);

    try {
      const resp = await api.sykloneApi.sk.svcBuildCreator.ApiUnitCells.postAll(formData);
      const name = resp.data?.["unit-cells"]?.filename;

      enqueueSnackbar(`Unit cell ${name} successfully uploaded!`, {
        variant: "success",
      });
      await fetchUnitCells();
    } catch (error) {
      enqueueSnackbar(`Error uploaded cell due to ${error.name},  ${error.message}`, {
        variant: "error",
      });
    }
  };

  return (
    <Grid container item>
      {
        {
          list: (
            <Grid container item>
              <Grid container item position="relative">
                <Grid item flexGrow={1}>
                  <Typography component="h1" variant="h4" align="center" color="inherit" noWrap mb={2}>
                    Post Processing
                  </Typography>
                </Grid>
                <Button
                  id="create-post-processing-parameter"
                  disabled={!checkElementVisibility(PermissionVisualElements.createPostProcessingParameter)}
                  onClick={handleNewTemplate}
                  sx={{ position: "absolute", right: 0 }}
                  variant="contained"
                  color="success"
                >
                  New Material
                </Button>
              </Grid>
              <Grid container item>
                {Object.keys(errors)?.length > 0 ? (
                  <Grid container spacing={1}>
                    {Object.keys(errors).map((key) => (
                      <Grid item xs={12} key={key}>
                        <WidgetInfoBox variant="error">{errors[key]}</WidgetInfoBox>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  postProcessing?.length && (
                    <Grid item xs={12}>
                      <_MaterialsList
                        machineDefinitions={machineDefinitions}
                        scanningParameters={scanningParameters}
                        postProcessing={postProcessing}
                        handleDelete={handleDelete}
                        handleEdit={handleEdit}
                        handleNewTemplate={handleNewTemplate}
                        handleDuplicate={handleDuplicate}
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </Grid>
          ),
          new: (
            <Grid container item justifyContent="center" xs={12}>
              <Grid container item flexDirection="row">
                <Grid item flexGrow={1} flexShrink={1} alignSelf="start">
                  <Tooltip title="Discard and show list" arrow placement="top">
                    <IconButton aria-label="delete" onClick={() => setMode("list")}>
                      <icons.mui.ArrowBack />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item flexGrow={1} flexShrink={1} alignSelf="center" marginBottom={1}>
                  <Typography component="h1" variant="h4" color="inherit">
                    New Material
                  </Typography>
                </Grid>
              </Grid>
              <Grid container item xs={9} alignItems="center">
                <WidgetPostProcessingEditor
                  data={initialValues.current}
                  machines={machineDefinitions}
                  unitCells={unitCells}
                  scanningParameters={scanningParameters}
                  onSubmit={handleSubmitNew}
                  onUnitCellUpload={handleUnitCellUpload}
                />
              </Grid>
            </Grid>
          ),
          edit: (
            <Grid container item justifyContent="center" xs={12}>
              <Grid container item flexDirection="row" position="relative">
                <Tooltip sx={{ position: "absolute" }} title="Discard and show list" arrow placement="top">
                  <IconButton aria-label="delete" onClick={() => setMode("list")}>
                    <icons.mui.ArrowBack />
                  </IconButton>
                </Tooltip>
                <Grid container item flexDirection="column" alignItems="center">
                  <Grid item flexGrow={1} flexShrink={1}>
                    <Typography component="h1" variant="h4" color="inherit">
                      Edit Material
                    </Typography>
                  </Grid>
                  <Grid item>id: {selectedId.current}</Grid>
                </Grid>
              </Grid>
              <Grid container item xs={9} alignItems="center">
                <WidgetPostProcessingEditor
                  data={initialValues.current}
                  machines={machineDefinitions}
                  unitCells={unitCells}
                  scanningParameters={scanningParameters}
                  onSubmit={(values) => handleSubmitOverride(selectedId.current, values)}
                  onUnitCellUpload={handleUnitCellUpload}
                />
              </Grid>
            </Grid>
          ),
        }[mode]
      }
    </Grid>
  );
}

WidgetPostProcessing.propTypes = {
  getMachineDefinitions: PropTypes.func,
  getPostProcessing: PropTypes.func,
  getScanningParameters: PropTypes.func,
  getUnitCells: PropTypes.func,
  reloadPostProcessing: PropTypes.func,
  reloadUnitCells: PropTypes.func,
};

WidgetPostProcessing.defaultProps = {
  getMachineDefinitions: () => {},
  getPostProcessing: () => {},
  getScanningParameters: () => {},
  getUnitCells: () => {},
  reloadPostProcessing: () => {},
  reloadUnitCells: () => {},
};

export default WidgetPostProcessing;
