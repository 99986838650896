import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";

// imports: syklone
import {
  Button,
  Divider,
  Form,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Formik,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  Typography,
  Yup,
} from "syklone/ui/index.js";
import { WidgetInfoBox, WidgetInputNumber } from "syklone/components/widgets/index.js";

// imports: local
import { WidgetHelperCard } from "../../../widgets/index.js";
import { withInfoPopover } from "../../../hoc/index.js";
import { ImageAutoIncrement } from "./data/index.js";
import WidgetJsonPreview from "./widget_json_preview.jsx";

const _AutoIncrement = ({ data, errors, touched, handleBlur, fieldName, setFieldTouched, setFieldValue }) => {
  const savedValues = useRef({ increment: 0, excess: 0 });
  const [helperImageData, setHelperImageData] = useState();

  useEffect(() => {
    const fetchImage = async () => {
      const blob = await fetch(ImageAutoIncrement).then((r) => r.blob());
      const imgFile = new File([blob], "Example image", { type: "image/png" });
      setHelperImageData(imgFile);
    };

    fetchImage();
  }, []);

  const handleSwitchChange = () => {
    const values = {
      value: !data.value,
    };

    if (!values.value) {
      const { increment, excess } = data;
      savedValues.current = { increment: increment, excess: excess };
      setFieldValue(`parameters[${fieldName}].autoIncrement`, values);
      return;
    }

    setFieldValue(`parameters[${fieldName}].autoIncrement`, { ...values, ...savedValues.current });
  };

  return (
    <Grid container item flexDirection="row" justifyContent="flex-start" alignItems="center" spacing={1}>
      <Grid item alignItems="center">
        <FormControlLabel
          control={<Switch checked={data.value} onChange={handleSwitchChange} />}
          labelPlacement="start"
          label="Auto increment"
          sx={{ margin: 0 }}
          disabled={true}
        />
        <_WidgetHelperCardWithInfoPopover title={"Auto Increment"} width={400} imageData={helperImageData}>
          <Grid container item flexDirection="column" spacing={1}>
            <Grid item>
              Auto increment value with part iteration. Allows definition an incremental value so that parts in the
              platform will have increasing values for this parameter. The value will increment with the print order.
            </Grid>
            <Grid item container spacing={0.5}>
              <Grid item>Parameters:</Grid>
              <Grid item>
                Initial value - starting value for this parameter. If only one part is present in the platform, this is
                the value that it will obtain. Defined on the lefthand side of the switch.
              </Grid>
              <Grid item>Increment - value added to each following part</Grid>
              <Grid item>
                Excess - if a part were to obtain a value greater than the maximum possible for the machine, as defined
                in the maximum field in the corresponding parameter template, it would receive this parameter instead.
              </Grid>
              <Grid item mt={1} fontWeight={1000}>
                Functionality not implemented yet.
              </Grid>
            </Grid>
          </Grid>
        </_WidgetHelperCardWithInfoPopover>
      </Grid>
      {data.value ? (
        <>
          <Grid item alignItems="center">
            <WidgetInputNumber
              name={"increment"}
              label={"Increment"}
              value={data.increment}
              onApply={(key, value) => {
                setFieldTouched(`parameters[${fieldName}].autoIncrement.increment`, true);
                setFieldValue(`parameters[${fieldName}].autoIncrement.increment`, value);
              }}
              onBlur={handleBlur}
              error={
                Boolean(errors.parameters?.[fieldName]?.autoIncrement?.increment) &&
                touched.parameters?.[fieldName]?.autoIncrement?.increment
              }
              helperText={
                touched.parameters?.[fieldName]?.autoIncrement?.increment &&
                errors.parameters?.[fieldName]?.autoIncrement?.increment
              }
            />
          </Grid>
          <Grid item alignItems="center">
            <WidgetInputNumber
              name={"excess"}
              label={"Excess"}
              value={data.excess}
              onApply={(key, value) => {
                setFieldTouched(`parameters[${fieldName}].autoIncrement.excess`, true);
                setFieldValue(`parameters[${fieldName}].autoIncrement.excess`, value);
              }}
              onBlur={handleBlur}
              error={
                Boolean(errors.parameters?.[fieldName]?.autoIncrement?.excess) &&
                touched.parameters?.[fieldName]?.autoIncrement?.excess
              }
              helperText={
                touched.parameters?.[fieldName]?.autoIncrement?.excess &&
                errors.parameters?.[fieldName]?.autoIncrement?.excess
              }
            />
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

_AutoIncrement.propTypes = {
  data: PropTypes.object,
  errors: PropTypes.object,
  handleBlur: PropTypes.func,
  fieldName: PropTypes.string,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  touched: PropTypes.object,
};

const _WidgetHelperCardWithInfoPopover = withInfoPopover(WidgetHelperCard);

const _InformationCard = ({ title, image, minValue, maxValue, unit, description }) => {
  return (
    <_WidgetHelperCardWithInfoPopover title={title} imageData={image}>
      <Grid container item spacing={1} flexDirection="column">
        <Grid container item flexDirection="row" sx={{ "&>*": { marginRight: "0.5rem" } }}>
          <Grid item>{`Min: ${minValue}`}</Grid>
          <Grid item>{`Max: ${maxValue}`}</Grid>
          <Grid item>{`Units: ${unit}`}</Grid>
        </Grid>
        <Grid item>{description}</Grid>
      </Grid>
    </_WidgetHelperCardWithInfoPopover>
  );
};
_InformationCard.propTypes = {
  image: PropTypes.object,
  title: PropTypes.string,
  unit: PropTypes.string,
  description: PropTypes.string,
  minValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

_InformationCard.propTypes = {
  image: "undefined",
  title: "No title.",
  unit: "",
  description: undefined,
  minValue: "",
  maxValue: "",
};

const _TemplateParameters = ({
  errors,
  handleBlur,
  handleChange,
  scanningParameters,
  setFieldTouched,
  setFieldValue,
  touched,
  values,
}) => {
  return (
    <>
      <Grid container flexDirection="column">
        {scanningParameters.length === 0 ? (
          <Grid item mb={2} sx={{ padding: 0 }}>
            <WidgetInfoBox>No parameters of current scanning template found.</WidgetInfoBox>
          </Grid>
        ) : null}
        <Grid container item spacing={2}>
          {values.parameters.map((parameter, index) => {
            return (
              <Grid item key={`parameter_${index}`} xs={12}>
                <Grid container alignItems="center" spacing={1}>
                  <Grid item>
                    {(() => {
                      const { dataType, unit } = scanningParameters.find((param) => param.fieldName === parameter.name);
                      switch (dataType) {
                        case "numeric":
                          return (
                            <WidgetInputNumber
                              name={parameter.name}
                              label={parameter.name}
                              suffix={unit}
                              value={parameter.value}
                              onApply={(key, value) => {
                                setFieldTouched(`parameters[${index}.value]`, true);
                                setFieldValue(`parameters[${index}.value]`, value);
                              }}
                              onBlur={handleBlur}
                              error={Boolean(errors.parameters?.[index]?.value) && touched.parameters?.[index]?.value}
                              helperText={touched.parameters?.[index]?.value && errors.parameters?.[index]?.value}
                            />
                          );
                        case "other":
                          return (
                            <TextField
                              name={`parameters[${parameter.name}]`}
                              label={parameter.name}
                              variant="outlined"
                              value={parameter.value}
                              onChange={handleChange}
                              error={Boolean(errors.parameters?.[index]?.value) && touched.parameters?.[index]?.value}
                              helperText={touched.parameters?.[index]?.value && errors.parameters?.[index]?.value}
                            />
                          );
                        default:
                          return (
                            <TextField
                              name={`parameters[${parameter.name}]`}
                              label={parameter.name}
                              variant="outlined"
                              value={parameter.value}
                              onChange={handleChange}
                              error={Boolean(errors.parameters?.[index].value) && touched.parameters?.[index].value}
                              helperText={touched.parameters?.[index].value && errors.parameters?.[index].value}
                            />
                          );
                      }
                    })()}
                  </Grid>
                  <Grid item>
                    {(() => {
                      const found = scanningParameters.find((param) => param.fieldName === parameter.name);
                      return (
                        <_InformationCard
                          image={found.image}
                          title={found.fieldName}
                          minValue={found.minValue}
                          maxValue={found.maxValue}
                          unit={found.unit}
                          description={found.description}
                        />
                      );
                    })()}
                  </Grid>
                  <Grid item ml={1}>
                    <_AutoIncrement
                      data={parameter.autoIncrement}
                      errors={errors}
                      fieldName={parameter.name}
                      handleBlur={handleBlur}
                      setFieldTouched={setFieldTouched}
                      setFieldValue={setFieldValue}
                      touched={touched}
                    />
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
      </Grid>
    </>
  );
};

_TemplateParameters.propTypes = {
  errors: PropTypes.object,
  handleBlur: PropTypes.func,
  handleChange: PropTypes.func,
  scanningParameters: PropTypes.array,
  setFieldTouched: PropTypes.func,
  setFieldValue: PropTypes.func,
  touched: PropTypes.object,
  values: PropTypes.object,
};

const WidgetScanningParametersEditor = ({ data, scanningTemplates, handleSubmit }) => {
  const [scanningParameters, setScanningParameters] = React.useState([]);
  const [initialValues, setInitialValues] = useState();

  const initialSchema = Yup.object().shape({
    buildName: Yup.string().required("Build name is required."),
    templateId: Yup.object().required("Scanning template is required."),
    materialMetadata: Yup.string(),
  });

  const [schema, setSchema] = React.useState(initialSchema);

  const onSubmit = (values) => {
    handleSubmit(values);
  };

  const prepareData = (data) => {
    const { buildName, templateId, machineDefinitionId, parameters, materialMetadata } = data;

    const scanningTemplate = scanningTemplates.find((template) => template._id.$oid === templateId.$oid);
    const scanningParameters = scanningTemplate ? scanningTemplate.scanningParameters : [];

    const parametersValues = [];

    for (const parameter of scanningParameters) {
      const { fieldName } = parameter;
      const found = parameters.find((parameter) => parameter.name === fieldName);
      parametersValues.push({
        name: fieldName,
        value: found?.value ? found.value : parameter.dataType === "numeric" ? 0 : "",
        autoIncrement: { value: false },
      });
    }

    const parametersSchema =
      scanningParameters.length > 0
        ? Yup.object().shape({
            parameters: Yup.array().of(
              Yup.object().shape({
                name: Yup.string().required(),
                autoIncrement: Yup.object().shape({
                  value: Yup.bool().required(),
                  increment: Yup.number(),
                  excess: Yup.number(),
                }),
                value: Yup.number()
                  .required()
                  .test("is-valid", function (value) {
                    const { name } = this.parent;
                    const constraint = scanningParameters.find((c) => c.fieldName === name);
                    if (!constraint) return true;
                    const isValid = value >= constraint.minValue && value <= constraint.maxValue;
                    return (
                      isValid ||
                      this.createError({
                        path: this.path,
                        message: `${name} value ${value} is out of range <${constraint.minValue}, ${constraint.maxValue}>`,
                      })
                    );
                  }),
              })
            ),
          })
        : Yup.object();

    const schema = initialSchema.concat(parametersSchema);

    return {
      preparedValues: {
        buildName: buildName,
        templateId: templateId,
        machineDefinitionId: machineDefinitionId,
        parameters: parametersValues,
        materialMetadata: materialMetadata || "",
      },
      scanningParameters: scanningParameters,
      schema: schema,
    };
  };

  useEffect(() => {
    const preparedData = prepareData(data);
    const { scanningParameters, schema, preparedValues } = preparedData;

    setScanningParameters(scanningParameters);
    setSchema(schema);
    setInitialValues(preparedValues);
  }, [data]);

  const onChangeTemplate = (event, values, setValues) => {
    const { scanningParameters, preparedValues, schema } = prepareData({
      ...values,
      templateId: event.target.value,
    });

    setScanningParameters(scanningParameters);
    setSchema(schema);
    setValues(preparedValues);
  };

  return (
    <>
      {initialValues ? (
        <Formik initialValues={initialValues} onSubmit={onSubmit} validationSchema={schema} enableReinitialize>
          {({ values, touched, errors, setValues, handleChange, handleBlur, setFieldValue, setFieldTouched }) => (
            <Form
              data-syklone="scanning-parameters"
              style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%" }}
            >
              <Grid item container flexDirection="column" alignItems="center" mt={2} xs={12} md={8}>
                <Grid item container>
                  <Grid item container flexDirection="row" spacing={2} mb={2}>
                    <Grid item>
                      <TextField
                        id="buildName"
                        name="buildName"
                        label="Build Name"
                        variant="outlined"
                        value={values.buildName}
                        onChange={handleChange}
                        error={touched.buildName && Boolean(errors.buildName)}
                        helperText={touched.buildName && errors.buildName}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        id="materialMetadata"
                        name="materialMetadata"
                        label="Material Metadata"
                        variant="outlined"
                        value={values.materialMetadata}
                        onChange={(e) => handleChange(e)}
                        error={touched.materialMetadata && Boolean(errors.materialMetadata)}
                        helperText={touched.materialMetadata && errors.materialMetadata}
                      />
                    </Grid>
                  </Grid>

                  <Grid item container flexDirection="column" spacing={1}>
                    <Grid item mb={0.5}>
                      <Divider />
                    </Grid>
                    <Grid item>
                      <Typography gutterBottom variant="h5" component="h2">
                        Parameters
                      </Typography>
                    </Grid>
                    <Grid item>
                      <FormControl
                        variant="outlined"
                        sx={{ minWidth: "220px!important" }}
                        error={touched.templateId && Boolean(errors.templateId)}
                      >
                        <InputLabel id="select-scanning-template" key="templateSelect">
                          Select scanning template
                        </InputLabel>
                        <Select
                          name="templateId"
                          label="Select scanning template"
                          onChange={(e) => {
                            onChangeTemplate(e, values, setValues);
                          }}
                          value={
                            scanningTemplates.find((template) => template._id.$oid === values.templateId.$oid)?._id
                          }
                        >
                          {scanningTemplates.map((parameters, index) => {
                            return (
                              <MenuItem key={index} value={parameters._id}>
                                {parameters["templateName"]}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {errors.templateId && touched.templateId && (
                          <FormHelperText>{errors.templateId}</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item data-syklone="scanning-parameters-fields" mt={2}>
                      <_TemplateParameters
                        errors={errors}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        scanningParameters={scanningParameters}
                        setFieldTouched={setFieldTouched}
                        setFieldValue={setFieldValue}
                        touched={touched}
                        values={values}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} mt={2}>
                  <Grid container spacing={3} justifyContent="center">
                    <Grid item>
                      <WidgetJsonPreview json={values} />
                    </Grid>
                    <Grid item>
                      <Button size="large" color="primary" variant="contained" type="submit">
                        Commit
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      ) : null}
    </>
  );
};

WidgetScanningParametersEditor.propTypes = {
  handleSubmit: PropTypes.func,
  data: PropTypes.object,
  scanningTemplates: PropTypes.array,
};

WidgetScanningParametersEditor.defaultProps = {
  handleSubmit: () => {},
  data: {
    buildName: "",
    machineDefintionId: "",
    materialMetadata: "",
    parameters: {},
    templateId: "",
  },
  scanningTemplates: [],
};

export default WidgetScanningParametersEditor;
