import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { IconButton, icons, Tooltip } from "syklone/ui/index.js";

const DialogRemoteCommonRefreshTooltip = ({ onRefresh, dataTag }) => {
  return (
    <Tooltip title="Refresh">
      <IconButton onClick={onRefresh} sx={{ marginTop: -5, marginBottom: -5 }} data-syklone={dataTag}>
        <icons.mui.Refresh />
      </IconButton>
    </Tooltip>
  );
};

DialogRemoteCommonRefreshTooltip.propTypes = {
  onRefresh: PropTypes.func,
  dataTag: PropTypes.string.isRequired,
};

export default DialogRemoteCommonRefreshTooltip;
