import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Box, Typography, icons, styled, IconButton } from "syklone/ui/index.js";

const widgerRenderWidth = 867;
const widgetRenderHeight = 650;
const actionBarOffset = 59.49;

const _ExpandButton = styled(IconButton)({
  position: "absolute !important",
  zIndex: 999,
  left: "12px",
  top: "72px",
  opacity: "1!important",
  backgroundColor: "#3b3b3b!important",
  boxShadow: "-4px 6px 9px -5px rgba(0,0,0,1)",
  "&:hover": {
    background: "#929292!important",
  },
});

const DialogSnapshotPreview = ({ snapshot, onBack }) => {
  return (
    <Box sx={{ height: widgetRenderHeight + actionBarOffset, width: widgerRenderWidth }}>
      <_ExpandButton onClick={() => onBack()} size="small">
        <icons.mui.ArrowBack fontSize="small" />
      </_ExpandButton>
      <Box sx={{ background: "#000", borderRadius: "5px", display: "flex", justifyContent: "center" }}>
        <img style={{ width: "70%" }} src={snapshot.getImage()} alt={snapshot.getName()} />
      </Box>
      <Typography
        component="pre"
        sx={{ marginTop: "2em!important", marginLeft: "5px", color: "rgba(255, 255, 255, 0.7)" }}
      >
        {snapshot.getSummary()}
      </Typography>
    </Box>
  );
};

DialogSnapshotPreview.propTypes = {
  snapshot: PropTypes.object,
  onBack: PropTypes.func,
};

export default DialogSnapshotPreview;
