import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { useApiContext } from "syklone/api/react/index.js";
import * as coreCommands from "syklone/graphics/tools/core_commands/index.js";

// imports: local
import DialogWizardCommonCommit from "../dialog_wizard_common_commit/dialog_wizard_common_commit.jsx";

const DialogRemoteCommitPlatforms = React.forwardRef(
  ({ buildFileId, buildFileStatus, widgetRender, tool, username }, ref) => {
    const [rows, setRows] = React.useState([]);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isConfirmDisabled, setIsConfirmDisabled] = React.useState(false);
    const [loadingText, setIsLoadingText] = React.useState("");

    const api = useApiContext();
    const commandManager = api.commandManager;
    const servicePlatformSessions = api.sykloneApi.servicePlatformSessions;
    const sessionSettings = api.sessionSettings;
    const profileManager = api.profileManager;
    let currentProfile = profileManager.getCurrentProfile();

    React.useImperativeHandle(ref, () => ({
      show: () => {
        setIsOpen(true);
        setIsConfirmDisabled(false);
      },
    }));

    const onComplete = (filename, snapshots) => {
      function loader(loading, text) {
        setIsLoading(loading);
        setIsLoadingText(text);
        if (loading) {
          setIsConfirmDisabled(true);
        } else {
          setIsOpen(false);
          setIsConfirmDisabled(false);
        }
      }

      let slicerType;
      if (currentProfile.debug) {
        slicerType = sessionSettings.data().slicerType;
      } else {
        slicerType = "bfo";
      }

      setIsConfirmDisabled(true);
      const data = {
        filename: filename,
        user: username,
        slicer: slicerType,
        snapshots: snapshots,
        buildFileId: buildFileId,
        buildFileStatus: buildFileStatus,
      };

      commandManager.executeAsync(new coreCommands.CommandCommitPlatformRemotely(api, tool, loader, data));
    };

    const onRefresh = async () => {
      try {
        let response = await servicePlatformSessions.getCommitted();
        let rows = response.data.result;
        setRows(rows);
      } catch (error) {
        alert(`${error}`);
      }
    };

    const onCreateSnapshots = () => {
      const cmd = new coreCommands.CommandCreateSnapshotsPlatform(tool);
      commandManager.execute(cmd);
    };

    return (
      <DialogWizardCommonCommit
        widgetRender={widgetRender}
        filenameExistsText="Committed platform cannot be overwritten"
        title="Commit platforms"
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        rows={rows}
        fileNameEnabled={true}
        onComplete={onComplete}
        onRefresh={onRefresh}
        handleCreateSnapshots={onCreateSnapshots}
        isLoading={isLoading}
        loadingText={loadingText}
        isSubmitting={isConfirmDisabled}
        dataTag="commit-platforms-dialog"
        stepTitle1="Adjust Snapshots"
        stepTitle2="Review snapshots"
        stepTitle3="Commit the platform"
      />
    );
  }
);

DialogRemoteCommitPlatforms.displayName = "DialogRemoteCommitPlatforms";

DialogRemoteCommitPlatforms.propTypes = {
  buildFileId: PropTypes.string,
  buildFileStatus: PropTypes.string,
  tool: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  widgetRender: PropTypes.object,
  username: PropTypes.string,
};

export default DialogRemoteCommitPlatforms;
