import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Box, Typography, Grid, styled } from "syklone/ui/index.js";
import DialogSnapshotPreview from "./dialog_snapshot_preview.jsx";

const widgerRenderWidth = 867;
const widgetRenderHeight = 650;
const itemPaddingTop = 16;

const _SnapshotBox = styled(Box)({
  display: "flex",
  flexDirection: "row",
  backgroundColor: "#434343",
  padding: 2,
  height: "100%",
  "&:hover": {
    backgroundColor: "#757575",

    cursor: "pointer",

    "& pre": {
      color: "#fff!important",
    },
  },
});

const DialogRemoteCommonSnapshotsList = ({ snapshots, onSnapshotClick }) => {
  const handleSnapshotSelection = (snapshot) => {
    setSelectedSnapshot(snapshot);
    onSnapshotClick(snapshot);
  };

  const [selectedSnapshot, setSelectedSnapshot] = React.useState(null);
  return (
    <>
      {selectedSnapshot !== null ? (
        <DialogSnapshotPreview
          snapshot={selectedSnapshot}
          onBack={() => {
            handleSnapshotSelection(null);
          }}
        />
      ) : (
        <Box sx={{ height: widgetRenderHeight + itemPaddingTop, width: widgerRenderWidth }}>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={2}
            component="ul"
            sx={{
              listStyleType: "none",
              marginInlineEnd: 0,
              paddingInlineStart: 0,
            }}
          >
            {snapshots.map((snapshot, index) => {
              return (
                <Grid item md={6} xs={12} key={snapshot.getName()} component="li">
                  <_SnapshotBox
                    tabIndex="0"
                    onClick={() => {
                      handleSnapshotSelection(snapshot);
                    }}
                    role="button"
                    aria-label={`Preview of snapshots snapshot ${index + 1}`}
                  >
                    <Box style={{ borderRadius: "5px", display: "flex", backgroundColor: "#000" }}>
                      <img
                        src={snapshot.getImage()}
                        alt={snapshot.getName()}
                        style={{ width: "120px", objectFit: "contain" }}
                      />
                    </Box>
                    <Typography
                      component="pre"
                      sx={{
                        paddingLeft: "2em",
                        margin: "5px 0px 5px",
                        fontFamily: "inherit",
                        fontSize: "0.8em!important",
                        wordWrap: "break-word",
                        whiteSpace: "pre-wrap",
                        color: "rgba(255, 255, 255, 0.7)",
                      }}
                    >
                      {snapshot.getSummary()}
                    </Typography>
                  </_SnapshotBox>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      )}
    </>
  );
};

DialogRemoteCommonSnapshotsList.propTypes = {
  snapshots: PropTypes.array,
  onSnapshotClick: PropTypes.func,
};

export default DialogRemoteCommonSnapshotsList;
