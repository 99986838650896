import React, { useRef } from "react";
import { useLocation, Link, Route, Navigate, Routes } from "react-router-dom";

// imports: syklone
import { useAuthContext, useApiContext, AuthProtectedRoute } from "syklone/api/react/index.js";

import {
  Box,
  createTheme,
  CssBaseline,
  Grid,
  icons,
  SnackbarProvider,
  styled,
  Tab,
  Tabs,
  ThemeProvider,
  themes,
  useSnackbar,
} from "syklone/ui/index.js";
import { DialogProfile, DialogSettings } from "syklone/components/dialogs/index.js";
import { PermissionApplicationRoutes } from "syklone/api/modules/permission_settings/data/index.js";

// imports: local
import { LogoBuildCreator } from "./data/index.js";
import {
  WidgetMachineDefinition,
  WidgetScanningTemplate,
  WidgetScanningParameters,
  WidgetPostProcessing,
} from "./widgets/index.js";
import { WidgetTopBar, WidgetPageTitle } from "../../widgets/index.js";

const _AppHeaderWrapperGrid = styled(Grid)(({ theme }) => ({
  display: "flex",
  background: theme.palette.background.default,
  borderBottom: `1px solid ${theme.separator.color}`,
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column!important",
    alignItems: "center!important",
    padding: "15px 15px 0px 15px!important",
  },
}));

const _NavWrapperBox = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  width: "calc(100% - 400px)",
  [theme.breakpoints.down("sm")]: {
    width: "100%!important",
  },
}));

const _StyledTab = styled(Tab)(({ theme }) => ({
  height: "65px",
  [theme.breakpoints.down("sm")]: {
    "& span": {
      textTransform: "none",
    },
  },
  [theme.breakpoints.down("xs")]: {
    "& span": {
      display: "none",
    },
  },
}));

class DataManager {
  #api;

  #fields;

  #notifier;

  constructor(api, notifier = () => {}) {
    this.#api = api;

    this.#notifier = notifier;

    this.#fields = {
      machineDefinitions: {
        data: undefined,
        fetchFunction: api.sykloneApi.sk.svcBuildCreator.ApiMachineDefinition.getAll,
        responseField: "machine-definitions",
      },
      scanningTemplates: {
        data: undefined,
        fetchFunction: api.sykloneApi.sk.svcBuildCreator.ApiScanningTemplates.getAll,
        responseField: "scanning-templates",
      },
      scanningParameters: {
        data: undefined,
        fetchFunction: api.sykloneApi.sk.svcBuildCreator.ApiScanningParameters.getAll,
        responseField: "scanning-parameters",
      },
      unitCells: {
        data: undefined,
        fetchFunction: api.sykloneApi.sk.svcBuildCreator.ApiUnitCells.getAll,
        responseField: "unit-cells",
      },
      postProcessings: {
        data: undefined,
        fetchFunction: api.sykloneApi.sk.svcBuildCreator.ApiPostProcessing.getAll,
        responseField: "post-processings",
      },
    };
  }

  fetchData = async function (fieldName) {
    const field = this.#fields[fieldName];
    try {
      if (!Object.keys(this.#fields).includes(fieldName)) throw new Error(`Property is not supported.`);
      const response = await field.fetchFunction();

      const data = response.data.data[field.responseField];

      if (data.length > 0) {
        this.#notifier(`Data ${fieldName} has successfully been fetched.`, "success");
      }
      field.data = data;
      return { data: data, error: undefined };
    } catch (error) {
      const message = `Unable to fetch ${fieldName} due to ${error.detail}`;
      this.#notifier(message, "error");
      return { data: undefined, error: message };
    }
  };

  getData = async function (fieldName) {
    try {
      if (!Object.keys(this.#fields).includes(fieldName)) throw new Error(`Property is not supported.`);
      if (this.#fields[fieldName].data) return { data: this.#fields[fieldName].data, error: undefined };
      const { error } = await this.fetchData(fieldName);
      return { data: this.#fields[fieldName].data, error: error };
    } catch (error) {
      const message = `Unable to get ${fieldName} data due to ${error.toString()}`;
      this.#notifier(message, "error");
      return { data: undefined, error: message };
    }
  };
}

function BuildCreatorContent() {
  const location = useLocation();
  const api = useApiContext();
  const { enqueueSnackbar } = useSnackbar();

  const dataManager = useRef(
    new DataManager(api, (message, variant) =>
      enqueueSnackbar(message, {
        variant: variant,
      })
    )
  );

  return (
    <>
      <Grid container direction="column">
        <_AppHeaderWrapperGrid item>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "200px" }}>
            <img src={LogoBuildCreator} alt="Build Creator" />
          </Box>
          <_NavWrapperBox>
            <Tabs
              value={location.pathname}
              variant="scrollable"
              scrollButtons="auto"
              indicatorColor="primary"
              textColor="primary"
            >
              <_StyledTab
                component={Link}
                value="/build-creator/machine-definition"
                icon={<icons.mui.Build />}
                to="machine-definition"
                label={<span>Machine Definition</span>}
              />

              <_StyledTab
                component={Link}
                value="/build-creator/scanning-template"
                to="scanning-template"
                icon={<icons.mui.Description />}
                label={<span>Scanning Templates</span>}
              />

              <_StyledTab
                component={Link}
                value="/build-creator/scanning-parameters"
                to="scanning-parameters"
                icon={<icons.mui.Tune />}
                label={<span>Scanning Parameters</span>}
              />

              <_StyledTab
                component={Link}
                value="/build-creator/post-processing"
                to="post-processing"
                icon={<icons.mui.SettingsApplications />}
                label={<span>Post Processing</span>}
              />
            </Tabs>
          </_NavWrapperBox>
        </_AppHeaderWrapperGrid>
        <Grid item container justifyContent="center">
          <Grid
            item
            container
            justifyContent="center"
            sx={(theme) => ({
              margin: "2rem",
              padding: "2rem",
              borderRadius: 6,
              background: theme.palette.background.surface,
            })}
          >
            <Routes>
              <Route index element={<Navigate to="/build-creator/scanning-parameters" replace />} />
              <Route
                path="/machine-definition"
                element={
                  <AuthProtectedRoute route={[PermissionApplicationRoutes.pageBuildCreatorMachineDefinition]}>
                    <WidgetMachineDefinition
                      getMachineDefinitions={async () => await dataManager.current.getData("machineDefinitions")}
                      reloadMachineDefinitions={async () => await dataManager.current.fetchData("machineDefinitions")}
                    />
                  </AuthProtectedRoute>
                }
              />
              <Route
                path="/scanning-template"
                element={
                  <AuthProtectedRoute route={[PermissionApplicationRoutes.pageBuildCreatorScanningTemplate]}>
                    <WidgetScanningTemplate
                      getMachineDefinitions={async () => await dataManager.current.getData("machineDefinitions")}
                      getScanningTemplates={async () => await dataManager.current.getData("scanningTemplates")}
                      reloadScanningTemplates={async () => await dataManager.current.fetchData("scanningTemplates")}
                    />
                  </AuthProtectedRoute>
                }
              />
              <Route
                path="/scanning-parameters"
                element={
                  <AuthProtectedRoute route={[PermissionApplicationRoutes.pageBuildCreatorScanningParameters]}>
                    <WidgetScanningParameters
                      getScanningParameters={async () => await dataManager.current.getData("scanningParameters")}
                      getScanningTemplates={async () => await dataManager.current.getData("scanningTemplates")}
                      reloadScanningParameters={async () => await dataManager.current.fetchData("scanningParameters")}
                    />
                  </AuthProtectedRoute>
                }
              />
              <Route
                path="/post-processing"
                element={
                  <AuthProtectedRoute route={[PermissionApplicationRoutes.pageBuildCreatorPostProcessing]}>
                    <WidgetPostProcessing
                      getMachineDefinitions={async () => await dataManager.current.getData("machineDefinitions")}
                      getScanningParameters={async () => await dataManager.current.getData("scanningParameters")}
                      getUnitCells={async () => await dataManager.current.getData("unitCells")}
                      getPostProcessing={async () => await dataManager.current.getData("postProcessings")}
                      reloadUnitCells={async () => await dataManager.current.fetchData("unitCells")}
                      reloadPostProcessing={async () => await dataManager.current.fetchData("postProcessings")}
                    />
                  </AuthProtectedRoute>
                }
              />
            </Routes>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}

const PageBuildCreator = () => {
  const api = useApiContext();
  const auth = useAuthContext();
  const refDialogSettings = React.useRef();
  const refWidgetProfile = React.useRef();
  const username = auth?.user?.name || "";
  const [theme, setTheme] = React.useState(themes.themeDark);

  const currentTheme = createTheme(theme);
  const sessionSettings = api.sessionSettings;

  React.useEffect(() => {
    const isThemeLight = sessionSettings.data().isThemeLight;
    onTheme(isThemeLight);
  }, []);

  const onTheme = (isThemeLight) => {
    const sessionSettings = api.sessionSettings;

    let data = sessionSettings.data();
    data.isThemeLight = isThemeLight;
    sessionSettings.save(data);
    setTheme(isThemeLight ? themes.themeLight : themes.themeDark);
  };

  const onSaveSettings = (settings) => {
    const sessionSettings = api.sessionSettings;
    sessionSettings.save(settings);
  };

  const onResetSettings = () => {
    const sessionSettings = api.sessionSettings;
    let dct = sessionSettings.defaults();
    sessionSettings.save(dct);
  };

  const reRender = () => {
    const sessionSettings = api.sessionSettings;
    onTheme(sessionSettings.data().isThemeLight);
  };

  const onSettings = () => {
    refDialogSettings.current.show();
  };

  const onProfile = () => {
    refWidgetProfile.current.setDialogOpen(true);
  };

  const onLogout = () => {
    auth && auth?.logout();
  };
  return (
    <>
      <ThemeProvider theme={currentTheme}>
        <CssBaseline />
        <WidgetPageTitle title="Build creator" />
        <SnackbarProvider>
          <DialogSettings
            data={sessionSettings.data()}
            options={sessionSettings.options()}
            onOk={onSaveSettings}
            onReset={onResetSettings}
            ref={refDialogSettings}
            reRender={reRender}
          />
          <DialogProfile ref={refWidgetProfile} data={auth.user} />
          <WidgetTopBar
            isLightTheme={sessionSettings.data().isThemeLight}
            username={username}
            hasNotification={true}
            onProfile={onProfile}
            onSettings={onSettings}
            onLogout={onLogout}
            onTheme={onTheme}
          />
          <BuildCreatorContent />
        </SnackbarProvider>
      </ThemeProvider>
    </>
  );
};

export default PageBuildCreator;
