import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Button, DataGrid, Grid, IconButton, icons, Tooltip, Typography, useSnackbar } from "syklone/ui/index.js";
import { useApiContext, usePermissionChecks } from "syklone/api/react/index.js";
import { WidgetInfoBox, WidgetPopoverMenu } from "syklone/components/widgets/index.js";
import { PermissionVisualElements } from "syklone/api/modules/permission_settings/data/index.js";
// imports: local
import WidgetMachineDefinitionEditor from "./widget_machine_definition_editor.jsx";

const _DefinitionsList = ({ data, onEdit, onDelete, onDuplicate }) => {
  const { checkElementVisibility } = usePermissionChecks();
  const columns = [
    { field: "id", headerName: "ID", flex: 1 },
    { field: "definitionName", headerName: "Definition Name", flex: 1 },
    { field: "manufacturer", headerName: "Manufacturer", flex: 1 },
    { field: "model", headerName: "Model", flex: 1 },
    { field: "technology", headerName: "Technology", flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      sortable: false,
      filterable: false,
      width: 70,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div>
            <WidgetPopoverMenu data={actionMenu(params)} />
          </div>
        );
      },
    },
  ];

  const sortedData = structuredClone(data).sort((a, b) => {
    return Date.parse(b.createdAt.$date) - Date.parse(a.createdAt.$date);
  });

  const rows = sortedData?.map((entry) => {
    const { definitionName, manufacturer, model, technology } = entry;

    return { id: entry._id.$oid, definitionName, manufacturer, model, technology: technology.name };
  });

  const actionMenu = (params) => {
    return {
      items: [
        {
          name: "Edit",
          action: () => onEdit(params.id),
          isHighlighted: false,
          disabled: !checkElementVisibility(PermissionVisualElements.editMachineDefinition),
        },
        {
          name: "Create duplicate",
          action: () => onDuplicate(params.id),
          isHighlighted: false,
          disabled: !checkElementVisibility(PermissionVisualElements.createMachineDefinition),
        },
        {
          name: "Delete",
          action: () => onDelete(params.id),
          disabled: !checkElementVisibility(PermissionVisualElements.deleteMachineDefinition),
          isHighlighted: true,
        },
      ],
    };
  };

  return (
    <Grid item>
      <DataGrid
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        pageSizeOptions={[10]}
        sx={(theme) => ({
          fontSize: 13,
          "&.MuiDataGrid-root": {
            "& .MuiDataGrid-row.Mui-selected": {
              backgroundColor: theme.palette.mode === "dark" ? "#121212" : "#f8f8f8",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: theme.palette.mode === "dark" ? "1px solid #2F2F2F" : "1px solid #E8E8E8",
            },
            "& .MuiDataGrid-columnHeader:focus": {
              outline: "none",
            },
            border: "none",
          },
          ".MuiDataGrid-columnHeaders": {
            borderBottom: theme.palette.mode === "dark" ? "1px solid #2F2F2F" : "1px solid #E8E8E8",
          },
          ".MuiDataGrid-footerContainer": {
            borderTop: "none",
          },
        })}
      />
    </Grid>
  );
};

_DefinitionsList.propTypes = {
  data: PropTypes.array,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onDuplicate: PropTypes.func,
};

_DefinitionsList.defaultProps = {
  data: [],
  onEdit: () => {},
  onDelete: () => {},
  onDuplicate: () => {},
};

const WidgetMachineDefinition = ({ reloadMachineDefinitions, getMachineDefinitions }) => {
  const api = useApiContext();
  const { checkElementVisibility } = usePermissionChecks();

  const [mode, setMode] = useState("list");
  const [errors, setErrors] = useState({});
  const [machineDefinitions, setMachineDefinitions] = useState();

  let initialValues = useRef();
  let selectedId = useRef();

  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const machineDefinitionsSetup = async () => {
      const { data, error } = await getMachineDefinitions();
      if (error) setErrors({ ...errors, machineDefinitions: error });

      setMachineDefinitions(data);
    };

    machineDefinitionsSetup();
  }, []);

  useEffect(() => {
    if (mode !== "edit") {
      initialValues.current = undefined;
    }
  }, [mode]);

  useEffect(() => {
    if (machineDefinitions?.length === 0) {
      enqueueSnackbar("No machine definitions found.", {
        variant: "warning",
      });
    }
  }, [machineDefinitions]);

  const fetchMachines = async () => {
    const { data, error } = await reloadMachineDefinitions();
    if (error) setErrors({ ...errors, machineDefinitions: error });
    setMachineDefinitions(data);
  };

  const handleMachineDefinitionDelete = async (id) => {
    try {
      const response = await api.sykloneApi.sk.svcBuildCreator.ApiMachineDefinition.deleteAllByMachineDefinitionId(id);
      enqueueSnackbar(response.data.data.Message, {
        variant: "success",
      });
      await fetchMachines();
    } catch (error) {
      enqueueSnackbar(`Unable to delete machine definition due to ${error.toString()}`, {
        variant: "error",
      });
      setErrors(error);
    }
  };

  const handleMachineDefinitionEdit = (id) => {
    const selectedDefinition = machineDefinitions.find((definition) => definition._id.$oid === id);
    const { createdAt, _id, ...filteredValues } = selectedDefinition; // eslint-disable-line
    selectedId.current = _id.$oid;
    initialValues.current = filteredValues;
    setMode("edit");
  };

  const handleNewDefinition = () => {
    initialValues.current = undefined;
    selectedId.current = undefined;
    setMode("new");
  };

  const handleDuplicate = (id) => {
    const selectedDefinition = machineDefinitions.find((definition) => definition._id.$oid === id);
    const { createdAt, _id, ...filteredValues } = selectedDefinition; // eslint-disable-line
    selectedId.current = undefined;
    initialValues.current = filteredValues;
    setMode("new");
  };

  const handleCreateNewMachineDefinition = async (values) => {
    try {
      await api.sykloneApi.sk.svcBuildCreator.ApiMachineDefinition.postAll(values);
      enqueueSnackbar("Machine definition successfully uploaded!", {
        variant: "success",
      });
      await fetchMachines();
      setMode("list");
    } catch (error) {
      enqueueSnackbar(`Error uploading machine definition due to ${error.name},  ${error.message}`, {
        variant: "error",
      });
    }
  };

  const handleMachineDefinitionOverride = async (id, values) => {
    try {
      await api.sykloneApi.sk.svcBuildCreator.ApiMachineDefinition.putAllByMachineDefinitionId(id, values);
      enqueueSnackbar(`Machine definition of id:${id} successfully updated!`, {
        variant: "success",
      });
      await fetchMachines();
      setMode("list");
    } catch (error) {
      enqueueSnackbar(`Error updating machine definition due to ${error.name},  ${error.message}`, {
        variant: "error",
      });
    }
  };

  return (
    <Grid container item>
      {
        {
          list: (
            <Grid container item>
              <Grid container item position="relative">
                <Grid item flexGrow={1}>
                  <Typography component="h1" variant="h4" align="center" color="inherit" noWrap mb={2}>
                    Machine Definitions
                  </Typography>
                </Grid>
                <Button
                  id="create-machine-definition"
                  disabled={!checkElementVisibility(PermissionVisualElements.createMachineDefinition)}
                  onClick={handleNewDefinition}
                  sx={{ position: "absolute", right: 0 }}
                  variant="contained"
                  color="success"
                >
                  New Definition
                </Button>
              </Grid>
              <Grid container item>
                {Object.keys(errors)?.length > 0 ? (
                  <Grid container>
                    {Object.keys(errors).map((key) => (
                      <Grid item xs={12} key={key}>
                        <WidgetInfoBox variant="error">{errors[key]}</WidgetInfoBox>
                      </Grid>
                    ))}
                  </Grid>
                ) : (
                  machineDefinitions?.length && (
                    <Grid item xs={12}>
                      <_DefinitionsList
                        data={machineDefinitions}
                        onDelete={handleMachineDefinitionDelete}
                        onEdit={handleMachineDefinitionEdit}
                        onDuplicate={handleDuplicate}
                      />
                    </Grid>
                  )
                )}
              </Grid>
            </Grid>
          ),
          new: (
            <>
              <Grid container item flexDirection="row">
                <Grid item flexGrow={1} flexShrink={1} alignSelf="start">
                  <Tooltip title="Back to the list." arrow placement="top">
                    <IconButton aria-label="delete" onClick={() => setMode("list")}>
                      <icons.mui.ArrowBack />
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item flexGrow={1} flexShrink={1} alignSelf="center" marginBottom={1}>
                  <Typography component="h1" variant="h4" color="inherit">
                    New Machine Definition
                  </Typography>
                </Grid>
              </Grid>
              <WidgetMachineDefinitionEditor
                initialValues={initialValues.current}
                onSubmit={handleCreateNewMachineDefinition}
              />
            </>
          ),
          edit: (
            <>
              <Grid container item flexDirection="row" position="relative">
                <Tooltip sx={{ position: "absolute" }} title="Back to the list." arrow placement="top">
                  <IconButton aria-label="delete" onClick={() => setMode("list")}>
                    <icons.mui.ArrowBack />
                  </IconButton>
                </Tooltip>
                <Grid container item flexDirection="column" alignItems="center">
                  <Grid item flexGrow={1} flexShrink={1}>
                    <Typography component="h1" variant="h4" color="inherit">
                      Edit Machine Definition
                    </Typography>
                  </Grid>
                  <Grid item>id: {selectedId.current}</Grid>
                </Grid>
              </Grid>
              <WidgetMachineDefinitionEditor
                initialValues={initialValues.current}
                onSubmit={(values) => handleMachineDefinitionOverride(selectedId.current, values)}
              />
            </>
          ),
        }[mode]
      }
    </Grid>
  );
};

WidgetMachineDefinition.propTypes = {
  getMachineDefinitions: PropTypes.func,
  reloadMachineDefinitions: PropTypes.func,
};

WidgetMachineDefinition.defaultProps = {
  getMachineDefinitions: () => {},
  reloadMachineDefinitions: () => {},
};

export default WidgetMachineDefinition;
