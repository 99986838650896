import React from "react";
import PropTypes from "prop-types";

// imports: local
import { DialogCommonWizard } from "../dialog_common_wizard/index.js";
import { DialogRemoteCommonRefreshTooltip } from "../dialog_remote_common_refresh_tooltip/index.js";
import { DialogRemoteCommonWidgetRenderViewer } from "../dialog_remote_common_widget_render_viewer/index.js";
import DialogRemoteCommonSnapshotsList from "./dialog_remote_common_snapshots_list.jsx";
import DialogRemoteCommonCommit from "./dialog_remote_common_commit.jsx";

const widgerRenderWidth = 867;
const dialogContentPadding = 48;

const DialogWizardCommonCommit = ({
  isOpen,
  onClose,
  onRefresh,
  handleCreateSnapshots,
  isLoading,
  rows,
  filenameExistsText,
  onComplete,
  widgetRender,
  loadingText,
  isSubmitting,
  dataTag,
  stepTitle1,
  stepTitle2,
  stepTitle3,
}) => {
  const [activeStep, setActiveStep] = React.useState(0);
  const [snapshots, setSnapshots] = React.useState([]);
  const [isActionshidden, setIsActionsHidden] = React.useState(false);
  const [isButtonOkDisabled, setButtonOkDisabled] = React.useState(false);
  const adjustSnapshotsRef = React.useRef(null);
  const generatedSnapshots = React.useRef(null);
  const filenameRef = React.useRef("");

  React.useEffect(() => {
    if (isOpen) {
      setActiveStep(0);
      setButtonOkDisabled(false);
    }
  }, [isOpen]);

  React.useEffect(() => {
    if (activeStep !== 2) {
      setButtonOkDisabled(false);
      setIsActionsHidden(false);
    }
  }, [activeStep]);

  const nextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const previousStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  let handleNext;
  let buttonName;
  let title;
  switch (activeStep) {
    case 0:
      title = stepTitle1;
      handleNext = () => {
        setButtonOkDisabled(true);
        adjustSnapshotsRef.current.onGenerateSnapshots();
      };
      buttonName = "Create Snapshots";
      break;
    case 1:
      title = stepTitle2;
      handleNext = () => nextStep();
      buttonName = "Next";
      break;
    case 2:
      title = stepTitle3;
      handleNext = () => onComplete(filenameRef.current, generatedSnapshots.current);
      buttonName = "Commit";
      break;
    default:
      title = "";
      handleNext = () => undefined;
      buttonName = "Next";
      break;
  }

  const setRef = React.useCallback(
    (node) => {
      if (adjustSnapshotsRef.current) {
        adjustSnapshotsRef.current.disable();
      }
      if (node) {
        node.setWidgetRender(widgetRender);
        node.enable();
      }
      adjustSnapshotsRef.current = node;
    },
    [widgetRender]
  );

  return (
    <DialogCommonWizard
      open={isOpen}
      dataTag={dataTag}
      dialogPaperSx={{ maxWidth: widgerRenderWidth + dialogContentPadding }}
      onClose={onClose}
      maxSteps={3}
      hideActions={isActionshidden}
      activeStep={activeStep}
      isLoading={isLoading}
      loadingText={loadingText}
      title={`Commit - ${title}`}
      autoFocus
      handleNext={handleNext}
      handleBack={previousStep}
      nextStepTitle={buttonName}
      tooltip={
        activeStep === 2 ? (
          <DialogRemoteCommonRefreshTooltip onRefresh={onRefresh} dataTag={`${dataTag}-refresh-tooltip`} />
        ) : null
      }
      isNextDisabled={isButtonOkDisabled || isSubmitting}
      showClose
    >
      <DialogRemoteCommonWidgetRenderViewer
        containerWidth={widgerRenderWidth}
        ref={setRef}
        widgetRender={widgetRender}
        handleCreateSnapshots={(isValid) => {
          isValid ? handleCreateSnapshots() : setButtonOkDisabled(false);
        }}
        onSnapshotsCreated={(snapshots) => {
          generatedSnapshots.current = snapshots;
          setSnapshots(snapshots.getSnapshots());
          nextStep();
        }}
      />
      <DialogRemoteCommonSnapshotsList
        snapshots={snapshots}
        onSnapshotClick={(snapshot) => setIsActionsHidden(!!snapshot)}
      />
      <DialogRemoteCommonCommit
        rows={rows}
        dataTag={dataTag}
        filenameExistsText={filenameExistsText}
        onRefresh={onRefresh}
        confirmButtonStatus={(isConfirmDisabled) => {
          setButtonOkDisabled(isConfirmDisabled);
        }}
        onFilenameChange={(value) => (filenameRef.current = value)}
      />
    </DialogCommonWizard>
  );
};

DialogWizardCommonCommit.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onRefresh: PropTypes.func,
  handleCreateSnapshots: PropTypes.func,
  isLoading: PropTypes.bool,
  rows: PropTypes.array,
  filenameExistsText: PropTypes.string,
  onComplete: PropTypes.func,
  widgetRender: PropTypes.object,
  loadingText: PropTypes.string,
  isSubmitting: PropTypes.bool,
  dataTag: PropTypes.string.isRequired,
  stepTitle1: PropTypes.string,
  stepTitle2: PropTypes.string,
  stepTitle3: PropTypes.string,
};

export default DialogWizardCommonCommit;
