import React from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router-dom";

// imports: syklone
import { Box, createTheme, CssBaseline, styled, Tab, Tabs, ThemeProvider, themes } from "syklone/ui/index.js";
import { useApiContext } from "syklone/api/react/index.js";
import { FormLogin } from "syklone/components/forms/index.js";
import { auth } from "syklone/components/miscelanea/index.js";

// imports: local
import * as icons from "./icons/index.js";
import { WidgetPageTitle } from "../../widgets/index.js";

export default function PageLogin(props) {
  const api = useApiContext();
  const sessionSettings = api.sessionSettings;
  const apiUsers = api.sykloneApi.sk.svcPlatformCreator.ApiUsers;

  const [value, setValue] = React.useState(0);
  const [theme, setTheme] = React.useState({});
  let navigate = useNavigate();
  const paths = {
    0: "/part-creator",
    1: "/platform-creator",
    2: "/build-creator",
  };
  let isThemeLight = sessionSettings.data().isThemeLight;

  const firstrender = React.useRef(true);
  React.useEffect(() => {
    setTheme(isThemeLight ? themes.themeLight : themes.themeDark);
    if (firstrender.current) {
      firstrender.current = false;
    }
  });

  async function onLogin(username, password, cb) {
    let response = await apiUsers.postLogin({
      username: username,
      password: password,
    });
    let data = response.data;
    if (data.errors) {
      cb(data.errors);
    } else {
      auth.login(() => {
        navigate(paths[value], {
          state: { username: username },
        });
      });
    }
  }

  function onSubmitRegistration() {
    navigate("/register");
  }

  let currentTheme = createTheme(theme);

  return (
    <ThemeProvider theme={currentTheme}>
      <WidgetPageTitle title="Part&Platform creator" />
      <CssBaseline />
      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", height: "100vh" }}>
        <_ContainerBox isThemeLight={isThemeLight} maxWidth="xs">
          <Box sx={{ borderBottom: "1px solid #3A3A3A" }}>
            <Tabs
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
            >
              <_OptionTab
                icon={<_LogoImg alt="Part creator" src={icons.PartAmblem} selected={value === 0} />}
                label={<_TabLabelSpan>PART CREATOR</_TabLabelSpan>}
              />
              <_OptionTab
                icon={<_LogoImg alt="Platform creator" src={icons.PlatformAmblem} selected={value === 1} />}
                label={<_TabLabelSpan>PLATFORM CREATOR</_TabLabelSpan>}
              />
              <_OptionTab
                icon={<_LogoImg alt="Build creator" src={icons.BuildAmblem} selected={value === 2} />}
                label={<_TabLabelSpan>BUILD CREATOR</_TabLabelSpan>}
              />
            </Tabs>
          </Box>
          <FormLogin onLogin={onLogin} isThemeLight={isThemeLight} onSubmitRegistration={onSubmitRegistration} />
        </_ContainerBox>
      </Box>
    </ThemeProvider>
  );
}

PageLogin.propTypes = {
  classes: PropTypes.object,
};

// -------- Private: Styled components --------
const _ContainerBox = styled(Box, {
  shouldForwardProp: (props) => props !== "isThemeLight",
})(({ isThemeLight }) => ({
  maxWidth: "500px",
  borderRadius: "6px",
  padding: "0px 0px 40px 0px",
  background: isThemeLight ? "#e1e1e1" : "#242424",
}));

const _OptionTab = styled(Tab)({
  minWidth: "160px!important",
  height: "90px",
});

const _TabLabelSpan = styled("span")({
  fontSize: 12,
  fontWeight: 500,
});

const _LogoImg = styled("img", {
  shouldForwardProp: (props) => props !== "selected",
})(({ selected }) => ({
  filter: selected
    ? "invert(85%) sepia(39%) saturate(6096%) hue-rotate(0deg) brightness(104%) contrast(103%)"
    : "invert(100%) sepia(0%) saturate(7469%) hue-rotate(116deg) brightness(109%) contrast(102%)",
}));
