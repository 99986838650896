import React from "react";
import PropTypes from "prop-types";

function WidgetPageTitle({ title }) {
  React.useEffect(() => {
    if (title && document) {
      document.title = title;
    }
  }, [title]);

  return null;
}

WidgetPageTitle.propTypes = {
  title: PropTypes.string,
};

export default WidgetPageTitle;
