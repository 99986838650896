import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Card, Button, CardHeader, CardContent, Dialog } from "syklone/ui/index.js";

function WidgetJsonPreview({ json }) {
  const [openPreview, setOpenPreview] = React.useState(false);
  const [exported, setExported] = React.useState("");

  const handleClosePreview = () => {
    setOpenPreview(false);
  };

  const handleOpenPreview = (json) => {
    setExported(JSON.stringify(json, null, 2));
    setOpenPreview(true);
  };

  return (
    <React.Fragment>
      <Button color="primary" variant="contained" type="button" onClick={(e) => handleOpenPreview(json)} size="large">
        Preview JSON
      </Button>
      <Dialog onClose={handleClosePreview} aria-labelledby="customized-dialog-title" open={openPreview}>
        <Card sx={{ minWidth: 320 }}>
          <CardHeader title="Preview JSON" />
          <CardContent>
            <pre>{exported}</pre>
          </CardContent>
        </Card>
      </Dialog>
    </React.Fragment>
  );
}

WidgetJsonPreview.propTypes = {
  json: PropTypes.object,
};

export default WidgetJsonPreview;
