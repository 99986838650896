import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import * as coreCommands from "syklone/graphics/tools/core_commands/index.js";
import { useApiContext } from "syklone/api/react/index.js";

// imports: local
import { DialogRemoteCommon } from "../dialog_remote_common/index.js";

const DialogRemoteSaveParts = React.forwardRef(({ open, username }, ref) => {
  const [rows, setRows] = React.useState([]);
  const [isOpen, setIsOpen] = React.useState(open);
  const toolRef = React.useRef();
  const [isLoading, setIsLoading] = React.useState(false);
  const [isConfirmDisabled, setIsConfirmDisabled] = React.useState(false);
  const [loadingText, setIsLoadingText] = React.useState("");

  const api = useApiContext();
  const commandManager = api.commandManager;
  const servicePartSessions = api.sykloneApi.servicePartSessions;

  React.useImperativeHandle(ref, () => ({
    show: (_tool) => {
      toolRef.current = _tool;
      onRefresh();
      setIsOpen(true);
      setIsConfirmDisabled(false);
    },
  }));

  const onOk = (currentRow, filename) => {
    function loader(loading, text) {
      setIsLoading(loading);
      setIsLoadingText(text);
      if (loading) {
        setIsConfirmDisabled(true);
      } else {
        setIsOpen(false);
        setIsConfirmDisabled(false);
      }
    }

    setIsConfirmDisabled(true);
    const data = {
      filename: filename,
      user: username,
    };
    commandManager.executeAsync(new coreCommands.CommandSavePartRemotely(api, toolRef.current, loader, data));
  };

  const onRefresh = async () => {
    try {
      let response = await servicePartSessions.getFiltered(username, false);
      let rows = response.data.result;
      setRows(rows);
    } catch (error) {
      alert(`${error}`);
    }
  };

  const onDelete = async (currentRow) => {
    console.log(currentRow);
    if (!window.confirm(`Warning: Do you want to save part ${currentRow.blobPath}`)) {
      return;
    }

    try {
      if (username !== currentRow.user) {
        throw Error(`Error deleting part, ${currentRow.user} doesn't own this file`);
      }

      await servicePartSessions.remove(currentRow.id);
      setRows(rows.filter((r) => currentRow.id !== r.id));
    } catch (error) {
      alert(error);
    }
  };

  return (
    <DialogRemoteCommon
      dataTag="dialog-remote-save-parts"
      title="Save parts"
      filenameExistsText={"Part is going to be overwritten"}
      open={isOpen}
      isLoading={isLoading}
      loadingText={loadingText}
      onClose={() => setIsOpen(false)}
      rows={rows}
      fileNameEnabled={true}
      onOk={onOk}
      onCellDoubleClick={onOk}
      onRefresh={() => onRefresh}
      onDelete={onDelete}
      isSubmitting={isConfirmDisabled}
      canOverwriteFilename={true}
    />
  );
});

DialogRemoteSaveParts.displayName = "DialogRemoteSaveParts";

DialogRemoteSaveParts.propTypes = {
  tool: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  username: PropTypes.string,
};

export default DialogRemoteSaveParts;
