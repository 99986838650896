import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Box } from "syklone/ui/index.js";

// imports: local
import { DialogRemoteCommonFilenameInput } from "../dialog_remote_common_filename_input/index.js";
import { DialogRemoteCommonDatagrid } from "../dialog_remote_common_datagrid/index.js";
import { useDialogRemoteCommonFilenameValueWithValidation } from "../../hooks/index.js";

const widgerRenderWidth = 867;
const widgetRenderHeight = 650;
const fieldNameOffset = 80;

const columns = [
  {
    field: "name",
    headerName: "Name",
    width: 200,
  },
  { field: "user", headerName: "User", width: 130 },
];

const DialogRemoteCommonCommit = ({
  rows,
  filenameExistsText,
  onRefresh,
  confirmButtonStatus,
  onFilenameChange,
  dataTag,
}) => {
  const { setFilenameValue, filenameValue, filenameInputHelperText, isConfirmDisabled, inputHelperTextType } =
    useDialogRemoteCommonFilenameValueWithValidation(rows, filenameExistsText);

  React.useEffect(() => {
    onRefresh();
  }, []);

  React.useEffect(() => {
    confirmButtonStatus(isConfirmDisabled);
  }, [isConfirmDisabled]);

  return (
    <>
      <Box
        sx={{ height: widgetRenderHeight - fieldNameOffset, width: widgerRenderWidth }}
        data-syklone={`${dataTag}-data-grid`}
      >
        <DialogRemoteCommonDatagrid
          columns={columns}
          onSelectionModelChanged={(currentRow) => setFilenameValue(currentRow ? currentRow.name : "")}
          rows={rows}
        />
      </Box>
      <Box sx={{ marginTop: "20px", maxWidth: 250, height: 62 }} data-syklone={`${dataTag}-filename-input`}>
        <DialogRemoteCommonFilenameInput
          fileNameType={inputHelperTextType}
          fileNameEnabled
          filenameHelperText={filenameInputHelperText}
          filenameValue={filenameValue}
          onChange={(value) => {
            setFilenameValue(value);
            onFilenameChange(value);
          }}
          sx={{ width: "100%" }}
        />
      </Box>
    </>
  );
};

DialogRemoteCommonCommit.propTypes = {
  rows: PropTypes.array,
  filenameExistsText: PropTypes.string,
  onButtonOkDisabled: PropTypes.func,
  onRefresh: PropTypes.func,
  confirmButtonStatus: PropTypes.func,
  onFilenameChange: PropTypes.func,
  dataTag: PropTypes.string.isRequired,
};

export default DialogRemoteCommonCommit;
