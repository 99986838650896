import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { TextField } from "syklone/ui/index.js";

const DialogRemoteCommonFilenameInput = ({
  fileNameType,
  filenameHelperText,
  filenameValue,
  fileNameEnabled,
  onChange,
  sx,
}) => {
  return (
    <TextField
      error={!!filenameHelperText}
      helperText={filenameHelperText}
      label="filename"
      disabled={!fileNameEnabled}
      value={filenameValue}
      onChange={(e) => onChange(e.target.value)}
      autoComplete="off"
      variant="outlined"
      sx={
        fileNameType === "warning"
          ? {
              "& input:valid": {
                color: "white !important",
              },
              "& input:valid + fieldset": {
                borderColor: "orange !important",
              },
              "& .Mui-error": {
                color: "orange !important",
              },
              ...sx,
            }
          : sx
      }
    />
  );
};

DialogRemoteCommonFilenameInput.propTypes = {
  fileNameType: PropTypes.string,
  filenameHelperText: PropTypes.string,
  filenameValue: PropTypes.string,
  fileNameEnabled: PropTypes.bool,
  onChange: PropTypes.func,
  sx: PropTypes.object,
};

export default DialogRemoteCommonFilenameInput;
