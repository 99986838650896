import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import { Box } from "syklone/ui/index.js";

// imports: local
import { useDialogRemoteCommonFilenameValueWithValidation } from "../../hooks/index.js";
import { DialogCommonConfirmation } from "../dialog_common_confirmation/index.js";
import { DialogRemoteCommonRefreshTooltip } from "../dialog_remote_common_refresh_tooltip/index.js";
import { DialogRemoteCommonFilenameInput } from "../dialog_remote_common_filename_input/index.js";
import { DialogRemoteCommonDatagrid } from "../dialog_remote_common_datagrid/index.js";

const columns = [
  {
    field: "name",
    headerName: "Name",
    width: 200,
  },
  { field: "user", headerName: "User", width: 130 },
];

const DialogRemoteCommon = ({
  open,
  rows = [],
  title,
  onOk,
  onClose,
  onRefresh,
  fileNameEnabled,
  onCellDoubleClick,
  loadingText,
  isLoading,
  isSubmitting,
  onDelete,
  filenameExistsText,
  dataTag,
  canOverwriteFilename,
}) => {
  const { setFilenameValue, filenameValue, filenameInputHelperText, isConfirmDisabled, inputHelperTextType } =
    useDialogRemoteCommonFilenameValueWithValidation(rows, filenameExistsText, canOverwriteFilename);
  const selectedRow = React.useRef(null);
  const handleClose = () => {
    onClose();
    setFilenameValue("");
  };

  return (
    <DialogCommonConfirmation
      isLoading={isLoading}
      loadingText={loadingText}
      title={title}
      showClose
      dataTag={dataTag}
      tooltip={<DialogRemoteCommonRefreshTooltip onRefresh={onRefresh} dataTag={`${dataTag}-refresh-tooltip`} />}
      actions={
        <DialogRemoteCommonFilenameInput
          fileNameType={inputHelperTextType}
          filenameHelperText={filenameInputHelperText}
          filenameValue={filenameValue}
          fileNameEnabled={fileNameEnabled}
          onChange={(value) => {
            setFilenameValue(value);
          }}
        />
      }
      isConfirmDisabled={isConfirmDisabled || isSubmitting}
      autoFocus
      open={open}
      onConfirm={() => onOk(selectedRow.current, filenameValue)}
      onClose={handleClose}
      onCancel={handleClose}
    >
      <Box sx={{ height: 400, width: 520 }}>
        <DialogRemoteCommonDatagrid
          columns={columns}
          onCellDoubleClick={(row) => onCellDoubleClick(row, filenameValue)}
          onSelectionModelChanged={(currentRow) => {
            setFilenameValue(currentRow ? currentRow.name : "");
            selectedRow.current = currentRow;
          }}
          rows={rows}
          onDelete={onDelete}
        />
      </Box>
    </DialogCommonConfirmation>
  );
};

DialogRemoteCommon.propTypes = {
  fileNameEnabled: PropTypes.bool,
  filenameHelperText: PropTypes.string,
  onFilenameChanged: PropTypes.func,
  open: PropTypes.bool,
  rows: PropTypes.array,
  title: PropTypes.string,
  onClose: PropTypes.func,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  onRefresh: PropTypes.func,
  onCellDoubleClick: PropTypes.func,
  loadingText: PropTypes.string,
  onDelete: PropTypes.func,
  isLoading: PropTypes.bool,
  isSubmitting: PropTypes.bool,
  filenameExistsText: PropTypes.string,
  dataTag: PropTypes.string.isRequired,
  canOverwriteFilename: PropTypes.bool,
};

export default DialogRemoteCommon;
