import React from "react";

// imports: local
import * as appVersion from "../../version.js";

export function items(api) {
  // prettier-ignore
  return api.items(
    { key: "DEP: REACT", value: React.version },
    { key: "VERSION_APP", value: appVersion.VERSION },
    { key: "VERSION_SUITE", value: api?.suiteVersion ? api.suiteVersion : -1 },
  );
}
