import React from "react";
import PropTypes from "prop-types";

// imports: syklone
import {
  WidgetAvatar,
  WidgetTopBarContainer,
  WidgetTopBarRightControlContainer,
} from "syklone/components/widgets/index.js";
import { Button, MenuItem } from "syklone/ui/index.js";

// imports: local
import { getEnv as getRemoteEnv } from "../../../env.js";

const WidgetTopBar = ({ username, hasNotification = false, onProfile, onSettings, onLogout, handleAbout }) => {
  const refAvatarNotifications = React.useRef(null);

  const handleProfileClick = () => {
    refAvatarNotifications.current?.handleClose();
    onProfile && onProfile();
  };

  const handleSettingsClick = () => {
    refAvatarNotifications.current?.handleClose();
    onSettings && onSettings();
  };

  return (
    <WidgetTopBarContainer
      style={{
        padding: "0px 20px",
        paddingResponsive: "0px 30px",
        border: "0px!important",
        justifyContent: "end",
      }}
      hasBorder={false}
    >
      <Button
        onClick={() => window.open(`${getRemoteEnv().URL_PAGE_GLOBAL_FRONTEND}/dashboard/part-platform`, "_self")}
        data-syklone="back-to-dashboard"
      >
        Back to Dashboard
      </Button>
      <WidgetTopBarRightControlContainer>
        <WidgetAvatar
          ref={refAvatarNotifications}
          username={username}
          hasNotification={hasNotification}
          onProfile={handleProfileClick}
          onLogout={onLogout}
          onAbout={handleAbout}
        >
          <MenuItem key="settings" onClick={handleSettingsClick} data-syklone="widget-avatar-menu-item-settings">
            Settings
          </MenuItem>
        </WidgetAvatar>
      </WidgetTopBarRightControlContainer>
    </WidgetTopBarContainer>
  );
};

WidgetTopBar.propTypes = {
  username: PropTypes.string,
  hasNotification: PropTypes.bool,
  onProfile: PropTypes.func,
  onLogout: PropTypes.func,
  handleAbout: PropTypes.func,
  refWidgetThemeSwitcher: PropTypes.object,
  onTheme: PropTypes.func,
  onSettings: PropTypes.func,
  isLightTheme: PropTypes.bool,
};

WidgetTopBar.defaultProps = {
  username: "",
  hasNotification: true,
  onProfile: () => undefined,
  onLogout: () => undefined,
  handleAbout: () => undefined,
  onTheme: () => undefined,
  isLightTheme: true,
};

export default WidgetTopBar;
