import React from "react";
import { PropTypes } from "prop-types";

// imports: syklone
import { Divider, Grid, Typography } from "syklone/ui";

// imports: local
import { WidgetHelperCard } from "../../../widgets/index.js";
import { withInfoPopover } from "../../../hoc/index.js";

const WidgetHelperCardWithInfoPopover = withInfoPopover(WidgetHelperCard);

const WidgetSection = ({ title, adorment, description, imageData, showDivider, children }) => {
  return (
    <Grid container item xs={12} flexDirection="column" spacing={2}>
      {showDivider ? (
        <Grid item>
          <Divider />
        </Grid>
      ) : null}
      <Grid item container flexDirection="row" alignItems="center" spacing={1}>
        <Grid item>
          <Typography variant="h5" color="inherit" noWrap>
            {title}
          </Typography>
        </Grid>
        {description || imageData ? (
          <Grid item>
            <WidgetHelperCardWithInfoPopover title={title} imageData={imageData}>
              <Grid container>{description}</Grid>
            </WidgetHelperCardWithInfoPopover>
          </Grid>
        ) : null}
        {adorment ? <Grid item>{adorment}</Grid> : null}
      </Grid>
      {children}
    </Grid>
  );
};

WidgetSection.propTypes = {
  adorment: PropTypes.node,
  children: PropTypes.node,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  imageData: PropTypes.object,
  showDivider: PropTypes.bool,
  title: PropTypes.string,
};
WidgetSection.defaultProps = {
  adorment: undefined,
  children: undefined,
  description: "",
  imageData: undefined,
  showDivider: true,
  title: "",
};

export default WidgetSection;
